import {
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from '@mui/material';

import { TWeatherFilter } from '../../../../types';
import {
  IcLgtModSvg,
  IcLgtSvg,
  IcModSevSvg,
  IcModSvg,
  IcNegSvg,
  IcOtherSvg,
  IcSevSvg,
  IcTraceLgtSvg,
  IcTraceSvg,
  TbExtrmSvg,
  TbLgtModSvg,
  TbLgtSvg,
  TbLlwsSvg,
  TbModSevSvg,
  TbModSvg,
  TbNegSvg,
  TbSevSvg,
  TbSmoothLgtSvg,
} from '../../weather-Icons';

type TPirepKeys = keyof (TWeatherFilter['pireps']['tb'] &
  TWeatherFilter['pireps']['ic']);

const iconMap: Record<
  TPirepKeys,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  'IC-LGT-MOD': IcLgtModSvg,

  'IC-LGT': IcLgtSvg,

  'IC-MOD-SEV': IcModSevSvg,

  'IC-MOD': IcModSvg,

  'IC-NEG': IcNegSvg,

  'IC-OTHER': IcOtherSvg,

  'IC-SEV': IcSevSvg,

  'IC-TRACE-LGT': IcTraceLgtSvg,

  'IC-TRACE': IcTraceSvg,

  'TB-EXTRM': TbExtrmSvg,

  'TB-LGT-MOD': TbLgtModSvg,

  'TB-LGT': TbLgtSvg,

  'TB-LLWS': TbLlwsSvg,

  'TB-MOD-SEV': TbModSevSvg,

  'TB-MOD': TbModSvg,

  'TB-NEG': TbNegSvg,

  'TB-SEV': TbSevSvg,

  'TB-SMOOTH': TbSmoothLgtSvg,
};

export type TPirepItemProps = ListItemProps & {
  id: TPirepKeys;
  label: string;
};
export const PirepItem = ({ id, label, ...props }: TPirepItemProps) => {
  const Icon = iconMap[id];
  return (
    <ListItem {...props}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};
