import CloseIcon from '@mui/icons-material/Close';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { ICONS } from '../../../components';
import { OrganIconType } from '../../../types/new';

import { cellDrawerClasses } from './CellDrawerRoot';

export type CellDrawerHeaderProps = {
  day: 'today' | 'tomorrow';
  organIconName: OrganIconType;
  title: string;
  secondaryText: string;
  onClose: () => void;
};

export const CellDrawerHeader = ({
  day,
  organIconName,
  secondaryText,
  title,
  onClose,
}: CellDrawerHeaderProps) => {
  const Icon = ICONS[organIconName];
  return (
    <Box className={cellDrawerClasses.header}>
      <Box className={cellDrawerClasses.headerPrimary}>
        <Box
          className={classNames(
            cellDrawerClasses.headerPrimaryIcon,
            organIconName,
          )}
        >
          <Icon />
        </Box>
        <Box className={cellDrawerClasses.headerPrimaryTitle}>{title}</Box>
        <Box className={cellDrawerClasses.headerPrimaryClose} onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>
      <Box className={cellDrawerClasses.headerSecondary}>
        <Box className={cellDrawerClasses.headerSecondaryLocation}>
          <Box className={cellDrawerClasses.headerSecondaryIcon}>
            <FmdGoodIcon />
          </Box>
          <Box className={cellDrawerClasses.headerSecondaryText}>
            {secondaryText}
          </Box>
        </Box>
        <Box className={cellDrawerClasses.headerSecondaryDay}>{day}</Box>
      </Box>
    </Box>
  );
};
