import { Theme } from '@mui/material';
import {
  axisClasses,
  chartsGridClasses,
  legendClasses,
} from '@mui/x-charts-pro';

import { themeBase } from '../theme-base';

const { spacing, palette } = themeBase;
export const muiCharts: Theme['components'] = {
  MuiChartsSurface: {
    styleOverrides: {
      root: {
        borderRadius: spacing(2),
        background: '#0F151C',
      },
    },
  },
  MuiChartsLegend: {
    styleOverrides: {
      root: {
        [`.${legendClasses.series}`]: {
          color: 'white',
          text: {
            fill: 'currentColor',
          },
        },
      },
    },
  },
  MuiChartsAxis: {
    styleOverrides: {
      root: {
        [`.${axisClasses.tickLabel}`]: {
          color: palette.grey[400],
          fill: 'currentColor',
        },
      },
    },
  },
  MuiChartsGrid: {
    styleOverrides: {
      root: {
        background: '#0F151C',
        [`.${chartsGridClasses.verticalLine}`]: {
          strokeDasharray: '4 4',
        },
        [`.${chartsGridClasses.horizontalLine}`]: {},
      },
      line: {
        color: palette.mapBlue[700],
        stroke: 'currentColor',
      },
    },
  },
};
