import {
  Box,
  Divider,
  List,
  styled,
  listClasses,
  listItemClasses,
  listItemIconClasses,
  dividerClasses,
  listItemTextClasses,
} from '@mui/material';
import classNames from 'classnames';
import { useCallback } from 'react';

import { TWeatherFilter } from '../../../../types';
import { useWeatherFilter } from '../useWeatherFilter';

import { FlightCatItem } from './FlightCatItem';
import { PirepItem } from './PirepItem';
import { SigmetItem } from './Sigmettem';

const PREFIX = 'TSMED-weather-legend';

const weatherLegendClasses = {
  overlays: `${PREFIX}-overlays`,

  list: `${PREFIX}-list`,
  listHeading: `${PREFIX}-listHeading`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  position: 'absolute',
  display: 'flex',
  gap: 16,
  bottom: 16,
  left: 16,
  padding: 16,
  background: 'rgba(29, 42, 55, 0.4)',
  border: '1px solid rgb(29, 42, 55)',
  borderRadius: 8,
  [`.${dividerClasses.root}`]: {
    background: 'white',
  },
  [`.${weatherLegendClasses.list}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    [`.${weatherLegendClasses.listHeading}`]: {
      textAlign: 'center',
    },
    [`.${listClasses.root}`]: {
      display: 'flex',
      padding: 0,
      gap: 8,
      [`.${listItemClasses.root}`]: {
        flexDirection: 'column',
        padding: 4,
        gap: 4,
        transition: 'all 200ms ease-in-out',
        cursor: 'pointer',
        [`&.inactive`]: {
          filter: 'opacity(0.5) grayscale(1)',
          scale: '0.9',
        },
        [`.${listItemIconClasses.root}`]: {
          minWidth: 'auto',
          width: 24,
          height: 24,
        },
        [`.${listItemTextClasses.root}`]: {
          whiteSpace: 'nowrap',
          margin: 0,
        },
      },
    },
  },
}));

const flightCatList: Array<keyof TWeatherFilter['flightCats']> = [
  'LIFR',
  'IFR',
  // 'MVFR',
  // 'VFR',
];
const sigmetList: Array<{
  id: keyof TWeatherFilter['sigmets'];
  mapLabel: string;
  legendLabel: string;
}> = [
  { id: 'CONVECTIVE', mapLabel: 'CONV', legendLabel: 'Convective' },
  { id: 'TS', mapLabel: 'TS', legendLabel: 'Thunderstorm' },
  { id: 'TC', mapLabel: 'TC', legendLabel: 'Trop Cyclone' },
  { id: 'ICE', mapLabel: 'ICE', legendLabel: 'Icing' },
  // { id: 'MTN-OBSCN', mapLabel: 'MTN-OBSCN', legendLabel: 'Blowing Dust/Sand' },
  // { id: 'VA', mapLabel: 'VA', legendLabel: 'Volcanic Ash' },
  // { id: 'TURB', mapLabel: 'TURB', legendLabel: 'Turb' },
  // { id: 'IFR', mapLabel: 'IFR', legendLabel: 'IFR' },
  // { id: 'MTW', mapLabel: 'MTW', legendLabel: 'MTW' },
];

const pirepTbList: Array<{
  id: keyof TWeatherFilter['pireps']['tb'];
  label: string;
}> = [
  // { id: 'TB-NEG', label: 'NEG' },
  // { id: 'TB-SMOOTH', label: 'SMOOTH' },
  // { id: 'TB-LGT', label: 'LGT' },
  // { id: 'TB-LGT-MOD', label: 'LGT-MOD' },
  { id: 'TB-MOD', label: 'MOD' },
  { id: 'TB-MOD-SEV', label: 'MOD-SEV' },
  { id: 'TB-SEV', label: 'SEV' },
  { id: 'TB-EXTRM', label: 'EXTRM' },
  { id: 'TB-LLWS', label: 'LLWS' },
];

const pirepIcList: Array<{
  id: keyof TWeatherFilter['pireps']['ic'];
  label: string;
}> = [
  // { id: 'IC-NEG', label: 'NEG' },
  // { id: 'IC-TRACE', label: 'TRACE' },
  // { id: 'IC-TRACE-LGT', label: 'TRACE-LGT' },
  // { id: 'IC-LGT', label: 'LGT' },
  // { id: 'IC-LGT-MOD', label: 'LGT-MOD' },
  { id: 'IC-MOD', label: 'MOD' },
  { id: 'IC-MOD-SEV', label: 'MOD-SEV' },
  { id: 'IC-SEV', label: 'SEV' },
  // { id: 'IC-OTHER', label: 'OTHER' },
];

export const WeatherLegend = () => {
  const { filter, setFilter } = useWeatherFilter();
  const switchFlightCat = useCallback(
    (flightCat: keyof TWeatherFilter['flightCats']) => () => {
      setFilter({
        ...filter,
        ['flightCats']: {
          ...filter.flightCats,
          [flightCat]: !filter.flightCats[flightCat],
        },
      });
    },
    [filter, setFilter],
  );
  const switchSigmet = useCallback(
    (sigmet: keyof TWeatherFilter['sigmets']) => () => {
      setFilter({
        ...filter,
        ['sigmets']: {
          ...filter.sigmets,
          [sigmet]: !filter.sigmets[sigmet],
        },
      });
    },
    [filter, setFilter],
  );
  const switchPirepTb = useCallback(
    (pirep: keyof TWeatherFilter['pireps']['tb']) => () => {
      setFilter({
        ...filter,
        ['pireps']: {
          ...filter.pireps,
          tb: {
            ...filter.pireps.tb,
            [pirep]: !filter.pireps.tb[pirep],
          },
        },
      });
    },
    [filter, setFilter],
  );
  const switchPirepIc = useCallback(
    (pirep: keyof TWeatherFilter['pireps']['ic']) => () => {
      setFilter({
        ...filter,
        ['pireps']: {
          ...filter.pireps,
          ic: {
            ...filter.pireps.ic,
            [pirep]: !filter.pireps.ic[pirep],
          },
        },
      });
    },
    [filter, setFilter],
  );

  const { flightCats, sigmets, pireps } = filter;
  return (
    <Root>
      <Box className={weatherLegendClasses.list}>
        <Box className={weatherLegendClasses.listHeading}>Flight cat</Box>
        <List>
          {flightCatList.map(fc => (
            <FlightCatItem
              key={fc}
              flightCat={fc}
              className={classNames({
                active: flightCats[fc],
                inactive: !flightCats[fc],
              })}
              onClick={switchFlightCat(fc)}
            />
          ))}
        </List>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box className={weatherLegendClasses.list}>
        <Box className={weatherLegendClasses.listHeading}>Turb</Box>
        <List>
          {pirepTbList.map(p => (
            <PirepItem
              key={p.id}
              id={p.id}
              label={p.label}
              className={classNames({
                active: pireps.tb[p.id],
                inactive: !pireps.tb[p.id],
              })}
              onClick={switchPirepTb(p.id)}
            />
          ))}
        </List>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box className={weatherLegendClasses.list}>
        <Box className={weatherLegendClasses.listHeading}>Ice</Box>
        <List>
          {pirepIcList.map(p => (
            <PirepItem
              key={p.id}
              id={p.id}
              label={p.label}
              className={classNames({
                active: pireps.ic[p.id],
                inactive: !pireps.ic[p.id],
              })}
              onClick={switchPirepIc(p.id)}
            />
          ))}
        </List>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box className={weatherLegendClasses.list}>
        <Box className={weatherLegendClasses.listHeading}>SIGMET</Box>
        <List>
          {sigmetList.map(sigmet => (
            <SigmetItem
              key={sigmet.id}
              id={sigmet.id}
              label={sigmet.legendLabel}
              className={classNames({
                active: sigmets[sigmet.id],
                inactive: !sigmets[sigmet.id],
              })}
              onClick={switchSigmet(sigmet.id)}
            />
          ))}
        </List>
      </Box>
    </Root>
  );
};
