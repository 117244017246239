import { useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import {
  MAP_OVERLAY_VIEW_WIDTH,
  MAP_OVERLAY_VIEW_HEIGHT,
} from '../../const/const';
import { getCaseClustering, getCasesPath } from '../../helpers/clustering';
import { useElementRef } from '../../hooks/useElementRef';
import { useSvgClientRect } from '../../hooks/useSvgClientRect';
import {
  HubStatusType,
  CaseType,
  ClusterItem,
  NewCaseType,
  CaseClusterType,
} from '../../types/new';

import { CasesSvgCluster } from './components/CasesSvgCluster';
import { HubLine } from './components/HubLine';
import { IconsLayout } from './components/IconsLayout';
import { NewAlertLine } from './components/NewAlertLine';
import styles from './overlays.module.scss';

interface Props {
  hubs: HubStatusType[];
  cases: CaseType[];
  newCases: NewCaseType[];
}

export const MainMapOverlay = ({ hubs, cases, newCases }: Props) => {
  const { ref } = useElementRef<SVGSVGElement>('mainOverlaySVG');
  const { width: clientWidth, height: clientHeight } =
    useSvgClientRect('mainOverlaySVG');
  const hasContent = Boolean(hubs.length || cases.length || newCases.length);

  const [clusters, setClusters] = useState<ClusterItem<CaseClusterType>[]>([]);

  const activeCases = useMemo(
    () => cases.filter(item => item.showOnMap),
    [cases],
  );

  const newCasesOnMap = useMemo(
    () => newCases.filter(item => item.showOnMap),
    [newCases],
  );

  useDebounce(
    () => {
      if (hasContent && clientHeight && clientWidth) {
        const casesWithPath = getCasesPath(activeCases);

        const data = getCaseClustering({
          cases: casesWithPath,
          clientHeight,
          clientWidth,
        });

        setClusters(data);
      } else {
        setClusters([]);
      }
    },
    250,
    [hasContent, activeCases, clientHeight, clientWidth],
  );

  return hasContent ? (
    <div className={styles.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="mainOverlaySVG"
        className={styles.overlaySVG}
        ref={ref}
        height="100%"
        viewBox={`0 0 ${MAP_OVERLAY_VIEW_WIDTH} ${MAP_OVERLAY_VIEW_HEIGHT}`}
      >
        <defs>
          <linearGradient id="eastAlertLineGradient">
            <stop offset="36%" stopColor="rgba(67, 76, 92, 0.5)" />
            <stop offset="65%" stopColor="rgba(255, 174, 129, 0.5)" />
          </linearGradient>
          <linearGradient id="westAlertLineGradient">
            <stop offset="36%" stopColor="rgba(255, 174, 129, 0.5)" />
            <stop offset="65%" stopColor="rgba(67, 76, 92, 0.5)" />
          </linearGradient>
          <linearGradient
            id="hubLineGradient"
            x1="381.5"
            y1="-87.9994"
            x2="3.2088"
            y2="152.218"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#406074" />
            <stop offset="1" stopColor="#1E2D3A" />
          </linearGradient>
        </defs>
        {hubs.map(({ id, location: { lonLat, boundary } }) => (
          <HubLine key={id} id={id} point={lonLat} boundary={boundary} />
        ))}
        {newCasesOnMap.map(item => (
          <NewAlertLine
            key={item.id}
            id={item.id}
            state={item.donorHospital.state}
            point={item.donorHospital.lonLat}
            boundary={item.donorHospital.boundary}
          />
        ))}
        {clusters.map(item => (
          <CasesSvgCluster key={item.key} cluster={item} />
        ))}
      </svg>
      <IconsLayout clusters={clusters} newCases={newCasesOnMap} />
    </div>
  ) : null;
};
