import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsMTTR } from '../../../../adapters/stats/useQueryStatsMTTR';
import { statsFormatCount, statsFormatHours } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsMTTRAogTable } from './StatsMTTRAogTable';
import { StatsMTTRAogTableMobile } from './StatsMTTRAogTableMobile';
import { StatsMTTRChart } from './StatsMTTRChart';
import { StatsMTTRRepairTable } from './StatsMTTRRepairTable';
import { StatsMTTRRepairTableMobile } from './StatsMTTRRepairTableMobile';

const PREFIX = 'TSMED-StatsMTTRTab';

const statsMTTRClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsMTTRClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsMTTRClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsMTTRClasses.fieldNegative}`]: {},
  [`.${statsMTTRClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsMTTR = () => {
  const { data: statsData } = useQueryData(useQueryStatsMTTR);

  const {
    totalRepairHours,
    totalAogCount,
    totalAogHours,
    totalMttrRepair,
    totalAogDur,
    totalPrevRepairHours,
    totalPrevAogCount,
    totalPrevAogHours,
    totalPrevMttrRepair,
    totalPrevAogDur,
  } = useMemo(() => {
    if (!statsData) {
      return {
        totalRepairHours: 0,
        totalAogCount: 0,
        totalAogHours: 0,
        totalMttrRepair: 0,
        totalAogDur: 0,
        totalPrevRepairHours: 0,
        totalPrevAogCount: 0,
        totalPrevAogHours: 0,
        totalPrevMttrRepair: 0,
        totalPrevAogDur: 0,
      };
    }

    return {
      totalRepairHours: statsData.summary.repairValue,
      totalAogCount: statsData.summary.aogCount,
      totalAogHours: statsData.summary.aogValue,
      totalMttrRepair: statsData.summary.mttr,
      totalAogDur: statsData.summary.aogDur,
      totalPrevRepairHours: statsData.summary.repairPrevValue,
      totalPrevAogCount: statsData.summary.aogPrevCount,
      totalPrevAogHours: statsData.summary.aogPrevValue,
      totalPrevMttrRepair: statsData.summary.mttrPrev,
      totalPrevAogDur: statsData.summary.aogDurPrev,
    };
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const summaryItems: ISummaryItem[] = [
    {
      id: 'total-repair-hours',
      label: 'Total Repair Hours',
      primaryValue: statsFormatHours(totalRepairHours),
      secondaryValue: ` / ${statsFormatHours(totalPrevRepairHours)}`,
    },
    {
      id: 'total-aog-count',
      label: 'Total AOG Events',
      primaryValue: statsFormatCount(totalAogCount),
      secondaryValue: ` / ${statsFormatCount(totalPrevAogCount)}`,
    },
    {
      id: 'total-aog-hours',
      label: 'Total AOG Hours',
      primaryValue: statsFormatHours(totalAogHours),
      secondaryValue: ` / ${statsFormatHours(totalPrevAogHours)}`,
    },
    {
      id: 'mttr-repair',
      label: 'MTTR',
      primaryValue: statsFormatHours(totalMttrRepair),
      secondaryValue: ` / ${statsFormatHours(totalPrevMttrRepair)}`,
      icon:
        (totalMttrRepair === totalPrevMttrRepair && 'balance') ||
        (totalMttrRepair < totalPrevMttrRepair && 'down') ||
        'up',
      kind: totalMttrRepair > totalPrevMttrRepair ? 'negative' : 'positive',
    },
    {
      id: 'mttr-aog',
      label: 'Average AOG Duration',
      primaryValue: statsFormatHours(totalAogDur),
      secondaryValue: ` / ${statsFormatHours(totalPrevAogDur)}`,
      icon:
        (totalAogDur === totalPrevAogDur && 'balance') ||
        (totalAogDur < totalPrevAogDur && 'down') ||
        'up',
      kind: totalAogDur > totalPrevAogDur ? 'negative' : 'positive',
    },
  ];

  return (
    <Root>
      <Summary items={summaryItems} />
      <StatsMTTRChart />
      <Box className={statsMTTRClasses.contentBlock}>
        <Typography variant="h2">MTTR by Aircraft</Typography>
        {isMobile ? <StatsMTTRRepairTableMobile /> : <StatsMTTRRepairTable />}
      </Box>
      <Box className={statsMTTRClasses.contentBlock}>
        <Typography variant="h2">Average AOG Duration by Aircraft</Typography>
        {isMobile ? <StatsMTTRAogTableMobile /> : <StatsMTTRAogTable />}
      </Box>
    </Root>
  );
};
