import { Color, PaletteMode, PaletteOptions } from '@mui/material';

export type TGetPaletteOptions = {
  mode: PaletteMode;
  background: string;
  primary: string;
  grey: Color;
  blue: Color;
  mapBlue: Color;
  green: Color;
  violet: Color;
  orange: Color;
  mint: Color;
};
export const getPalette = ({
  mode,
  background,
  primary,
  grey,
  blue,
  mapBlue,
  green,
  violet,
  orange,
  mint,
}: TGetPaletteOptions): PaletteOptions => ({
  mode,
  background: {
    default: background,
  },
  primary: {
    main: primary,
  },
  grey,
  blue,
  mapBlue,
  green,
  violet,
  orange,
  mint,
});
