import classNames from 'classnames';

import { ORGAN_COLORS, ORGAN_SHAPES } from '../../const/const';

import styles from './Legend.module.scss';

export const Legend = () => {
  const legendItems = [
    { color: ORGAN_COLORS.heart, shape: ORGAN_SHAPES.heart, caption: 'Heart' },
    { color: ORGAN_COLORS.lung, shape: ORGAN_SHAPES.lung, caption: 'Lung' },
    { color: ORGAN_COLORS.liver, shape: ORGAN_SHAPES.liver, caption: 'Liver' },
  ];

  return (
    <div className={styles.legend}>
      {legendItems.map(curLegendItem => (
        <div className={styles.legendItem} key={curLegendItem.caption}>
          <div className={styles.shapeContainer}>
            <div
              className={classNames(styles.shape, styles[curLegendItem.shape])}
              style={{ backgroundColor: curLegendItem.color }}
            ></div>
          </div>
          <div className={styles.caption}>{curLegendItem.caption}</div>
        </div>
      ))}
    </div>
  );
};
