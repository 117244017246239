import { Components, Theme } from '@mui/material';

export const muiFormControl: Theme['components'] = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        margin: 0,
        color: '#FFFFFF',
        '&:hover': {
          // backgroundColor: '#33383F',
        },
      },
      label: {
        fontSize: 16,
      },
    },
  } as Components['MuiFormControl'],
};
