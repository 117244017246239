import { useEffect, useRef } from 'react';

import { useElementRef } from '../../hooks/useElementRef';
import { NewCaseType } from '../../types/new';

import styles from './NewCaseAlert.module.scss';
import { NewCaseSubItem } from './NewCaseAlertSubItem';

interface Props {
  newCase: NewCaseType;
  index: number;
}

export const NewCaseAlert = ({ newCase }: Props) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { ref } = useElementRef<HTMLDivElement>(newCase.id);

  useEffect(() => {
    ref(elementRef.current);
  }, [ref]);

  return (
    <div id={newCase.id} ref={elementRef} className={styles.layout}>
      <p className={styles.alertTitle}>New Case Alert</p>
      <span className={styles.donorPlace}>{newCase.donorHospital.address}</span>

      <NewCaseSubItem newCase={newCase} />

      {newCase.sameUnosNewCases.map(sameUnosNewCase => (
        <NewCaseSubItem
          newCase={sameUnosNewCase}
          showTopBorder={true}
          key={`NewCaseSubItem-${sameUnosNewCase.id}`}
        />
      ))}
    </div>
  );
};
