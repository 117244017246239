import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { DomElementsContext } from '../contexts/DomElementsContext';

interface UseElementRefReturn<T> {
  ref: (reference: T | null) => void;
  element: T | null;
}

export const useElementRef = <
  T extends HTMLDivElement | SVGSVGElement | SVGPathElement,
>(
  id: string,
): UseElementRefReturn<T> => {
  const ref = useRef<T | null>(null);

  const { getElement, addElement, removeElement } =
    useContext(DomElementsContext);

  const element = useMemo<T | null>(() => getElement(id), [id, getElement]);

  const setRef = useCallback(
    (reference: T | null) => {
      ref.current = reference;

      addElement(id, reference);
    },
    [addElement, id],
  );

  useEffect(() => {
    if (ref.current) {
      setRef(ref.current);

      return () => {
        removeElement(id);
      };
    }
  }, [id, removeElement, setRef]);

  return {
    ref: setRef,
    element,
  };
};
