export const SortDescIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.10039 3.09998C2.91474 3.09998 2.73669 3.17373 2.60542 3.305C2.47414 3.43628 2.40039 3.61432 2.40039 3.79998C2.40039 3.98563 2.47414 4.16367 2.60542 4.29495C2.73669 4.42623 2.91474 4.49998 3.10039 4.49998H10.8004C10.986 4.49998 11.1641 4.42623 11.2954 4.29495C11.4266 4.16367 11.5004 3.98563 11.5004 3.79998C11.5004 3.61432 11.4266 3.43628 11.2954 3.305C11.1641 3.17373 10.986 3.09998 10.8004 3.09998H3.10039ZM3.10039 5.89998C2.91474 5.89998 2.73669 5.97373 2.60542 6.105C2.47414 6.23628 2.40039 6.41432 2.40039 6.59998C2.40039 6.78563 2.47414 6.96367 2.60542 7.09495C2.73669 7.22623 2.91474 7.29998 3.10039 7.29998H8.00039C8.18604 7.29998 8.36409 7.22623 8.49537 7.09495C8.62664 6.96367 8.70039 6.78563 8.70039 6.59998C8.70039 6.41432 8.62664 6.23628 8.49537 6.105C8.36409 5.97373 8.18604 5.89998 8.00039 5.89998H3.10039ZM3.10039 8.69998C2.91474 8.69998 2.73669 8.77373 2.60542 8.905C2.47414 9.03628 2.40039 9.21432 2.40039 9.39998C2.40039 9.58563 2.47414 9.76367 2.60542 9.89495C2.73669 10.0262 2.91474 10.1 3.10039 10.1H5.90039C6.08604 10.1 6.26409 10.0262 6.39537 9.89495C6.52664 9.76367 6.60039 9.58563 6.60039 9.39998C6.60039 9.21432 6.52664 9.03628 6.39537 8.905C6.26409 8.77373 6.08604 8.69998 5.90039 8.69998H3.10039ZM11.5004 6.59998C11.5004 6.41432 11.4266 6.23628 11.2954 6.105C11.1641 5.97373 10.986 5.89998 10.8004 5.89998C10.6147 5.89998 10.4367 5.97373 10.3054 6.105C10.1741 6.23628 10.1004 6.41432 10.1004 6.59998V10.5102L9.19529 9.60508C9.06327 9.47756 8.88645 9.40701 8.70291 9.4086C8.51937 9.4102 8.3438 9.48382 8.21402 9.6136C8.08423 9.74339 8.01061 9.91896 8.00902 10.1025C8.00742 10.286 8.07798 10.4629 8.20549 10.5949L10.3055 12.6949C10.4368 12.8261 10.6148 12.8998 10.8004 12.8998C10.986 12.8998 11.164 12.8261 11.2953 12.6949L13.3953 10.5949C13.5228 10.4629 13.5934 10.286 13.5918 10.1025C13.5902 9.91896 13.5166 9.74339 13.3868 9.6136C13.257 9.48382 13.0814 9.4102 12.8979 9.4086C12.7143 9.40701 12.5375 9.47756 12.4055 9.60508L11.5004 10.5102V6.59998Z"
      fill="#5B728A"
    />
  </svg>
);
