import {
  createTheme,
  formControlLabelClasses,
  outlinedInputClasses,
  radioClasses,
  svgIconClasses,
  tabClasses,
  Theme,
  ThemeOptions,
} from '@mui/material';
import { deepmerge } from '@mui/utils';

import { components } from './components';
import { themeBase } from './theme-base';

const { spacing, palette } = themeBase;
export const darkTheme: Theme = createTheme(
  deepmerge(themeBase, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: { color: '#ddd' },
          ['*::-webkit-scrollbar']: {
            width: 16,

            ['&-track']: {
              background: 'transparent',
            },

            ['&-thumb']: {
              borderRadius: 8,
              backgroundColor: palette.mapBlue[700],
              border: '6px solid #000',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: spacing(0.5),
            backgroundColor: '#364B5F',
            height: spacing(3),
            color: palette.grey[200],
            '&:hover': {
              backgroundColor: '#33383F',
            },
          },
          label: {
            padding: spacing(0, 1),
            fontSize: 14,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '&:hover': {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#33383F',
              },
            },
          },
          label: {
            fontSize: 16,
          },
          icon: {
            right: 12,
            top: 'calc(50% - 10px)',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            [`.${formControlLabelClasses.root}`]: {
              padding: spacing(1, 2),
              margin: 0,
              gap: spacing(2),
              [`.${radioClasses.root}`]: {
                padding: 0,
              },
            },
            '&:hover': {
              backgroundColor: palette.mapBlue[700],
            },
          },
          outlined: {
            borderColor: '#33383F',
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: `${palette.mapBlue[700]}80`,
            color: palette.grey[200],
            borderColor: palette.mapBlue[700],
            borderWidth: 2,
            padding: spacing(1, 2),
            lineHeight: '23px',
            textTransform: 'none',
            borderRadius: 10,
            fontSize: '16px',
            '&:hover': {
              backgroundColor: `${palette.mapBlue[700]}80`,
              borderWidth: 2,
              borderColor: '#33383F',
            },
          },
          label: {
            fontSize: 16,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: `${palette.mapBlue[700]}80`,
            color: palette.grey[200],
            padding: spacing(1, 2),
            gap: spacing(2),
            [`.${svgIconClasses.root}`]: {
              fill: '#FFFFFF',
            },
            '&:hover': {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#33383F',
              },
            },
            '&.Mui-focused': {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#33383F',
              },
            },
          },
          input: {
            lineHeight: '27px',
            height: 'auto',
            fontSize: spacing(2),
            fontWeight: 500,
            // fontFamily: "'JetBrains Mono', monospace",
            padding: 0,
          },
          notchedOutline: {
            borderWidth: 2,
            lineHeight: '27px',
            borderColor: palette.mapBlue[700],
            borderRadius: spacing(1.25),
            // background: '#1D2A3780',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            marginTop: spacing(0.5),
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            color: palette.grey[200],
            borderRadius: 10,
            borderWidth: 2,
            borderColor: '#364B5F',
            borderStyle: 'solid',
            padding: 0,
            background: palette.mapBlue[700],
            boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.70)',
            ['&::-webkit-scrollbar']: {
              width: 16,

              ['&-track']: {
                background: 'transparent',
              },

              ['&-thumb']: {
                borderRadius: 8,
                background: '#5B728A',
                border: `6px solid ${palette.mapBlue[700]}`,
              },
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            color: '#FFFFFF',
            '&:hover': {
              color: '#FFFFFF',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            display: 'flex',
            gap: spacing(2),
            padding: spacing(1, 2),
            alignItems: 'center',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: 20,
            height: 20,
            transition: 'all 200ms ease-in-out',
            color: '#364B5F',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            display: 'flex',
            gap: spacing(1.5),
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            textTransform: 'none',
            [`&:hover`]: {
              color: '#ddd',
            },
            [`&.${tabClasses.selected}`]: {
              color: '#ddd',
              background: '#364B5F',
            },
          },
          textColorPrimary: {
            color: '#87888B',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            padding: spacing(1, 3),
            background: 'rgba(29, 42, 55, 0.50)',
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            fill: 'green',
          },
        },
      },
      ...components,
    },
  } as ThemeOptions),
);
