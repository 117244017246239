export const SortIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.09961 4.49999C3.09961 4.31434 3.17336 4.13629 3.30463 4.00501C3.43591 3.87374 3.61396 3.79999 3.79961 3.79999H12.1996C12.3853 3.79999 12.5633 3.87374 12.6946 4.00501C12.8259 4.13629 12.8996 4.31434 12.8996 4.49999C12.8996 4.68564 12.8259 4.86369 12.6946 4.99496C12.5633 5.12624 12.3853 5.19999 12.1996 5.19999H3.79961C3.61396 5.19999 3.43591 5.12624 3.30463 4.99496C3.17336 4.86369 3.09961 4.68564 3.09961 4.49999ZM3.09961 7.99999C3.09961 7.81434 3.17336 7.63629 3.30463 7.50501C3.43591 7.37374 3.61396 7.29999 3.79961 7.29999H12.1996C12.3853 7.29999 12.5633 7.37374 12.6946 7.50501C12.8259 7.63629 12.8996 7.81434 12.8996 7.99999C12.8996 8.18564 12.8259 8.36369 12.6946 8.49496C12.5633 8.62624 12.3853 8.69999 12.1996 8.69999H3.79961C3.61396 8.69999 3.43591 8.62624 3.30463 8.49496C3.17336 8.36369 3.09961 8.18564 3.09961 7.99999ZM3.09961 11.5C3.09961 11.3143 3.17336 11.1363 3.30463 11.005C3.43591 10.8737 3.61396 10.8 3.79961 10.8H7.99961C8.18526 10.8 8.36331 10.8737 8.49458 11.005C8.62586 11.1363 8.69961 11.3143 8.69961 11.5C8.69961 11.6856 8.62586 11.8637 8.49458 11.995C8.36331 12.1262 8.18526 12.2 7.99961 12.2H3.79961C3.61396 12.2 3.43591 12.1262 3.30463 11.995C3.17336 11.8637 3.09961 11.6856 3.09961 11.5Z"
      fill="#364B5F"
    />
  </svg>
);
