import {
  DateRangeOutlined as DateRangeOutlinedIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {
  Box,
  styled,
  outlinedInputClasses,
  FormControl,
  Button,
  buttonClasses,
  paperClasses,
} from '@mui/material';
import {
  NonEmptyDateRange,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { extend, type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useState, MouseEvent, useEffect } from 'react';

import { ShortcutMenu } from './ShortcutMenu';
import { useStats } from './useStats';

extend(utc);
extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);

const PREFIX = 'TSMED-maintStats-dateFilter';

const dateFilterClasses = {
  date: `${PREFIX}-date`,
  shortcut: `${PREFIX}-shortcut`,
  shortcutMenu: `${PREFIX}-shortcutMenu`,
  shortcutRadioIcon: `${PREFIX}-shortcutRadioIcon`,
  shortcutRadioIconChecked: `${PREFIX}-shortcutRadioIconChecked`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  display: 'flex',
  [`.${outlinedInputClasses.notchedOutline}`]: {
    // borderRadius: 0,
  },
  [`.${dateFilterClasses.date}`]: {
    flex: '0 16 fit-content',
    [`.${outlinedInputClasses.input}`]: {
      textOverflow: 'ellipsis',
    },
    [`.${outlinedInputClasses.notchedOutline}`]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  [`.${dateFilterClasses.shortcut}`]: {
    [`.${paperClasses.root}`]: {},
    [`.${buttonClasses.root}`]: {
      marginLeft: -2,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: 'fit-content',
      whiteSpace: 'nowrap',
    },
  },
  [`.${dateFilterClasses.shortcutRadioIcon}`]: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: '#364B5F',
    border: '1px solid #7591AD',
    boxSizing: 'borderBox',
  },
}));

export const DateFilter = () => {
  const { dateRange, dispatch, activeShortcut } = useStats();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (activeShortcut) {
      dispatch({
        type: 'setRange',
        payload: activeShortcut.getValue({
          isValid: () => true,
        }) as NonEmptyDateRange<Dayjs>,
      });
    }
  }, [activeShortcut, dispatch]);
  return (
    <Root>
      <DateRangePicker
        className={dateFilterClasses.date}
        value={dateRange}
        timezone={tz}
        onChange={([dateFrom, dateTo]) => {
          const from =
            dateFrom &&
            dayjs()
              .tz()
              .year(dateFrom.year())
              .month(dateFrom.month())
              .date(dateFrom.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0);
          const to =
            dateTo &&
            dayjs()
              .tz()
              .year(dateTo.year())
              .month(dateTo.month())
              .date(dateTo.date())
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0);
          if (from || to) {
            dispatch({
              type: 'setRange',
              payload: [from || to, to || from] as NonEmptyDateRange<Dayjs>,
            });
            dispatch({
              type: 'setActiveShortcut',
            });
          }
        }}
        slots={{
          field: SingleInputDateRangeField,
          switchViewIcon: DateRangeOutlinedIcon,

          // leftArrowIcon: DateRangeOutlinedIcon,
        }}
        slotProps={{
          textField: {
            InputProps: { startAdornment: <DateRangeOutlinedIcon /> },
            variant: 'outlined',
          },
        }}
        name="allowedRange"
        localeText={{ start: 'Date From', end: 'Date To' }}
      />
      <FormControl className={dateFilterClasses.shortcut}>
        <Button
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
        >
          {activeShortcut?.label ?? 'Custom'}
        </Button>
        <ShortcutMenu anchorEl={anchorEl} onClose={handleClose} />
      </FormControl>
    </Root>
  );
};
