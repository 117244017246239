import { useContext, useMemo } from 'react';

import { ReactComponent as CarSVG } from '../../assets/car.svg';
import { ReactComponent as CheckSVG } from '../../assets/check.svg';
import { ReactComponent as ClockSVG } from '../../assets/clock.svg';
import { ReactComponent as GearStatsSVG } from '../../assets/gear-stats.svg';
import { ReactComponent as NoCrewSVG } from '../../assets/noCrew.svg';
import { ReactComponent as PlaneSVG } from '../../assets/plane.svg';
import { ReactComponent as PlaneOnFlySVG } from '../../assets/planeOnFly.svg';
import { ReactComponent as TwoArrowsSVG } from '../../assets/twoArrows.svg';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import {
  getConicGradient,
  AvailabilityColorType,
} from '../../components/Statistics';
import { TechnicalTimers } from '../../components/TechnicalTimers';
import { MapContext } from '../../contexts/MapContext';
import { currentTimeInTimezone } from '../../helpers/timezoneHelper';
import {
  AviationStatisticsType,
  CasesShareStatisticsType,
} from '../../types/new';

import { StatisticsItem } from './StatisticsItem';
import styles from './StatisticsPage.module.scss';

type StatisticsDataType = {
  airGroundBg: string;
  tmdxShareBg: string;
  tmdxWaitingForCase: number;
  tmdxWaitingForCaseBg: string;
  tmdxOnCase: number;
  tmdxOnCaseBg: string;
  tmdxNonCase: number;
  tmdxNonCaseBg: string;
  tmdxNoCrew: number;
  tmdxNoCrewBg: string;
  tmdxMaintenance: number;
  tmdxMaintenanceBg: string;
} & CasesShareStatisticsType &
  AviationStatisticsType;

export const AIR_GROUND_COLOR: AvailabilityColorType[] = [
  {
    main: '#62666C',
    secondary: '#0E5795',
    minPercents: 0,
  },
];
export const AVAILABILITY_COLOR: AvailabilityColorType[] = [
  {
    main: '#006A17',
    secondary: '#00E7331A',
    minPercents: 70,
  },
  {
    main: '#8B5C00',
    secondary: '#FFAF3833',
    minPercents: 50,
  },
  {
    main: '#851D24',
    secondary: '#FF384433',
    minPercents: 0,
  },
];
export const AVIA_SHARE_COLOR: AvailabilityColorType[] = [
  {
    main: '#006A17',
    secondary: '#00E7331A',
    minPercents: 70,
  },
  {
    main: '#8B5C00',
    secondary: '#FFAF3833',
    minPercents: 50,
  },
  {
    main: '#851D24',
    secondary: '#FF384433',
    minPercents: 0,
  },
];
export const ON_CASE_COLOR: AvailabilityColorType[] = [
  {
    main: '#006A17',
    secondary: '#00E7331A',
    minPercents: 70,
  },
  {
    main: '#8B5C00',
    secondary: '#FFAF3833',
    minPercents: 50,
  },
  {
    main: '#851D24',
    secondary: '#FF384433',
    minPercents: 0,
  },
];
export const WAITING_FOR_CASE_COLOR: AvailabilityColorType[] = [
  {
    main: '#851D24',
    secondary: '#FF384433',
    minPercents: 51,
  },
  {
    main: '#8B5C00',
    secondary: '#FFAF3833',
    minPercents: 31,
  },
  {
    main: '#006A17',
    secondary: '#00E7331A',
    minPercents: 0,
  },
];
export const NON_CASE_COLOR: AvailabilityColorType[] = [
  {
    main: '#851D24',
    secondary: '#FF384433',
    minPercents: 51,
  },
  {
    main: '#8B5C00',
    secondary: '#FFAF3833',
    minPercents: 31,
  },
  {
    main: '#006A17',
    secondary: '#00E7331A',

    minPercents: 0,
  },
];
export const NO_CREW_COLOR: AvailabilityColorType[] = [
  {
    main: '#0E5795',
    secondary: '#62666C',
    minPercents: 0,
  },
];
export const MAINTENANCE_COLOR: AvailabilityColorType[] = [
  {
    main: '#851D24',
    secondary: '#FF384433',
    minPercents: 21,
  },
  {
    main: '#8B5C00',
    secondary: '#FFAF3833',
    minPercents: 11,
  },
  {
    main: '#006A17',
    secondary: '#00E7331A',
    minPercents: 0,
  },
];

export const StatisticsPageContent = () => {
  const { allData } = useContext(MapContext);
  const statistics = allData?.statistics;
  const statisticsData = useMemo<StatisticsDataType | null>(() => {
    if (!statistics) {
      return null;
    }
    const {
      casesStatistics: { allCases, airGround, tmdxShare },
      aviationStatistics: {
        crewOnCaseHours,
        crewOnNonCaseHours,
        crewTotalHours,
        crewWaitingForCaseHours,
        maintenancesHours,
        noCrewHours,
        days,
      },
    } = statistics;

    const airGroundShare = Math.round((airGround / allCases) * 100);
    const groundShare = 100 - airGroundShare;
    const tmdxSharePercents = Math.round((tmdxShare / airGround) * 100);

    const tmdxWaitingForCase = Math.round(
      (crewWaitingForCaseHours / crewTotalHours) * 100,
    );
    const tmdxOnCase = Math.round((crewOnCaseHours / crewTotalHours) * 100);
    const tmdxNonCase = Math.round((crewOnNonCaseHours / crewTotalHours) * 100);

    const unavailableHours = maintenancesHours + noCrewHours;
    const tmdxNoCrew = Math.round((noCrewHours / unavailableHours) * 100);
    const tmdxMaintenance = Math.round(
      (maintenancesHours / unavailableHours) * 100,
    );

    const tmdxAirGround =
      AIR_GROUND_COLOR.find(i => i.minPercents <= groundShare) ||
      AIR_GROUND_COLOR[0];
    const tmdxShareColors =
      AVIA_SHARE_COLOR.find(i => i.minPercents <= tmdxSharePercents) ||
      AVIA_SHARE_COLOR[0];
    const tmdxWaitingForCaseColors =
      WAITING_FOR_CASE_COLOR.find(i => i.minPercents <= tmdxWaitingForCase) ||
      WAITING_FOR_CASE_COLOR[0];
    const tmdxOnCaseColors =
      ON_CASE_COLOR.find(i => i.minPercents <= tmdxOnCase) || ON_CASE_COLOR[0];
    const tmdxNonCaseColors =
      NON_CASE_COLOR.find(i => i.minPercents <= tmdxNonCase) ||
      NON_CASE_COLOR[0];
    const tmdxNoCrewColors =
      NO_CREW_COLOR.find(i => i.minPercents <= tmdxNoCrew) || NO_CREW_COLOR[0];
    const tmdxMaintenanceColors =
      MAINTENANCE_COLOR.find(i => i.minPercents <= tmdxMaintenance) ||
      MAINTENANCE_COLOR[0];

    return {
      crewOnCaseHours: Math.round(crewOnCaseHours),
      crewOnNonCaseHours: Math.round(crewOnNonCaseHours),
      crewTotalHours: Math.round(crewTotalHours),
      crewWaitingForCaseHours: Math.round(crewWaitingForCaseHours),
      maintenancesHours: Math.round(maintenancesHours),
      noCrewHours: Math.round(noCrewHours),
      ground: groundShare,
      airGround: airGroundShare,
      airGroundBg: getConicGradient({
        mainColor: tmdxAirGround.main,
        secondaryColor: tmdxAirGround.secondary,
        mainPercents: groundShare,
      }),
      tmdxShare: tmdxSharePercents,
      tmdxShareBg: getConicGradient({
        mainColor: tmdxShareColors.main,
        secondaryColor: tmdxShareColors.secondary,
        mainPercents: tmdxSharePercents,
      }),
      tmdxWaitingForCase,
      tmdxWaitingForCaseBg: getConicGradient({
        mainColor: tmdxWaitingForCaseColors.main,
        secondaryColor: tmdxWaitingForCaseColors.secondary,
        mainPercents: tmdxWaitingForCase,
      }),
      tmdxOnCase,
      tmdxOnCaseBg: getConicGradient({
        mainColor: tmdxOnCaseColors.main,
        secondaryColor: tmdxOnCaseColors.secondary,
        mainPercents: tmdxOnCase,
      }),
      tmdxNonCase,
      tmdxNonCaseBg: getConicGradient({
        mainColor: tmdxNonCaseColors.main,
        secondaryColor: tmdxNonCaseColors.secondary,
        mainPercents: tmdxNonCase,
      }),
      tmdxNoCrew,
      tmdxNoCrewBg: getConicGradient({
        mainColor: tmdxNoCrewColors.main,
        secondaryColor: tmdxNoCrewColors.secondary,
        mainPercents: tmdxNoCrew,
      }),
      tmdxMaintenance,
      tmdxMaintenanceBg: getConicGradient({
        mainColor: tmdxMaintenanceColors.main,
        secondaryColor: tmdxMaintenanceColors.secondary,
        mainPercents: tmdxMaintenance,
      }),
      days,
    };
  }, [statistics]);
  return (
    (statistics && statisticsData && (
      <div className={styles.layout}>
        <div className={styles.header}>
          <div className={styles.title}>
            TMDX Aviation Statistic for last {statisticsData.days} days
          </div>
          <div className={styles.subtitle}>
            Last update{' '}
            {currentTimeInTimezone(
              new Date(statistics.lastUpdated),
              'America/New_York',
            )}{' '}
            ET
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.card}>
            <div className={styles.header}>
              <div className={styles.count}>
                {statistics.casesStatistics.allCases}
              </div>
              <div className={styles.helperText}>Total Cases</div>
            </div>

            <div className={styles.content}>
              <StatisticsItem
                icon={<PlaneOnFlySVG />}
                percents={`${statisticsData.airGround}`}
                bg={statisticsData.airGroundBg}
                extraIcon={<CarSVG />}
                extraPercents={`${statisticsData.ground}`}
                label={'Air vs Ground'}
                mainColor="#0e5795"
              />
              <StatisticsItem
                icon={<PlaneSVG />}
                bg={statisticsData.tmdxShareBg}
                percents={`${statisticsData.tmdxShare}`}
                label={'TMDX Avia Share'}
              />
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.header}>
              <div className={styles.count}>
                {statisticsData.crewTotalHours} hours
              </div>
              <div className={styles.helperText}>TMDX Avia Available</div>
            </div>
            <div className={styles.content}>
              <StatisticsItem
                icon={<ClockSVG />}
                bg={statisticsData.tmdxWaitingForCaseBg}
                percents={`${statisticsData.tmdxWaitingForCase}`}
                hours={`${statisticsData.crewWaitingForCaseHours}`}
                label={'Waiting for Case'}
              />
              <StatisticsItem
                icon={<CheckSVG />}
                bg={statisticsData.tmdxOnCaseBg}
                percents={`${statisticsData.tmdxOnCase}`}
                hours={`${statisticsData.crewOnCaseHours}`}
                label={'On Case'}
              />
              <StatisticsItem
                icon={<TwoArrowsSVG />}
                bg={statisticsData.tmdxNonCaseBg}
                percents={`${statisticsData.tmdxNonCase}`}
                hours={`${statisticsData.crewOnNonCaseHours}`}
                label={'Non-Case transportation'}
              />
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.header}>
              <div className={styles.count}>
                {statisticsData.maintenancesHours + statisticsData.noCrewHours}{' '}
                hours
              </div>
              <div className={styles.helperText}>TMDX Avia Unavailable</div>
            </div>

            <div className={styles.content}>
              <StatisticsItem
                bg={statisticsData.tmdxNoCrewBg}
                icon={<NoCrewSVG />}
                percents={`${statisticsData.tmdxNoCrew}`}
                hours={`${statisticsData.noCrewHours}`}
                label={'No Crew'}
              />
              <StatisticsItem
                icon={<GearStatsSVG />}
                bg={statisticsData.tmdxMaintenanceBg}
                percents={`${statisticsData.tmdxMaintenance}`}
                hours={`${statisticsData.maintenancesHours}`}
                label={'Maintenance'}
              />
            </div>
          </div>
        </div>
      </div>
    )) || <Loader isLoading={true} />
  );
};

export const StatisticsPage = () => (
  <Layout>
    {<StatisticsPageContent />}
    <TechnicalTimers />
  </Layout>
);
