import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { Box } from '@mui/material';
import classnames from 'classnames';

import { cellDrawerClasses } from './CellDrawerRoot';

export type CellDrawerCountsProps = {
  assignedCount: number;
  availableCount: number;
};

export const CellDrawerCounts = ({
  assignedCount,
  availableCount,
}: CellDrawerCountsProps) => (
  <Box className={cellDrawerClasses.counts}>
    <Box
      className={classnames(cellDrawerClasses.countBlock, {
        [cellDrawerClasses.available]: availableCount,
        [cellDrawerClasses.unavailable]: !availableCount,
      })}
    >
      <Box className={cellDrawerClasses.countsIcon}>
        <BackHandOutlinedIcon />
      </Box>
      <Box className={cellDrawerClasses.countsNumber}>{availableCount}</Box>
      <Box className={cellDrawerClasses.countsText}>Available</Box>
    </Box>
    <Box
      className={classnames(cellDrawerClasses.countBlock, {
        [cellDrawerClasses.assigned]: assignedCount,
        [cellDrawerClasses.unavailable]: !assignedCount,
      })}
    >
      <Box className={cellDrawerClasses.countsIcon}>
        <DoDisturbAltIcon />
      </Box>
      <Box className={cellDrawerClasses.countsNumber}>{assignedCount}</Box>
      <Box className={cellDrawerClasses.countsText}>Assigned</Box>
    </Box>
  </Box>
);
