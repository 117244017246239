import classnames from 'classnames';
import { ReactNode, useState } from 'react';

import { ReactComponent as DotsSVG } from '../../assets/dots.svg';

import styles from './PageDropdown.module.scss';

interface Props {
  id: string;
  items: {
    id: string;
    icon: ReactNode;
    title: string;
  }[];
  fixed?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const PageDropdown = ({
  id,
  items,
  value,
  onChange,
  fixed = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onMouseEnter = () => setIsOpen(true);
  const onMouseLeave = () => setIsOpen(false);

  const onValueChange = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div
      className={styles.dropdown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        ...((fixed && { position: 'fixed' }) || {}),
      }}
    >
      <div
        className={classnames(styles.dropdownIcon, {
          [styles.dropdownIconActive]: isOpen,
        })}
      >
        <DotsSVG width="20px" />
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {items.map(item => (
            <div
              key={`${id}-${item.id}`}
              className={classnames(styles.dropdownItem, {
                [styles.dropdownItemActive]: value === item.id,
              })}
              onClick={() => onValueChange(item.id)}
            >
              <div className={styles.dropdownItemIcon}>{item.icon}</div>
              <span className={styles.dropdownItemTitle}>{item.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
