import { useMemo, ReactNode } from 'react';

import { useQueryPireps } from '../../../adapters';
import { hashCode } from '../../../helpers';
import { getPoint } from '../../../helpers/pathGeneration';
import {
  TAircraftReportTurbulenceCondition,
  TAircraftReportIcingCondition,
} from '../../../types';
import {
  tbNegSvgPath,
  tbSmoothLgtSvgPath,
  tbLgtSvgPath,
  tbLgtModSvgPath,
  tbModSvgPath,
  tbModSevSvgPath,
  tbSevSvgPath,
  tbExtrmSvgPath,
  tbLlwsSvgPath,
  icNegSvgPath,
  icTraceSvgPath,
  icTraceLgtSvgPath,
  icLgtSvgPath,
  icLgtModSvgPath,
  icModSvgPath,
  icModSevSvgPath,
  icSevSvgPath,
  icOtherSvgPath,
} from '../weather-Icons';

import { useWeatherFilter } from './useWeatherFilter';

const tbIntensityMap: Record<
  TAircraftReportTurbulenceCondition['turbulence_intensity'],
  string
> = {
  ['NEG']: tbNegSvgPath,
  ['SMOOTH']: tbSmoothLgtSvgPath,
  ['LGT']: tbLgtSvgPath,
  ['LGT-MOD']: tbLgtModSvgPath,
  ['MOD']: tbModSvgPath,
  ['MOD-SEV']: tbModSevSvgPath,
  ['SEV']: tbSevSvgPath,
  ['EXTRM']: tbExtrmSvgPath,
  ['LLWS']: tbLlwsSvgPath,
};

const icIntensityMap: Record<
  TAircraftReportIcingCondition['icing_intensity'],
  string
> = {
  ['NEG']: icNegSvgPath,
  ['TRACE']: icTraceSvgPath,
  ['TRACE-LGT']: icTraceLgtSvgPath,
  ['LGT']: icLgtSvgPath,
  ['LGT-MOD']: icLgtModSvgPath,
  ['MOD']: icModSvgPath,
  ['MOD-SEV']: icModSevSvgPath,
  ['SEV']: icSevSvgPath,
  ['OTHER']: icOtherSvgPath,
};

const extractTbIntensity = (
  s: string,
): TAircraftReportTurbulenceCondition['turbulence_intensity'] | null => {
  const matches =
    /\/TB ([^/]+)/.exec(s)?.[1] ?? / TB (.+)(?:\s(IC|SK))/.exec(s)?.[1];

  if (!matches) {
    return null;
  }
  return [
    ...matches.matchAll(
      /NEG|SMOOTH|LGT|LIGHT|LGT-MOD|MOD|MODERATE|MOD-SEV|SEV|EXTRM|LLWS/g,
    ),
  ]
    .map(i => i[0])
    .join('-')
    .replaceAll('LIGHT', 'LGT')
    .replaceAll(
      'MODERATE',
      'MOD',
    ) as TAircraftReportTurbulenceCondition['turbulence_intensity'];
};
const extractIcIntensity = (
  s: string,
): TAircraftReportIcingCondition['icing_intensity'] | null => {
  const matches =
    /\/IC ([^/]+)/.exec(s)?.[1] ?? / IC (.+)(?:\sSK)/.exec(s)?.[1];

  if (!matches) {
    return null;
  }
  return [
    ...matches.matchAll(
      /NEG|TRACE|TRACE-LGT|LGT|LGT-MOD|MOD|MOD-SEV|SEV|OTHER'/g,
    ),
  ]
    .map(i => i[0])
    .join('-')
    .replaceAll('LIGHT', 'LGT')
    .replaceAll(
      'MODERATE',
      'MOD',
    ) as TAircraftReportIcingCondition['icing_intensity'];
};

export const usePireps = () => {
  const { data: pirepsData } = useQueryPireps();

  const { filter } = useWeatherFilter();

  return useMemo(() => {
    if (!pirepsData) {
      return [];
    }
    return (
      pirepsData
        // .filter(a => /(MOD|SEV|EXTRM|LLWS)/.test(a.raw_text))
        .reduce<ReactNode[]>((acc, a, index) => {
          const point = getPoint([a.longitude, a.latitude]);

          if (point) {
            const key = hashCode(`${point.toString()}-${index} `);
            const icIntensity = extractIcIntensity(a.raw_text);
            if (icIntensity && filter.pireps.ic[`IC-${icIntensity}`]) {
              const iconPath = icIntensityMap[icIntensity];
              acc.push(
                <image
                  href={iconPath}
                  key={`${key}-pirep-ic`}
                  width={16}
                  height={16}
                  x={point[0]}
                  y={point[1]}
                  opacity={0.75}
                />,
              );
            }
            const tbIntensity = extractTbIntensity(a.raw_text);
            if (tbIntensity && filter.pireps.tb[`TB-${tbIntensity}`]) {
              const iconPath = tbIntensityMap[tbIntensity];
              acc.push(
                <image
                  href={iconPath}
                  key={`${key}-pirep-tb`}
                  width={16}
                  height={16}
                  x={point[0]}
                  y={point[1]}
                  opacity={0.75}
                />,
              );
            }
          }

          return acc;
        }, [])
    );
  }, [pirepsData, filter.pireps]);
};
