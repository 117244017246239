import { useMemo } from 'react';

import { useQueryStatsMTTR } from '../../../../adapters';
import { statsFormatCount, statsFormatHours } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowMTTRAog = MobileTableRow<{
  aogHours: number;
  aogCount: number;
}>;
export const StatsMTTRAogTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsMTTR);

  const rows: MobileTableRowMTTRAog[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowMTTRAog[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const rows: MobileTableRowMTTRAog[] = Object.values(
      statsData.perAircraft,
    ).map(
      ({ tailNumber, aogCount, intervals, aogValue, aogDur, aogDurPrev }) => {
        const intervalValues = intervalFields.map(date => {
          const aogHours = intervals[date].aogValue;
          const aogCount = intervals[date].aogCount;
          return {
            date,
            aogHours,
            aogCount,
          };
        });

        return {
          tailNumber,
          aogHours: aogValue,
          aogCount,
          intervals: intervalValues,
          rate: [aogDur, aogDurPrev] as [number, number],
        };
      },
    );
    return rows;
  }, [statsData]);

  return (
    <StatsTableMobile
      rows={rows}
      intervalField={{
        fieldName: 'aogHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        fieldName: 'aogCount',
        valueFormatter: statsFormatCount,
      }}
      rateField={{ valueFormatter: statsFormatHours }}
      summaryRows={[
        {
          field: 'aogHours',
          label: 'Total AOG Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'aogCount',
          label: 'Total AOG Events',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          label: 'Average AOG Duration',
          valueFormatter: statsFormatHours,
        },
      ]}
      intervalColumns={['', 'AOG h / AOGs']}
      rateStrategy="desc"
    />
  );
};
