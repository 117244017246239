import classnames from 'classnames';
import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';

import { ReactComponent as CarSVG } from '../../assets/car.svg';
import { ReactComponent as PlaneOnFlySVG } from '../../assets/planeOnFly.svg';
import { currentTimeInTimezone } from '../../helpers/timezoneHelper';
import { StatisticsType } from '../../types/new';

import {
  CasesStatisticsDataType,
  getConicGradient,
  AvailabilityColorType,
} from './colors';
import styles from './Statistics.module.scss';

interface Props {
  statistics: StatisticsType;
}

const AVAILABILITY_COLOR: AvailabilityColorType[] = [
  {
    main: '#006A17',
    secondary: '#00E7331A',
    minPercents: 70,
  },
  {
    main: '#8B5C00',
    secondary: '#FFAF3833',
    minPercents: 50,
  },
  {
    main: '#851D24',
    secondary: '#FF384433',
    minPercents: 0,
  },
];

const GROUND_COLOR = '#62666C';
const AIR_GROUND_COLOR = '#0E5795';

export const Statistics = ({ statistics }: Props) => {
  const casesStatisticsData = useMemo<CasesStatisticsDataType>(() => {
    const { allCases, airGround, tmdxShare } = statistics.casesStatistics;

    const airGroundShare = Math.round((airGround / allCases) * 100);
    const groundShare = 100 - airGroundShare;
    const tmdxSharePercents = Math.round((tmdxShare / airGround) * 100);

    const tmdxShareColors =
      AVAILABILITY_COLOR.find(i => i.minPercents <= tmdxSharePercents) ||
      AVAILABILITY_COLOR[0];

    return {
      airGround: airGroundShare,
      ground: groundShare,
      tmdxShare: tmdxSharePercents,
      airGroundBg: getConicGradient({
        mainColor: GROUND_COLOR,
        secondaryColor: AIR_GROUND_COLOR,
        mainPercents: groundShare,
      }),
      tmdxShareBg: getConicGradient({
        mainColor: tmdxShareColors.main,
        secondaryColor: tmdxShareColors.secondary,
        mainPercents: tmdxSharePercents,
      }),
    };
  }, [statistics.casesStatistics]);

  const fleetStatisticsGradient = useMemo<string>(() => {
    const tmdxShareColors =
      AVAILABILITY_COLOR.find(
        i => i.minPercents <= statistics.fleetAvailability,
      ) || AVAILABILITY_COLOR[0];

    return getConicGradient({
      mainColor: tmdxShareColors.main,
      secondaryColor: tmdxShareColors.secondary,
      mainPercents: statistics.fleetAvailability,
    });
  }, [statistics.fleetAvailability]);

  const daysCount = useMemo<number>(() => {
    const dateNow = new Date();
    const startDate = new Date(statistics.casesStatistics.startDate);

    return differenceInDays(dateNow, startDate) || 1;
  }, [statistics.casesStatistics.startDate]);

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <span className={styles.count}>
          {statistics.casesStatistics.allCases} Cases
        </span>
        <span className={styles.helperText}>for last {daysCount} days</span>
      </div>
      <div className={styles.pieChart}>
        <div
          className={styles.pieChartFragment}
          style={{
            background: casesStatisticsData.airGroundBg,
          }}
        />
        <div className={styles.chartContent}>
          <div className={styles.icons}>
            <div
              className={classnames(styles.iconText, styles.airGround, {
                [styles.iconTextBig]: casesStatisticsData.airGround > 50,
                [styles.iconTextSmall]: casesStatisticsData.airGround < 50,
              })}
            >
              <PlaneOnFlySVG />
              <span>{casesStatisticsData.airGround}%</span>
            </div>
            <div
              className={classnames(styles.iconText, styles.ground, {
                [styles.iconTextBig]: casesStatisticsData.ground > 50,
                [styles.iconTextSmall]: casesStatisticsData.ground < 50,
              })}
            >
              <CarSVG />
              <span>{casesStatisticsData.ground}%</span>
            </div>
          </div>
          <span
            className={styles.text}
            style={{
              letterSpacing: '-0.09em',
            }}
          >
            Air vs Ground
          </span>
        </div>
      </div>
      <div className={styles.pieChart}>
        <div
          className={styles.pieChartFragment}
          style={{
            background: casesStatisticsData.tmdxShareBg,
          }}
        />
        <div className={styles.chartContent}>
          <span className={styles.percents}>
            {casesStatisticsData.tmdxShare}%
          </span>
          <span className={styles.text}>TMDX Aviation Share</span>
        </div>
      </div>
      <div className={styles.pieChart}>
        <div
          className={styles.pieChartFragment}
          style={{
            background: fleetStatisticsGradient,
          }}
        />
        <div className={styles.chartContent}>
          <span className={styles.percents}>
            {statistics.fleetAvailability}%
          </span>
          <span className={styles.text}>TMDX Aviation Availability</span>
        </div>
      </div>
      <span className={styles.statusText}>
        Last update{' '}
        {currentTimeInTimezone(
          new Date(statistics.lastUpdated),
          'America/New_York',
        )}{' '}
        ET
      </span>
    </div>
  );
};
