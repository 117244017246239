import { useMemo } from 'react';

import { useQueryStatsFR } from '../../../../adapters';
import {
  statsFormatCount,
  statsFormatHours,
  statsFormatRate,
} from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowFRHours = MobileTableRow<{
  flightValue: number;
  aogCount: number;
}>;
export const StatsFRHoursTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsFR);

  const rows: MobileTableRowFRHours[] = useMemo(() => {
    const defaultResult: MobileTableRowFRHours[] = [];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const rows: MobileTableRowFRHours[] = Object.values(statsData.perAircraft)
      .map(
        ({
          tailNumber,
          aogCount,
          flightValue,
          aogFrequencyRatePerHours,
          aogFrequencyRatePerHoursPrev,
          intervals,
        }) => {
          const intervalValues = intervalFields.map(date => {
            const flightValue = +(intervals[date]?.flightValue ?? 0);
            const aogCount = +(intervals[date]?.aogCount ?? 0);
            return {
              date,
              flightValue,
              aogCount,
            };
          });

          return {
            tailNumber,
            flightValue,
            aogCount,
            rate: [aogFrequencyRatePerHours, aogFrequencyRatePerHoursPrev] as [
              number,
              number,
            ],
            intervals: intervalValues,
          };
        },
      )
      .sort((a, b) => (a.rate[0] > b.rate[0] ? -1 : 1));

    return rows;
  }, [statsData]);

  return (
    <StatsTableMobile
      rows={rows}
      intervalField={{
        fieldName: 'flightValue',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        fieldName: 'aogCount',
        valueFormatter: statsFormatCount,
      }}
      rateField={{
        valueFormatter: statsFormatRate,
      }}
      summaryRows={[
        {
          field: 'flightValue',
          label: 'Total Flight Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'aogCount',
          label: 'Total AOG Events',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'rate',
          label: 'AOG Frequency Rate',
          valueFormatter: statsFormatRate,
        },
      ]}
      intervalColumns={['', 'Flight h / AOGs']}
      rateStrategy="desc"
    />
  );
};
