import { useMemo } from 'react';

import {
  AircraftClusterTypes,
  AircraftOnAirportType,
  AirportType,
  ClusterDataEnum,
  ClusterItem,
  OrganIconType,
} from '../../../types/new';

import { AircraftCircle } from './AircraftCircle';

interface Props {
  cluster: ClusterItem<AircraftClusterTypes>;
}

export const AircraftsSvgCluster = ({ cluster }: Props) => {
  const data = useMemo(() => [...cluster.items].reverse(), [cluster.items]);

  return (
    <>
      {data.map(({ type, data }) => {
        let timeOnDuty = 0;

        const lng =
          data.Location?.Longitude ??
          (data as AircraftOnAirportType).Airport?.Longitude ??
          null;
        const lat =
          data.Location?.Latitude ??
          (data as AircraftOnAirportType).Airport?.Latitude ??
          null;
        const boundary =
          data.Location?.Boundary ??
          (data as AircraftOnAirportType).Airport?.Boundary ??
          null;
        if (type === ClusterDataEnum.OnGround) {
          timeOnDuty = data.TimeOnDuty || 0;
        }

        let organ: OrganIconType | null = null;
        let isLate = false;
        let isReturnToBase = false;
        let airports: AirportType[] = [];
        let isOnFly = false;
        let tripLegStart: string | null = null;
        let tripLegEnd: string | null = null;

        if (type === ClusterDataEnum.OnFly) {
          organ = data.Organ ?? null;
          isLate = !!data.IsLate;
          isReturnToBase = !!data.IsReturnToBase;
          airports = data.Airports;
          isOnFly = !!data.IsOnFly;
          tripLegStart = data.TripLegStart;
          tripLegEnd = data.TripLegEnd;
        }

        return (
          <AircraftCircle
            key={data.Id}
            id={data.Id}
            latitude={lat}
            longitude={lng}
            boundary={boundary}
            timeOnDuty={timeOnDuty}
            organ={organ}
            isLate={isLate}
            isOnFly={isOnFly}
            isReturnToBase={isReturnToBase}
            airports={airports}
            tripLegStart={tripLegStart}
            tripLegEnd={tripLegEnd}
          />
        );
      })}
    </>
  );
};
