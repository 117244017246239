import { ReactElement, useContext, useMemo } from 'react';

import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { TechnicalTimers } from '../../components/TechnicalTimers';
import { MapContext } from '../../contexts/MapContext';
import { THubsReportStatus, TRegion } from '../../types/new';

import { TableHeader } from './components/TableHeader';
import { TableRowGroupItem } from './components/TableRowGroupItem';
import styles from './HubsReportPage.module.scss';

export const HubsReportPageContent = () => {
  const { allData } = useContext(MapContext);

  const hubGroups = useMemo(
    () =>
      allData?.hubsReport.reduce<Record<string, THubsReportStatus[]>>(
        (acc, hub) => {
          const groupKey = hub.location.region;
          if (!acc[groupKey]) {
            acc[groupKey] = [];
          }
          acc[groupKey].push(hub);
          return acc;
        },
        {},
      ),
    [allData],
  );
  const groupItems = useMemo(() => {
    if (!hubGroups) {
      return;
    }
    return Object.keys(hubGroups).reduce<{
      groupItems: ReactElement[];
      startIndex: number;
    }>(
      (acc, groupKey) => {
        acc.groupItems.push(
          <TableRowGroupItem
            key={groupKey}
            rows={hubGroups[groupKey as TRegion]}
            region={groupKey}
            startIndex={acc.startIndex}
          />,
        );
        acc.startIndex += hubGroups[groupKey as TRegion].length;
        return acc;
      },
      { groupItems: [], startIndex: 0 },
    ).groupItems;
  }, [hubGroups]);
  return groupItems ? (
    <div className={styles.hubsReport}>
      <div className={styles.table}>
        <TableHeader />
        <ul className={styles.content}>{groupItems}</ul>
      </div>
    </div>
  ) : (
    <Loader isLoading={true} />
  );
};

export const HubsReportPage = () => (
  <Layout>
    {<HubsReportPageContent />}
    <TechnicalTimers />
  </Layout>
);
