import type { StatsQueryParams } from '../../../adapters/stats/types';

import { useStats } from './useStats';

export const useQueryData = <
  T extends (params: StatsQueryParams) => ReturnType<T>,
>(
  query: T,
) => {
  const { dateRange, selectedAircrafts, activeShortcut } = useStats();
  return query({
    dateTo: dateRange[1],
    aircrafts: selectedAircrafts,
    fromValue:
      activeShortcut?.periodDefinition[0] ??
      dateRange[1].diff(dateRange[0], 'day'),
    fromUnitType: activeShortcut?.periodDefinition[1] ?? 'day',
  });
};
