import { CasesShareStatisticsType } from '../../types/new';

export type AvailabilityColorType = {
  main: string;
  secondary: string;
  minPercents: number;
};

export const progressToDeg = (progress: number): number =>
  Math.floor((360 / 100) * progress);

export type CasesStatisticsDataType = {
  airGroundBg: string;
  tmdxShareBg: string;
} & CasesShareStatisticsType;

type GetConicGradientParams = {
  mainColor: string;
  secondaryColor: string;
  mainPercents: number;
  stopColor?: string;
};

export const getConicGradient = ({
  mainColor,
  secondaryColor,
  mainPercents,
  stopColor = '#000000',
}: GetConicGradientParams): string => {
  const mainDegrees = progressToDeg(mainPercents);
  const secondaryDegrees = 360 - mainDegrees;
  const stopDegrees = 2;

  // stop -> main start -> main stop -> stop start -> stop end -> secondary start -> secondary stop -> stop;
  const start = `${stopColor} ${stopDegrees}deg`;

  const mainStart = `${mainColor} ${stopDegrees}deg`;
  const mainEnd = `${mainColor} ${mainDegrees - stopDegrees}deg`;

  const stopStart = `${stopColor} ${mainDegrees - stopDegrees}deg`;
  const stopEnd = `${stopColor} ${mainDegrees + stopDegrees}deg`;

  const secondaryStart = `${secondaryColor} ${mainDegrees + stopDegrees}deg`;
  const secondaryEnd = `${secondaryColor} ${
    mainDegrees + secondaryDegrees - 2
  }deg`;

  const endStart = `${stopColor} ${mainDegrees + secondaryDegrees - 2}deg`;
  const endStop = `${stopColor} 360deg`;

  return `conic-gradient(${start}, ${mainStart}, ${mainEnd}, ${stopStart}, ${stopEnd}, ${secondaryStart}, ${secondaryEnd}, ${endStart}, ${endStop})`;
};
