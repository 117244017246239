import classNames from 'classnames';

import { useElementRef } from '../../hooks/useElementRef';
import { NewCaseType } from '../../types/new';
import { NewCaseAlert } from '../NewCaseAlert';

import styles from './AlertsColumn.module.scss';

interface Props {
  newCases: NewCaseType[];
  direction: 'west' | 'east' | 'blank';
  additionalClassName?: string;
}

export const AlertsColumn = ({
  newCases,
  direction,
  additionalClassName = '',
}: Props) => {
  const { ref } = useElementRef<HTMLDivElement>(`${direction}-alerts`);

  return (
    <div
      id={`${direction}-alerts`}
      ref={ref}
      className={classNames(styles.alerts, styles[additionalClassName])}
    >
      {newCases.map((curNewCase, index) => (
        <NewCaseAlert newCase={curNewCase} index={index} key={curNewCase.id} />
      ))}
    </div>
  );
};
