import { BBox } from '@turf/helpers';

export * from './hashCode';
export * from './boundaries';

export const inBBox = (pt: number[], bbox: BBox): boolean =>
  bbox[0] <= pt[0] && bbox[1] <= pt[1] && bbox[2] >= pt[0] && bbox[3] >= pt[1];

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
