import { useMemo } from 'react';

import { useQueryStatsFR } from '../../../../adapters';
import { statsFormatCount, statsFormatRate } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowFRCount = MobileTableRow<{
  flightCount: number;
  aogCount: number;
}>;
export const StatsFRCountsTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsFR);

  const countRows: MobileTableRowFRCount[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowFRCount[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const countRows: MobileTableRowFRCount[] = Object.values(
      statsData.perAircraft,
    )
      .map(
        ({
          tailNumber,
          flightCount,
          aogCount,
          aogFrequencyRatePerFlights,
          aogFrequencyRatePerFlightsPrev,
          intervals,
        }) => {
          const intervalValues = intervalFields.map(date => {
            const flightCount = intervals[date]?.flightCount ?? 0;
            const aogCount = intervals[date]?.aogCount ?? 0;
            return {
              date,
              flightCount,
              aogCount,
            };
          });

          return {
            tailNumber,
            flightCount,
            aogCount,
            rate: [
              aogFrequencyRatePerFlights ?? 0,
              aogFrequencyRatePerFlightsPrev ?? 0,
            ] as [number, number],
            intervals: intervalValues,
          };
        },
      )
      .sort((a, b) => (a.rate[0] > b.rate[0] ? -1 : 1));
    return countRows;
  }, [statsData]);

  return (
    <StatsTableMobile
      rows={countRows}
      rateField={{ valueFormatter: statsFormatRate }}
      intervalField={{
        fieldName: 'flightCount',
        valueFormatter: statsFormatCount,
      }}
      intervalSecondaryField={{
        fieldName: 'aogCount',
        valueFormatter: statsFormatCount,
      }}
      summaryRows={[
        {
          field: 'flightCount',
          label: 'Total Flights',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'aogCount',
          label: 'Total AOG Events',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'rate',
          label: 'AOG Frequency',
          valueFormatter: statsFormatRate,
        },
      ]}
      intervalColumns={['', 'Flights / AOGs']}
      rateStrategy="desc"
    />
  );
};
