import { useQuery } from '@tanstack/react-query';

import {
  REACT_APP_API_FETCH_INTERVAL,
  REACT_APP_BE_API_URL,
} from '../../const/const';
import { TAirMetar } from '../../types';

import { aviationWeatherFetch } from './aviationWeatherFetch';

const url = `${REACT_APP_BE_API_URL}api/aviation-weather/metars.cache.csv.gz`;

const transform = (raw: string) => {
  const rows = raw.split('\n');
  if (rows.length < 6) {
    throw new Error('Corrupted CSV file');
  }
  const rawData = rows.slice(6);

  const data: TAirMetar[] = rawData
    .filter(i => !!i)
    .map(row => {
      const [
        stationId,
        observationTime,
        latitude,
        longitude,
        tempC,
        dewpointC,
        windDirDegrees,
        windSpeedKt,
        windGustKt,
        visibilityStatuteMi,
        altimInHg,
        seaLevelPressureMb,
        corrected,
        auto,
        autoStation,
        maintenanceIndicatorOn,
        noSignal,
        lightningSensorOff,
        freezingRainSensorOff,
        presentWeatherSensorOff,
        wxString,
        skyCover0,
        cloudBaseFtAgl0,
        skyCover1,
        cloudBaseFtAgl1,
        skyCover2,
        cloudBaseFtAgl2,
        skyCover3,
        cloudBaseFtAgl3,
        flightCategory,
        threeHrPressureTendencyMb,
        maxTC,
        minTC,
        maxT24hrC,
        minT24hrC,
        precipIn,
        pcp3hrIn,
        pcp6hrIn,
        pcp24hrIn,
        snowIn,
        vertVisFt,
        metarType,
        elevationM,
      ] = row.split(',').slice(1);

      const metar: TAirMetar = {
        stationId,
        observationTime,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        tempC: parseFloat(tempC),
        dewpointC: parseFloat(dewpointC),
        windDirDegrees: parseInt(windDirDegrees),
        windSpeedKt: parseInt(windSpeedKt),
        windGustKt: parseInt(windGustKt),
        visibilityStatuteMi: parseFloat(visibilityStatuteMi),
        altimInHg: parseFloat(altimInHg),
        seaLevelPressureMb: parseInt(seaLevelPressureMb),
        corrected,
        wxString,
        qualityControlFlags: {
          auto: auto === 'TRUE',
          autoStation: autoStation === 'TRUE',
          presentWeatherSensorOff: presentWeatherSensorOff === 'TRUE',
          maintenanceIndicatorOn: maintenanceIndicatorOn === 'TRUE',
          lightningSensorOff: lightningSensorOff === 'TRUE',
          noSignal: noSignal === 'TRUE',
          freezingRainSensorOff: freezingRainSensorOff === 'TRUE',
          corrected: corrected === 'TRUE',
        },
        skyCondition: [
          {
            skyCover: skyCover0,
            cloudBaseFtAgl: cloudBaseFtAgl0,
          },
          {
            skyCover: skyCover1,
            cloudBaseFtAgl: cloudBaseFtAgl1,
          },
          {
            skyCover: skyCover2,
            cloudBaseFtAgl: cloudBaseFtAgl2,
          },
          {
            skyCover: skyCover3,
            cloudBaseFtAgl: cloudBaseFtAgl3,
          },
        ],
        flightCategory: flightCategory as TAirMetar['flightCategory'],
        threeHrPressureTendencyMb,
        maxTC,
        minTC: parseInt(minTC),
        maxT24hrC,
        minT24hrC,
        pcp3hrIn,
        pcp6hrIn,
        pcp24hrIn,
        snowIn,
        metarType: metarType as TAirMetar['metarType'],
        elevationM: elevationM ? parseInt(elevationM) : undefined,
        precipIn: precipIn ? parseInt(precipIn) : undefined,
        vertVisFt: vertVisFt ? parseInt(vertVisFt) : undefined,
      };
      return metar;
    });
  return data;
};

export const useQueryMetars = () =>
  useQuery({
    queryKey: ['metars'],
    queryFn: async () => await aviationWeatherFetch(url),
    select: transform,
    refetchInterval: 1000 * REACT_APP_API_FETCH_INTERVAL,
  });
