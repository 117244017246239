import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useQueryStatsDowntime } from '../../../../adapters';
import {
  statsFormatCount,
  statsFormatHours,
  statsFormatPercent,
} from '../../stat-constants';
import { StatsIntervalCell } from '../StatsIntervalCell';
import { StatsTable, type StatsTableRow } from '../StatsTable';
import { useQueryData } from '../useQueryData';

type TableRowDowntime = StatsTableRow<{
  downtimeHours: number;
  totalHours: number;
}>;

export const StatsDowntimeTable = () => {
  const { data: statsData } = useQueryData(useQueryStatsDowntime);

  const {
    intervalColumns,
    rows,
  }: {
    intervalColumns: GridColDef[];
    rows: TableRowDowntime[];
  } = useMemo(() => {
    const defaultResult = {
      intervalColumns: [],
      rows: [],
    };
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate);
    const intervalColumns: GridColDef[] = intervalFields.map(date => ({
      field: date,
      headerName: dayjs(date).format('DD.MM.YYYY'),
      minWidth: 120,
      type: 'custom',
      align: 'right',
      renderCell: ({ value }: GridRenderCellParams) => (
        <StatsIntervalCell value={value} />
      ),
    }));

    const rows: TableRowDowntime[] = Object.values(statsData.perAircraft).map(
      ({
        tailNumber,
        downtimeValue,
        totalHoursValue,
        downtimeRate,
        downtimeRatePrev,
        intervals,
      }) => {
        const intervalValues = keyBy(
          intervalFields.map(date => ({
            date,
            downtimeHours: intervals[date]?.downtimeValue ?? 0,
            totalHours: intervals[date]?.totalHoursValue ?? 0,
          })),
          'date',
        );

        return {
          id: tailNumber,
          tailNumber,
          downtimeHours: downtimeValue,
          totalHours: totalHoursValue,
          rate: [downtimeRate, downtimeRatePrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return { rows, intervalColumns };
  }, [statsData]);

  return (
    <StatsTable
      rows={rows}
      rateField={{ valueFormatter: statsFormatCount }}
      intervalField={{
        field: 'downtimeHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        field: 'totalHours',
        valueFormatter: statsFormatHours,
      }}
      summaryColumns={[
        {
          field: 'downtimeHours',
          label: 'Downtime Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'totalHours',
          label: 'Total Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          valueFormatter: statsFormatPercent,
          label: 'Downtime Rate',
        },
      ]}
      intervalColumns={intervalColumns}
      intervalGroupTitle="Downtime Hours / Total Hours"
      rateStrategy="desc"
    />
  );
};
