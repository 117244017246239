import { Box, styled } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { statsTrendedValueClasses } from './TrendedValue';

const PREFIX = 'TSMED-StatsIntervalCell';

const statsIntervalCellClasses = {
  root: `${PREFIX}-root`,
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  rateCell: `${PREFIX}-rateCell`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { palette, spacing } }) => ({
  color: palette.grey[200],
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  fontSize: '14px',
  alignItems: 'baseline',
  justifyContent: 'end',
  [`.${statsIntervalCellClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsTrendedValueClasses.secondaryValue}`]: {
    fontSize: '10px',
  },
}));

export const StatsIntervalCell = ({
  value: [cur, prev],
  ...props
}: Pick<GridRenderCellParams, 'value'>) => (
  <Root {...props}>
    {cur}{' '}
    <Box className={statsIntervalCellClasses.fieldSecondary}>/ {prev}</Box>
  </Root>
);
