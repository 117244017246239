import classNames from 'classnames';

import styles from './DataErrorsCountTile.module.scss';

interface Props {
  dataErrorsCount: number;
  backendNetworkError: string;
}

export const DataErrorsCountTile = ({
  dataErrorsCount,
  backendNetworkError,
}: Props) => (
  <div
    className={classNames(styles.dataErrorsCountTile, {
      [styles.disabled]: backendNetworkError,
    })}
  >
    <span className={styles.largeCaption}>{dataErrorsCount}</span>
    <span className={styles.smallCaption}>
      {`WARNING${dataErrorsCount !== 1 ? 'S' : ''}`}
    </span>
  </div>
);
