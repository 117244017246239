import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { ReactComponent as ErrorTriangleSVG } from '../../../assets/errorTriangle.svg';
import { camelCaseToWords } from '../../../helpers';
import { useAutoScroll } from '../../../hooks/useAutoScroll';
import { useElementRef } from '../../../hooks/useElementRef';
import { AppDataErrors } from '../../../types/new';

import styles from './DataErrorsList.module.scss';

interface Props {
  dataErrors: AppDataErrors;
  networkErrors: AppDataErrors;
  backendNetworkError: string;
}

export const DataErrorsList = ({
  dataErrors,
  networkErrors,
  backendNetworkError,
}: Props) => {
  const [hasScroll, setHasScroll] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const { ref, element } = useElementRef('errorsList');

  useEffect(() => {
    if (element) {
      setHasScroll(element.scrollHeight > element.clientHeight);
    }
  }, [element, dataErrors]);

  const handleScroll = () => {
    if (hasScroll && element) {
      setScrolled(element.scrollTop > 0);
    }
  };

  const { start, pause } = useAutoScroll({ element, hasScroll });

  const onMouseEnter = () => pause();
  const onMouseLeave = () => start();

  return (
    <div
      className={classnames(styles.DataErrorsListWrapper, {
        [styles.DataErrorsListWrapperFadeBefore]: isScrolled,
        [styles.DataErrorsListWrapperFadeAfter]: hasScroll,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        id="errorsList"
        ref={ref}
        className={styles.DataErrorsList}
        onScroll={handleScroll}
      >
        {Object.keys(dataErrors).map(objectTypeKey => {
          const sectionNetworkErrorsPresent = !!Object.keys(
            networkErrors[objectTypeKey] || {},
          ).length;
          const sectionDataErrorsPresent = !!Object.keys(
            dataErrors[objectTypeKey] || {},
          ).length;
          const showSection =
            sectionNetworkErrorsPresent || sectionDataErrorsPresent;

          return (
            showSection && (
              <div key={objectTypeKey}>
                <div
                  className={classnames(styles.caption, {
                    [styles.disabled]: backendNetworkError,
                    [styles.error]: sectionNetworkErrorsPresent,
                  })}
                >
                  {camelCaseToWords(objectTypeKey)}
                  {!backendNetworkError && sectionNetworkErrorsPresent && (
                    <span className={styles.errorText}>
                      <ErrorTriangleSVG /> NETWORK ERROR
                    </span>
                  )}
                </div>
                {Object.keys(dataErrors[objectTypeKey]).map(dataErrorKey => (
                  <div className={styles.text} key={dataErrorKey}>
                    {dataErrors[objectTypeKey][dataErrorKey].label}
                    &nbsp;|&nbsp;
                    {dataErrors[objectTypeKey][dataErrorKey].errorMessages.join(
                      ', ',
                    )}
                  </div>
                ))}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
