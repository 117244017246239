import { Box } from '@mui/material';
import classnames from 'classnames';
import { useState, MouseEvent } from 'react';

import { ICONS } from '../../../components';
import {
  OrganIconType,
  TeamIconType,
  THubsReportStatus,
  TProduct,
  TResourceDevice,
  TResourceHuman,
  TResourceType,
  TStaffDetails,
} from '../../../types/new';

import { CellDeviceDrawer } from './CellDeviceDrawer';
import { CellHumanDrawer } from './CellHumanDrawer';
import { CellPopper } from './CellPopper';
import styles from './TableRowItem.module.scss';

// Synced with BE structure
enum organIndexMap {
  liver,
  lung,
  heart,
}

// Synced with BE structure
enum teamIndexMap {
  ocs,
  surgeon,
  nurse,
}

const teamIconResourceMap: Record<TeamIconType, TResourceType> = {
  nurse: 'Specialist',
  ocs: 'Device',
  surgeon: 'Surgeon',
};
const organIconProductMap: Record<OrganIconType, TProduct> = {
  heart: 'Heart',
  liver: 'Liver',
  lung: 'Lung',
};

interface CellProps {
  day: 'today' | 'tomorrow';
  details: TStaffDetails;
  teamIconName: TeamIconType;
  organIconName: OrganIconType;
  location: THubsReportStatus['location'];
}

const Cell = ({
  details: { resources },
  day,
  organIconName,
  teamIconName,
  location,
}: CellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleMouseOver = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMouseOut = () => {
    setAnchorEl(null);
  };
  const handleClick = () => {
    setDialogOpen(!dialogOpen);
  };

  const available = resources.filter(
    r =>
      (teamIconName === 'ocs' ||
        (r as TResourceHuman)[day]?.scheduleType === 'On Call') &&
      !r.unosID,
  );
  const availableCount = available.length ?? 0;

  const assigned = (resources as TResourceHuman[]).filter(
    r =>
      (teamIconName === 'ocs' || r[day]?.scheduleType === 'On Call') &&
      r.unosID,
  );
  const assignedCount = assigned.length ?? 0;
  const returning = (resources as TResourceHuman[])
    .filter(r => r[day]?.scheduleSubType === 'Rest')
    ?.sort((a, b) =>
      (a[day]?.endUTCDateTime ?? '') < (b[day]?.endUTCDateTime ?? '') ? -1 : 1,
    );

  const returningCount = returning.length ?? 0;

  return (
    <>
      <Box
        className={styles.cell}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      >
        <div
          className={classnames(styles.amount, {
            [styles.unavailable]: !availableCount && assignedCount,
          })}
        >
          {availableCount || assignedCount || '-'}
        </div>
        <div className={styles.time}>
          {returning?.[0]?.[day]?.endTime ?? '-'}
        </div>
      </Box>
      <CellPopper
        anchorEl={anchorEl}
        assignedCount={assignedCount}
        availableCount={availableCount}
        returningCount={returningCount}
        day={day}
        icon={ICONS[teamIconName]}
        primaryText={`${organIconProductMap[organIconName]} ${teamIconResourceMap[teamIconName]}`}
        secondaryText={`${location.displayCity}, ${location.timeZone}`}
      />
      {teamIconName === 'ocs' ? (
        <CellDeviceDrawer
          open={dialogOpen}
          day={day}
          organIconName={organIconName}
          secondaryText={`${location.displayCity}, ${location.timeZone}`}
          resources={resources as TResourceDevice[]}
          onClose={handleClick}
        />
      ) : (
        <CellHumanDrawer
          open={dialogOpen}
          day={day}
          organIconName={organIconName}
          secondaryText={`${location.displayCity}, ${location.timeZone}`}
          available={available}
          assigned={assigned}
          returning={returning}
          onClose={handleClick}
          title={teamIconName === 'nurse' ? 'Specialists' : 'Surgeons'}
        />
      )}
    </>
  );
};

interface DayGroupProps {
  row: THubsReportStatus;
  day: 'today' | 'tomorrow';
  organ: OrganIconType;
}

const DayGroup = ({ day, row, organ }: DayGroupProps) => (
  <div className={classnames(styles['day-group'], styles.today)}>
    <Cell
      details={row.staffInfo[teamIndexMap.nurse].details[organIndexMap[organ]]}
      location={row.location}
      organIconName={organ}
      day={day}
      teamIconName={TeamIconType.nurse}
    />
    <Cell
      details={
        row.staffInfo[teamIndexMap.surgeon].details[organIndexMap[organ]]
      }
      day={day}
      location={row.location}
      organIconName={organ}
      teamIconName={TeamIconType.surgeon}
    />
    <Cell
      details={row.staffInfo[teamIndexMap.ocs].details[organIndexMap[organ]]}
      day={day}
      location={row.location}
      organIconName={organ}
      teamIconName={TeamIconType.ocs}
    />
  </div>
);

export interface TableRowItemProps {
  row: THubsReportStatus;
  isEven: boolean;
}

export const TableRowItem = ({ row, isEven }: TableRowItemProps) => {
  const { timeZone, displayCity } = row.location;
  return (
    <li
      className={classnames(styles.row, {
        [styles.even]: isEven,
        [styles.odd]: !isEven,
      })}
    >
      <div className={styles['hub-cell']}>
        <div className={styles.city}>{displayCity}</div>
        <div className={styles.abbreviation}>{timeZone}</div>
      </div>
      <div className={styles['day-groups']}>
        <DayGroup day="today" organ={OrganIconType.liver} row={row} />
        <DayGroup day="tomorrow" organ={OrganIconType.liver} row={row} />
        <DayGroup day="today" organ={OrganIconType.heart} row={row} />
        <DayGroup day="tomorrow" organ={OrganIconType.heart} row={row} />
        <DayGroup day="today" organ={OrganIconType.lung} row={row} />
        <DayGroup day="tomorrow" organ={OrganIconType.lung} row={row} />
      </div>
    </li>
  );
};
