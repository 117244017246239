import {
  Components,
  formControlLabelClasses,
  radioClasses,
  Theme,
} from '@mui/material';

import { themeBase } from '../theme-base';

const { spacing, palette } = themeBase;
export const muiMenu: Theme['components'] = {
  MuiMenu: {
    styleOverrides: {
      paper: {
        [`.${formControlLabelClasses.root}`]: {
          padding: spacing(1, 2),
          margin: 0,
          gap: spacing(2),
          [`.${radioClasses.root}`]: {
            padding: 0,
          },
        },
        '&:hover': {
          backgroundColor: palette.mapBlue[700],
        },
      },
      outlined: {
        borderColor: '#33383F',
      },
    },
  } as Components['MuiMenu'],
};
