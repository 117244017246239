import { EIGHT_HOURS, TWO_HOURS } from '../const/const';
import { AircraftEvent, EAircraftEventType } from '../types/new';

export const getEventTime = (eventTimeMs: number): number => {
  const eventTime = Math.floor(eventTimeMs / 1000 / 60);

  return Math.min(EIGHT_HOURS, Math.max(1, eventTime));
};

export const getCurrentEvent = (events: AircraftEvent[]): AircraftEvent => {
  const currentEvent = events[0] || { Type: EAircraftEventType.NotAvailable };
  const nextEvent = events[1] || { Type: EAircraftEventType.NotAvailable };
  const isCurrentOffDuty = currentEvent.Type === 'OffDuty';
  const isCurrentLessTwoHours =
    Math.floor(currentEvent.EventTimeMs / 1000 / 60) < TWO_HOURS;
  const isNextOnDuty = nextEvent.Type === 'OnDuty';

  if (isCurrentOffDuty && isCurrentLessTwoHours && isNextOnDuty) {
    return nextEvent;
  }

  return currentEvent;
};
