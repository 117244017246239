import { Components, iconButtonClasses, Theme } from '@mui/material';

import { themeBase } from '../theme-base';

const { spacing, palette } = themeBase;
export const muiDataGrid: Theme['components'] = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '--DataGrid-containerBackground': '#101C26',
        '--unstable_DataGrid-radius': spacing(1),
        '--DataGrid-rowBorderColor': 'black',
        color: '#DDD',
        fontSize: '12px',
      },
      columnHeaders: {
        // borderStartStartRadius: 10,
      },
      columnHeader: {
        [`&--pinnedLeft`]: {
          background: 'inherit',
        },
      },
      'container--top': {},
      headerCell: {
        color: '#ABB4BF',
      },
      cell: {
        color: palette.grey[200],
        [`&--pinnedLeft`]: {
          background: 'inherit',
        },
      },
      footerCell: {
        color: '#ABB4BF',
      },
      ['scrollbar--vertical']: {
        height: 0,
      },
      scrollbar: {
        display: 'none',
      },

      row: {
        ['&.even']: {
          background: '#0F151C',
        },
        ['&.odd']: {
          background: '#101C26',
        },
        ['&:hover']: {
          background: 'inherit',
        },
        background: '#191F26',
      },
      selectedRow: {
        background: '#46A000',
      },
      hoverRow: {
        background: 'inherit',
      },
      iconButtonContainer: {
        visibility: 'visible',
        width: 'auto',
        [`.${iconButtonClasses.root}`]: {
          background: 'transparent',
        },
      },
      // menuIcon: {
      //   visibility: 'visible',
      //   width: 'auto',
      // },
      menuIconButton: {
        padding: 0,

        background: 'transparent',
      },
    },
    defaultProps: {
      rowHeight: 40,
      columnHeaderHeight: 26,
    },
  } as Components['MuiDataGrid'],
};
