import { Color, createTheme, Theme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { defaultTheme } from '../default-theme';
import { getPalette } from '../helpers';

const grey: Color = {
  50: '#EFECEA',
  100: '#D1D9E4',
  200: '#DDDDDD',
  300: '#939CA6',
  400: '#B6B6B6',
  500: '#A3A7AB',
  600: '#87888B',
  700: '#7F8083',
  800: '#62666C',
  900: '#393C42',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const blue: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#87C8FF',
  600: '#6CA5D6',
  700: '#6799C3',
  800: '#527A9C',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const mapBlue: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#7591AD',
  400: '#657C93',
  500: '#5B728A',
  600: '#364B5F',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const green: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#AFFB74',
  600: '#9BDD67',
  700: '#83BC62',
  800: '#587E3A',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const orange: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#FF975D',
  600: '#E87636',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const violet: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#CC9AFD',
  600: '#A27CC9',
  700: '#9778C2',
  800: '#8263A1',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const mint: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#8BD8AE',
  600: '#84A988',
  700: '#6AA48A',
  800: '#8263A1',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const themeBase: Theme = createTheme(
  deepmerge(defaultTheme, {
    palette: {
      ...getPalette({
        mode: 'dark',
        background: '#000',
        primary: grey[200],
        grey,
        blue,
        mapBlue,
        green,
        violet,
        orange,
        mint,
      }),
      error: {
        dark: '#FF505B',
      },
      success: {
        dark: '#00E733',
      },
    },
    typography: {
      blue: {
        color: blue[200],
      },
      green: {
        color: green[200],
      },
      purple: {
        color: violet[200],
      },
      h1: {
        fontFamily: "'JetBrains Mono', monospace",
        fontSize: '32px',
        fontWeight: 500,
      },
      h2: {
        fontFamily: "'JetBrains Mono', monospace",
        fontSize: '24px',
        fontWeight: 500,
      },
    },
  } as ThemeOptions),
);
