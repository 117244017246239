import { ALERTS_LIST_MAX_HEIGHT } from '../const/const';
import { usaStatesWestern } from '../const/usa';
import { CaseType, ExcludedCasesType, NewCaseType } from '../types/new';

export const filterCasesByStatus = (
  cases: CaseType[],
  statuses: string[],
): ExcludedCasesType => {
  const mainCases = [];
  const excluded = [];

  for (const item of cases) {
    if (statuses.includes(item.oscSubStatus)) {
      excluded.push(item);
    } else {
      mainCases.push(item);
    }
  }

  return { cases: mainCases, excluded };
};

const showTimerLine = (newCase: NewCaseType): boolean =>
  !!newCase.transplantCenterCode || !!newCase.caseTimerEndString;

export const countNewCases = (newCases: NewCaseType[]): number =>
  newCases.reduce((acc, item) => acc + item.sameUnosNewCases.length + 1, 0);

export const splitArrayToEqualChunks = <T>(
  array: T[],
  maxChunksSize = 1,
  maxChunks = 1,
): T[][] => {
  const maxLength = maxChunks * maxChunksSize;
  const count = array.length > maxLength ? maxLength : array.length;
  const chunks: T[][] = Array(maxChunks).fill([]);

  let chunkIndex = 0;

  for (let i = 0; i < count; i++) {
    const isLastChunk = chunkIndex === maxChunks - 1;
    const item = array[i];
    chunks[chunkIndex] = [...chunks[chunkIndex], item];

    chunkIndex = isLastChunk ? 0 : chunkIndex + 1;
  }

  return chunks;
};

const sortByCoordinates = (a: NewCaseType, b: NewCaseType) => {
  const donorACoords = a.donorHospital.lonLat;
  const donorBCoords = b.donorHospital.lonLat;

  if (donorACoords?.[1] && donorBCoords && donorBCoords[1]) {
    return donorACoords[1] < donorBCoords[1] ? 1 : -1;
  }
  return 0;
};

const calculateNewCaseHeight = (newCase: NewCaseType): number => {
  const baseCardHeight = 120.5 + 2; // card + top-bottom borders
  const donorInfoLineHeight = 21.5; // line only. margin added only if timer will be present.
  const timerLineHeight = 22 + 13; // line + top-margin
  const hrHeight = 13 + 13 + 2; // margin-top + margin-bottom + border
  const gap = 12; // gap between alerts

  let fullHeight = baseCardHeight;
  if (showTimerLine(newCase)) {
    fullHeight += timerLineHeight;
  }

  for (const sameUnosNewCase of newCase.sameUnosNewCases) {
    fullHeight += hrHeight;
    fullHeight += donorInfoLineHeight;
    if (showTimerLine(sameUnosNewCase)) {
      fullHeight += timerLineHeight;
    }
  }

  return Math.round(fullHeight + gap);
};
const splitByScreenHeight = (
  newCaseArr: NewCaseType[],
): {
  fit: NewCaseType[];
  extra: NewCaseType[];
} => {
  const fit: NewCaseType[] = [];
  const extra: NewCaseType[] = [];
  let occupiedPageHeight = 0;

  // loop through the list
  for (const curNewCase of newCaseArr) {
    // if no extra element yet
    if (!extra.length) {
      // calc height
      const curNewCaseHeight = calculateNewCaseHeight(curNewCase);
      // if no overflow yet
      if (occupiedPageHeight + curNewCaseHeight < ALERTS_LIST_MAX_HEIGHT) {
        // push to fit
        fit.push(curNewCase);
        // store new occupied height
        occupiedPageHeight += curNewCaseHeight;
        // we have overflow now
      } else {
        // push to extra
        extra.push(curNewCase);
      }
      // if at least one extra element is present, we already overflowed, so lets push the rest to extra
    } else {
      extra.push(curNewCase);
    }
  }

  return { fit, extra };
};

export const splitNewCasesIntoColumns = (
  newCases: NewCaseType[],
): Record<string, NewCaseType[]> => {
  let west: NewCaseType[] = [];
  let east: NewCaseType[] = [];
  let extra: NewCaseType[] = [];

  // sort by north
  const newCasesSorted = newCases.sort(sortByCoordinates);

  // split to west-east
  for (const curNewCase of newCasesSorted) {
    if (usaStatesWestern.includes(curNewCase.donorHospital.state)) {
      west.push(curNewCase);
      // do not rely on usaStatesEastern! since 'state' in data might be missing, and the card wont be shown anywhere
    } else {
      east.push(curNewCase);
    }
  }

  // split elements by height, fit or not-fit
  const splittedByScreenHeightWest = splitByScreenHeight(west);
  west = splittedByScreenHeightWest.fit;
  extra = [...extra, ...splittedByScreenHeightWest.extra];

  const splittedByScreenHeightEast = splitByScreenHeight(east);
  east = splittedByScreenHeightEast.fit;
  extra = [...extra, ...splittedByScreenHeightEast.extra];

  return { west, east, extra };
};
