import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

export const LogoutPage = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const init = async () => {
      await instance.initialize();
      // TODO: remove or make it console.error
      // eslint-disable-next-line no-console
      instance.logoutRedirect().catch(error => console.log(error));
    };

    if (isAuthenticated && inProgress === InteractionStatus.None) {
      init();
    }
  }, [isAuthenticated, inProgress, instance]);

  return null;
};
