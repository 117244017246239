import { ThemeProvider } from '@mui/material';
import { useState, PropsWithChildren, useMemo } from 'react';
import { DefaultTheme } from 'styled-components';

import { ThemeContext, ThemeNameType } from '../../contexts/ThemeContext';
import { lightTheme, darkTheme } from '../../theme';

const themes: Record<ThemeNameType, DefaultTheme> = {
  dark: darkTheme,
  light: lightTheme,
};

export const ThemeConnector = ({ children }: PropsWithChildren) => {
  const [selectedTheme, setSelectedTheme] = useState<ThemeNameType>('dark');
  const ctx = useMemo(
    () => ({
      selectedTheme,
      setSelectedTheme,
    }),
    [selectedTheme],
  );
  return (
    <ThemeContext.Provider value={ctx}>
      <ThemeProvider theme={themes[selectedTheme]}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
