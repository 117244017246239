import { NewCaseType } from '../../types/new';

import styles from './NewCaseAlert.module.scss';

interface Props {
  newCase: NewCaseType;
  showTopBorder?: boolean;
}

export const NewCaseSubItem = ({ newCase, showTopBorder }: Props) => {
  const {
    donorType,
    caseTimerEndString,
    transplantCenterCode,
    donorORTimeString,
    label,
  } = newCase;
  const showTimerLine = !!transplantCenterCode || !!caseTimerEndString;

  return (
    <div>
      {showTopBorder ? <hr /> : null}
      <div className={styles.donorInfo}>
        <span className={styles.donorTime}>{donorType}</span>
        <span className={styles.donorTime}>
          {caseTimerEndString ? donorORTimeString : 'Pending'}
        </span>
        <span className={styles.label}>{label}</span>
      </div>
      {showTimerLine && (
        <div className={styles.donorInfo}>
          {!!caseTimerEndString && (
            <div className={styles.donorTime}>{caseTimerEndString}</div>
          )}
          <span className={styles.txCode}>{transplantCenterCode}</span>
        </div>
      )}
    </div>
  );
};
