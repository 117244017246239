import { Theme } from '@mui/material';

import { muiCharts } from './MuiCharts';
import { muiDataGrid } from './MuiDataGrid';
import { muiDateRangePicker } from './MuiDateRangePicker';
import { muiFormControl } from './MuiFormControl';
import { muiMenu } from './MuiMenu';

export const components: Theme['components'] = {
  ...muiMenu,
  ...muiCharts,
  ...muiFormControl,
  ...muiDataGrid,
  ...muiDateRangePicker,
};
