import { interpolateRgbBasis } from 'd3-interpolate';

export const ratedColor = (value: number) => {
  const rgb = interpolateRgbBasis(['#0CA92F', '#C09700', '#FF505B'])(value);
  // const rgb = interpolateRgbBasis(['#00E733', '#C09700', '#FF3844'])(value);
  return `#${rgb
    .replace(/^rgb?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
    .split(',') // splits them at ","
    .map(c => (+c).toString(16)) // Converts them to numbers
    .map(string => (string.length === 1 ? `0${string}` : string))
    .join('')}`;
};
export const statsFormatRate = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
}).format;
export const statsFormatCount = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
}).format;
export const statsFormatPercent = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  style: 'percent',
}).format;
export const statsFormatHours = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  style: 'unit',
  unit: 'hour',
  unitDisplay: 'narrow',
}).format;
