export const rafWorker = () => {
  const timers = new Map<string, number>();

  // eslint-disable-next-line no-restricted-globals
  self.addEventListener('message', ({ data }) => {
    if (data.type === 'raf') {
      let id = requestAnimationFrame((t: DOMHighResTimeStamp) => {
        const zero = t;

        const animate = (newT: DOMHighResTimeStamp) => {
          const elapsed = newT - zero;

          postMessage({
            id: data.id,
            timer: elapsed,
          });

          id = requestAnimationFrame(animate);
          timers.set(data.id, id);
        };

        animate(t);
      });

      timers.set(data.id, id);
    } else if (data.type === 'cancel') {
      const timerId = timers.get(data.id);

      if (timerId) {
        cancelAnimationFrame(timerId);
        timers.delete(data.id);
      }
    }
  });
};
