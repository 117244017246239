import { useSvgClientRect } from '../../../hooks/useSvgClientRect';
import { CaseClusterType, ClusterItem, NewCaseType } from '../../../types/new';

import { ClusterComponent } from './ClusterComponent';
import { NewCaseOrgan } from './NewCaseOrgan';

interface Props {
  clusters: ClusterItem<CaseClusterType>[];
  newCases: NewCaseType[];
}

export const IconsLayout = ({ clusters, newCases }: Props) => {
  const { width: clientWidth, height: clientHeight } =
    useSvgClientRect('mainOverlaySVG');

  return (
    <>
      {clusters.map(item => (
        <ClusterComponent
          key={item.key}
          items={item.items}
          clientWidth={clientWidth}
          clientHeight={clientHeight}
          isCluster={item.isCluster}
        />
      ))}
      {newCases.map(caseItem => (
        <div key={caseItem.id}>
          <NewCaseOrgan
            newCase={caseItem}
            clientWidth={clientWidth}
            clientHeight={clientHeight}
            zIndex={caseItem.sameUnosNewCases.length + 1}
          />
          {caseItem.sameUnosNewCases.map((caseSubItem, index) => (
            <NewCaseOrgan
              key={caseSubItem.id}
              newCase={caseSubItem}
              clientWidth={clientWidth}
              clientHeight={clientHeight}
              zIndex={caseItem.sameUnosNewCases.length - index}
              organIndex={index + 1}
              isSameUnos
            />
          ))}
        </div>
      ))}
    </>
  );
};
