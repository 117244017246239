import {
  Box,
  styled,
  Menu,
  FormControlLabel,
  Radio,
  RadioGroup,
  PopoverProps,
} from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';

import { useStats } from './useStats';

const PREFIX = 'TSMED-maintStats-shortcutMenu';

const shortcutMenuClasses = {
  shortcutRadioIcon: `${PREFIX}-shortcutRadioIcon`,
  shortcutRadioIconChecked: `${PREFIX}-shortcutRadioIconChecked`,
};

const Root = styled(Menu, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  [`.${shortcutMenuClasses.shortcutRadioIcon}`]: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: '#364B5F',
    border: '1px solid #7591AD',
    boxSizing: 'border-box',
    transition: 'all 200ms ease-in-out',
    [`&.${shortcutMenuClasses.shortcutRadioIconChecked}`]: {
      borderColor: '#DDD',
      borderWidth: 5,
    },
  },
}));
export interface IRadioMenuItem {
  id: string;
  label: string;
}
export interface IShortcutMenuProps {
  anchorEl?: PopoverProps['anchorEl'];
  onClose: PopoverProps['onClose'];
  // onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}
export const ShortcutMenu = (props: IShortcutMenuProps) => {
  const open = Boolean(props.anchorEl);
  const { dispatch, shortcuts, activeShortcut } = useStats();
  const items = useMemo(
    () =>
      shortcuts.map(i => ({
        id: i.id ?? i.label,
        label: i.label,
      })),
    [shortcuts],
  );

  return (
    <Root
      anchorEl={props.anchorEl}
      open={open}
      onClose={(...args) => {
        if (props.onClose) {
          props.onClose(...args);
        }
      }}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={''}
        name="radio-buttons-group"
        onChange={e => {
          dispatch({
            type: 'setActiveShortcut',
            payload: shortcuts.find(s => s.id === e.target.value),
          });
          if (props.onClose) {
            props.onClose(e, 'backdropClick');
          }
        }}
      >
        {items.map(item => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            checked={item.id === activeShortcut?.id}
            control={
              <Radio
                icon={<Box className={shortcutMenuClasses.shortcutRadioIcon} />}
                checkedIcon={
                  <Box
                    className={classNames(
                      shortcutMenuClasses.shortcutRadioIcon,
                      shortcutMenuClasses.shortcutRadioIconChecked,
                    )}
                  />
                }
              />
            }
            label={item.label}
          />
        ))}
      </RadioGroup>
    </Root>
  );
};
