import { BBox } from '@turf/helpers';

export const inBBox = (pt: number[], bbox: BBox): boolean =>
  bbox[0] <= pt[0] && bbox[1] <= pt[1] && bbox[2] >= pt[0] && bbox[3] >= pt[1];

export const isBboxIntersected = (leftBbox: BBox, rightBbox: BBox): boolean => {
  const A = leftBbox;
  const B = rightBbox;

  return !(A[0] > B[2] || A[2] < B[0] || A[1] > B[3] || A[3] < B[1]);
};
