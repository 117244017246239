import { useMemo } from 'react';

import { useQueryStatsDowntime } from '../../../../adapters';
import { statsFormatHours, statsFormatPercent } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowDowntime = MobileTableRow<{
  downtimeHours: number;
  totalHoursHours: number;
}>;
export const StatsDowntimeTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsDowntime);

  const rows: MobileTableRowDowntime[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowDowntime[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const rows: MobileTableRowDowntime[] = Object.values(
      statsData.perAircraft,
    ).map(
      ({
        tailNumber,
        downtimeValue,
        totalHoursValue,
        downtimeRate,
        downtimeRatePrev,
        intervals,
      }) => {
        const intervalValues = intervalFields.map(date => ({
          date,
          downtimeHours: intervals[date]?.downtimeValue ?? 0,
          totalHoursHours: intervals[date]?.totalHoursValue ?? 0,
        }));

        return {
          tailNumber,
          downtimeHours: downtimeValue,
          totalHoursHours: totalHoursValue,
          rate: [downtimeRate, downtimeRatePrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return rows;
  }, [statsData]);
  return (
    <StatsTableMobile
      rows={rows}
      intervalField={{
        fieldName: 'downtimeHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        fieldName: 'totalHoursHours',
        valueFormatter: statsFormatHours,
      }}
      rateField={{ valueFormatter: statsFormatPercent }}
      summaryRows={[
        {
          field: 'downtimeHours',
          label: 'Total Operational Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'totalHoursHours',
          label: 'Total AOG Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          valueFormatter: statsFormatPercent,
          label: 'Total Downtime',
        },
      ]}
      intervalColumns={['', 'Op. h / AOG h']}
      rateStrategy="desc"
    />
  );
};
