import {
  North as ArrowUpIcon,
  South as ArrowDownIcon,
} from '@mui/icons-material';
import { Box, BoxProps, styled } from '@mui/material';
import classNames from 'classnames';

const PREFIX = 'TSMED-StatsTrendedValue';

export const statsTrendedValueClasses = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  iconDot: `${PREFIX}-iconDot`,
  negative: `${PREFIX}-negative`,
  positive: `${PREFIX}-positive`,
  primaryValue: `${PREFIX}-primaryValue`,
  secondaryValue: `${PREFIX}-secondaryValue`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints, palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: spacing(1),
  fontSize: '24px',
  fontWeight: 500,
  [breakpoints.up('sm')]: {
    flexGrow: 1,
  },
  [`.${statsTrendedValueClasses.icon}`]: {
    width: 24,
    height: 24,
    [`&.${statsTrendedValueClasses.iconDot}`]: {
      width: 6,
      height: 6,
      backgroundColor: palette.grey[300],
      borderRadius: '50%',
      margin: 6,
    },
  },
  [`&.${statsTrendedValueClasses.negative}`]: {
    [`.${statsTrendedValueClasses.icon}`]: {
      color: '#FF3844',
    },
  },
  [`&.${statsTrendedValueClasses.positive}`]: {
    [`.${statsTrendedValueClasses.icon}`]: {
      color: '#00E733',
    },
  },
  [`.${statsTrendedValueClasses.primaryValue}`]: {
    whiteSpace: 'nowrap',
  },
  [`.${statsTrendedValueClasses.secondaryValue}`]: {
    fontSize: '16px',
    color: '#87888B',
    whiteSpace: 'nowrap',
  },
}));

export interface TrendedValueItemProps extends BoxProps {
  primaryValue: number | string;
  secondaryValue?: number | string;

  icon?: 'up' | 'down' | 'balance';
  kind?: 'negative' | 'positive';
  color?: string;
}

export const TrendedValue = ({
  primaryValue,
  icon,
  kind,
  secondaryValue,
  color,
  ...props
}: TrendedValueItemProps) => (
  <Root
    className={classNames(
      statsTrendedValueClasses.root,
      kind ? statsTrendedValueClasses[kind] : '',
    )}
    {...props}
  >
    <Box
      className={statsTrendedValueClasses.primaryValue}
      sx={{
        color: color ?? 'inherit',
      }}
    >
      {primaryValue}
    </Box>
    {secondaryValue !== undefined && (
      <Box className={statsTrendedValueClasses.secondaryValue}>
        {secondaryValue}
      </Box>
    )}
    {icon === 'up' && (
      <ArrowUpIcon className={classNames(statsTrendedValueClasses.icon)} />
    )}
    {icon === 'down' && (
      <ArrowDownIcon className={classNames(statsTrendedValueClasses.icon)} />
    )}
    {icon === 'balance' && (
      <Box
        className={classNames(
          statsTrendedValueClasses.icon,
          statsTrendedValueClasses.iconDot,
        )}
      />
    )}
  </Root>
);
