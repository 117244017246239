import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsAOGDR } from '../../../../adapters';
import { statsFormatCount, statsFormatPercent } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsAOGDRChart } from './StatsAOGDRChart';
import { StatsAOGDRTable } from './StatsAOGDRTable';
import { StatsAOGDRTableMobile } from './StatsAOGDRTableMobile';

const PREFIX = 'TSMED-StatsAOGDRTab';

const statsAOGDRClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsAOGDRClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsAOGDRClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsAOGDRClasses.fieldNegative}`]: {},
  [`.${statsAOGDRClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsAOGDR = () => {
  const { data: statsData } = useQueryData(useQueryStatsAOGDR);

  const {
    totalScheduledCount,
    totalScheduledPrevCount,
    totalCancelledCount,
    totalCancelledPrevCount,
    aogdr,
    aogdrPrev,
  } = useMemo(() => {
    if (!statsData) {
      return {
        totalScheduledCount: 0,
        totalScheduledPrevCount: 0,
        totalCancelledCount: 0,
        totalCancelledPrevCount: 0,
        aogdr: 0,
        aogdrPrev: 0,
      };
    }

    return {
      totalScheduledCount: statsData.summary.scheduledCount,
      totalScheduledPrevCount: statsData.summary.scheduledPrevCount,
      totalCancelledCount: statsData.summary.cancelledCount,
      totalCancelledPrevCount: statsData.summary.cancelledPrevCount,
      aogdr: statsData.summary.aogdr,
      aogdrPrev: statsData.summary.aogdrPrev,
    };
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const summaryItems: ISummaryItem[] = [
    {
      id: 'total-scheduled-flights',
      label: 'Total Scheduled Flights',
      primaryValue: statsFormatCount(totalScheduledCount),
      secondaryValue: ` / ${statsFormatCount(totalScheduledPrevCount)}`,
    },
    {
      id: 'total-delayed-flights',
      label: 'Total Cancelled Flights',
      primaryValue: statsFormatCount(totalCancelledCount),
      secondaryValue: ` / ${statsFormatCount(totalCancelledPrevCount)}`,
    },
    {
      id: 'aogdr',
      label: 'AOG Dispatch Reliability',
      primaryValue: statsFormatPercent(aogdr),
      secondaryValue: ` / ${statsFormatPercent(aogdrPrev)}`,
      icon:
        (aogdr === aogdrPrev && 'balance') ||
        (aogdr < aogdrPrev && 'down') ||
        'up',
      kind: aogdr > aogdrPrev ? 'positive' : 'negative',
    },
  ];

  return (
    <Root>
      <Summary items={summaryItems} />
      <StatsAOGDRChart />
      <Box className={statsAOGDRClasses.contentBlock}>
        <Typography variant="h2">
          AOG Dispatch Reliability by Aircraft
        </Typography>
        {isMobile ? <StatsAOGDRTableMobile /> : <StatsAOGDRTable />}
      </Box>
    </Root>
  );
};
