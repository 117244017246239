import { useEffect, useState } from 'react';
import { clearInterval, setInterval } from 'worker-timers';

import { timezonesConfig } from '../../const/usa';
import { currentTimeInTimezone } from '../../helpers/timezoneHelper';

import styles from './Timezones.module.scss';

export const Timezones = () => {
  const [curDate, setCurDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      // set new time only when second matches 0
      if (new Date().getSeconds() === 0) {
        setCurDate(new Date());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.timezones}>
      {timezonesConfig.map(curTimezoneConfig => (
        <div className={styles.timezone} key={curTimezoneConfig.caption}>
          <div className={styles.name}>{curTimezoneConfig.caption}</div>
          <div>
            {currentTimeInTimezone(curDate, curTimezoneConfig.jsTimezone)}
          </div>
        </div>
      ))}
    </div>
  );
};
