import { Color, Theme, createTheme } from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import type {} from '@mui/x-charts-pro/themeAugmentation';
// import { ColorPartial } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    red: React.CSSProperties;
    grey: React.CSSProperties;
    blue: React.CSSProperties;
    green: React.CSSProperties;
    violet: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    red?: React.CSSProperties;
    grey?: React.CSSProperties;
    blue?: React.CSSProperties;
    green?: React.CSSProperties;
    violet?: React.CSSProperties;
  }

  interface Palette {
    blue: Color;
    mapBlue: Color;
    green: Color;
    violet: Color;
    orange: Color;
    mint: Color;
  }

  interface PaletteOptions {
    blue: Color;
    mapBlue: Color;
    green: Color;
    violet: Color;
    orange: Color;
    mint: Color;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    red: true;
    grey: true;
    blue: true;
    green: true;
    violet: true;
  }
}

export const defaultTheme: Theme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          red: 'strong',
          grey: 'strong',
          blue: 'strong',
          green: 'strong',
          violet: 'strong',
        },
      },
    },
    MuiChartsSurface: {
      styleOverrides: {
        root: {
          touchAction: 'auto',
        },
      },
    },
  },
  typography: {
    // fontFamily: `'Avenir Next', Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    // 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    fontFamily: '"Inter", sans-serif',
    red: {
      color: '#FF3844',
    },
    grey: {
      color: '#D1D9E4',
    },
  },
});
