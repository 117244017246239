import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

interface DomElementsProviderProps {
  children?: ReactNode;
}

type DomElementsContextType = {
  elements: Map<string, HTMLDivElement | SVGElement | SVGPathElement>;
  addElement: (
    id: string,
    element: HTMLDivElement | SVGElement | SVGPathElement | null,
  ) => void;
  getElement: <T>(id: string) => T | null;
  removeElement: (id: string) => void;
};

const initialContextData: DomElementsContextType = {
  elements: new Map(),
  addElement: () => {},
  getElement: () => null,
  removeElement: () => {},
};

export const DomElementsContext =
  createContext<DomElementsContextType>(initialContextData);

export const DomElementsProvider = ({ children }: DomElementsProviderProps) => {
  const [elements, setElements] = useState<
    Map<string, HTMLDivElement | SVGElement | SVGPathElement>
  >(new Map());

  const addElement = useCallback(
    (
      id: string,
      element: HTMLDivElement | SVGElement | SVGPathElement | null,
    ) => {
      if (element) {
        setElements(prevState => {
          const updatedMap = prevState.set(id, element);

          return new Map(updatedMap);
        });
      }
    },
    [],
  );

  const removeElement = useCallback((id: string) => {
    setElements(prevState => {
      prevState.delete(id);

      return new Map(prevState);
    });
  }, []);

  const getElement = useCallback(
    // eslint-disable-next-line prettier/prettier
    <T, >(id: string): T | null => (elements.get(id) as T) || null,
    [elements],
  );

  const providedValue = useMemo(
    () => ({
      elements,
      addElement,
      getElement,
      removeElement,
    }),
    [elements, addElement, getElement, removeElement],
  );

  return (
    <DomElementsContext.Provider value={providedValue}>
      {children}
    </DomElementsContext.Provider>
  );
};
