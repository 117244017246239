import classnames from 'classnames';

import { ReactComponent as PlaneSVG } from '../../../assets/plane.svg';
import { EIGHT_HOURS, SIX_HOURS } from '../../../const/const';
import { useElementRef } from '../../../hooks/useElementRef';
import { AircraftOnAirportType } from '../../../types/new';
import styles from '../overlays.module.scss';

interface IconsProps extends AircraftOnAirportType {
  isSetElementRef?: boolean;
}

const getDutyStyles = (timeOnDuty: number): string | undefined => {
  if (timeOnDuty >= EIGHT_HOURS) {
    return styles.dutyWhite;
  }

  if (timeOnDuty >= SIX_HOURS && timeOnDuty < EIGHT_HOURS) {
    return styles.dutyMint;
  }

  if (timeOnDuty > 0 && timeOnDuty < SIX_HOURS) {
    return styles.dutyBlue;
  }
};

export const AircraftOnGroundIcons = ({
  isSetElementRef = false,
  ...item
}: IconsProps) => {
  const { ref } = useElementRef<HTMLDivElement>(`${item.Id}-aircraft-icons`);

  return (
    <div ref={isSetElementRef ? ref : null} className={styles.aircraftOnMap}>
      <div key={item.Id} className={classnames(styles.aircraftInfo)}>
        <span
          className={classnames(
            styles.registration,
            getDutyStyles(item.TimeOnDuty),
          )}
        >
          {item.Registration}
        </span>
        <div className={styles.aircraftAirportInfo}>
          {Boolean(item.Airport?.IATA ?? item.Airport?.ICAO) && (
            <span
              className={classnames(
                styles.registration,
                getDutyStyles(item.TimeOnDuty),
              )}
              style={{ opacity: 0.7 }}
            >
              {item.Airport?.IATA ?? item.Airport?.ICAO}
            </span>
          )}
          <div
            className={classnames(
              styles.aircraftMapIconOnGround,
              getDutyStyles(item.TimeOnDuty),
            )}
          >
            <PlaneSVG />
          </div>
          <div
            className={classnames(
              styles.aircraftTime,
              getDutyStyles(item.TimeOnDuty),
            )}
          >
            {item.TimeOnDuty >= EIGHT_HOURS && <>8+</>}
            {item.TimeOnDuty >= SIX_HOURS && item.TimeOnDuty < EIGHT_HOURS && (
              <>6-8</>
            )}
            {item.TimeOnDuty > 0 && item.TimeOnDuty < SIX_HOURS && <>{`<6`}</>}
            {!item.TimeOnDuty && <>X</>}
          </div>
        </div>
      </div>
    </div>
  );
};
