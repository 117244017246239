import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useQueryStatsAOGDR } from '../../../../adapters';
import {
  statsFormatCount,
  statsFormatPercent,
  statsFormatRate,
} from '../../stat-constants';
import { StatsIntervalCell } from '../StatsIntervalCell';
import { StatsTable, type StatsTableRow } from '../StatsTable';
import { useQueryData } from '../useQueryData';

type TableRowAOGDR = StatsTableRow<{
  scheduledCount: number;
  cancelledCount: number;
}>;

export const StatsAOGDRTable = () => {
  const { data: statsData } = useQueryData(useQueryStatsAOGDR);

  const {
    intervalColumns,
    rows,
  }: {
    intervalColumns: GridColDef[];
    rows: TableRowAOGDR[];
  } = useMemo(() => {
    const defaultResult = {
      intervalColumns: [],
      rows: [],
    };
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate);
    const intervalColumns: GridColDef[] = intervalFields.map(date => ({
      field: date,
      headerName: dayjs(date).format('DD.MM.YYYY'),
      minWidth: 120,
      type: 'custom',
      align: 'right',
      renderCell: ({ value }: GridRenderCellParams) => (
        <StatsIntervalCell value={value} />
      ),
    }));

    const rows: TableRowAOGDR[] = Object.values(statsData.perAircraft).map(
      ({
        tailNumber,
        intervals,
        aogdr,
        aogdrPrev,
        cancelledCount,
        scheduledCount,
      }) => {
        const intervalValues = keyBy(
          intervalFields.map(date => {
            const scheduledCount = intervals[date]?.scheduledCount ?? 0;
            const cancelledCount = intervals[date]?.cancelledCount ?? 0;
            return {
              date,
              scheduledCount,
              cancelledCount,
            };
          }),
          'date',
        );

        return {
          id: tailNumber,
          tailNumber,
          scheduledCount,
          cancelledCount,
          rate: [aogdr, aogdrPrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return { rows, intervalColumns };
  }, [statsData]);

  return (
    <StatsTable
      rows={rows}
      rateField={{ valueFormatter: statsFormatRate }}
      intervalField={{
        field: 'scheduledCount',
        valueFormatter: statsFormatCount,
      }}
      intervalSecondaryField={{
        field: 'cancelledCount',
        valueFormatter: statsFormatCount,
      }}
      summaryColumns={[
        {
          field: 'scheduledCount',
          label: 'Scheduled Flights',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'cancelledCount',
          label: 'Cancelled Flights',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'rate',
          label: 'Reliability',
          valueFormatter: statsFormatPercent,
        },
      ]}
      intervalColumns={intervalColumns}
      intervalGroupTitle="Scheduled Flights / Cancelled Flights"
      rateStrategy="asc"
    />
  );
};
