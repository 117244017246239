import { Box, styled } from '@mui/material';

import { Layout, TechnicalTimers, UsaMap } from '../../components';

import { useMetars } from './components';
import { usePireps } from './components/usePireps';
import { useSigmets } from './components/useSigmets';
import { WeatherLegend } from './components/WeatherLegend/WeatherLegend';

const PREFIX = 'TSMED-weather';

const weatherClasses = {
  overlays: `${PREFIX}-overlays`,
  aircraftReportIcon: `${PREFIX}-aircraftReportIcon`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  position: 'relative',
  width: 1920,
  height: 1080,
  [`.${weatherClasses.overlays}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '106%',
    height: '100%',
    svg: {
      height: '100%',
    },
    [`.${weatherClasses.aircraftReportIcon}`]: {
      position: 'absolute',
      height: 10,
      width: 10,
    },
  },
}));

export const WeatherPageContent = () => {
  const sigmetElements = useSigmets();
  const pirepElements = usePireps();
  const metarElements = useMetars();

  return (
    <Root id="map">
      <UsaMap />
      <Box className={weatherClasses.overlays}>
        <svg viewBox="0 0 1304 692" xmlns="http://www.w3.org/2000/svg">
          {sigmetElements}
          {metarElements}
          {pirepElements}
        </svg>
      </Box>
      <WeatherLegend />
    </Root>
  );
};

export const WeatherPage = () => (
  <Layout>
    <WeatherPageContent />
    <TechnicalTimers />
  </Layout>
);
