import {
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  AddBox as AddBoxIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {
  Box,
  styled,
  FormControl,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Chip,
  menuItemClasses,
  formControlClasses,
} from '@mui/material';
import { CSSProperties } from 'react';

import { IHistAircraft } from './StatsContext';
import { useStats } from './useStats';

const PREFIX = 'TSMED-maintStats-aircraftFilter';

const aircraftFilterClasses = {
  render: `${PREFIX}-render`,
  checkboxBox: `${PREFIX}-checkboxBox`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing } }) => ({
  [`.${aircraftFilterClasses.render}`]: {
    display: 'flex',
    gap: spacing(1.5),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`.${menuItemClasses.root}`]: {
    paddingLeft: spacing(2),
  },
  [`.${formControlClasses.root}`]: {
    display: 'flex',
  },
}));

export const AircraftFilter = () => {
  const { aircrafts, selectedAircrafts, dispatch } = useStats();
  const handleChange = (event: SelectChangeEvent<typeof selectedAircrafts>) => {
    const {
      target: { value },
    } = event;

    const selected = value as number[];
    let payload: IHistAircraft['Id'][];
    if (selected.includes(-1)) {
      if (selected.length > 1) {
        payload = [];
      } else {
        payload = aircrafts.map(({ Id }) => Id);
      }
    } else {
      payload = selected;
    }
    dispatch({
      type: 'setSelectedAircrafts',
      payload,
    });
  };
  return (
    <Root>
      <FormControl>
        <Select
          multiple
          value={selectedAircrafts}
          label={null}
          displayEmpty
          MenuProps={{
            slotProps: {
              paper: {
                style: {
                  marginTop: 3,
                  maxHeight: 450,
                  width: 210,
                } as CSSProperties,
              },
            },
          }}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          renderValue={selected => (
            <Box className={aircraftFilterClasses.render}>
              Aircrafts
              <Chip
                label={
                  (selected.length === aircrafts.length && 'All') ||
                  (!selected.length && 'All') ||
                  selected.length
                }
                variant="outlined"
              />
            </Box>
          )}
        >
          <MenuItem key={'All'} value={-1}>
            <Checkbox
              checked={true}
              icon={
                (selectedAircrafts.length && <IndeterminateCheckBoxIcon />) || (
                  <AddBoxIcon />
                )
              }
              checkedIcon={
                (selectedAircrafts.length && <IndeterminateCheckBoxIcon />) || (
                  <AddBoxIcon />
                )
              }
            />
            <ListItemText
              primary={
                (selectedAircrafts.length && 'Select None') || 'Select All'
              }
            />
          </MenuItem>
          {aircrafts.map(aircraft => (
            <MenuItem
              sx={{ paddingLeft: 4 }}
              key={aircraft.Registration}
              value={aircraft.Id}
            >
              <Checkbox
                icon={
                  <Box
                    sx={{
                      border: '1px solid #7591AD',
                      borderRadius: 0.5,
                      width: 18,
                      height: 18,
                      background: '#364B5F',
                      margin: '3px',
                      boxSizing: 'border-box',
                    }}
                  />
                }
                checked={selectedAircrafts.includes(aircraft.Id)}
              />
              <ListItemText primary={aircraft.Registration} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
};
