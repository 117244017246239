import { useContext, useEffect, useState } from 'react';
import { clearInterval, setInterval } from 'worker-timers';

import { REACT_APP_VERSION } from '../../const/const';
import { MapContext } from '../../contexts/MapContext';
import { currentTimeInTimezone } from '../../helpers/timezoneHelper';

import styles from './TechnicalTimers.module.scss';

export const TechnicalTimers = () => {
  const [curDate, setCurDate] = useState(new Date());
  const { lastSentTime, lastReceiveTime } = useContext(MapContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const tz = 'America/New_York';

  return (
    <div className={styles.timers}>
      <span className={styles.time}>
        Time: {currentTimeInTimezone(curDate, tz, true)} ET
      </span>
      {!!lastSentTime && (
        <span className={styles.time}>
          Last request sent: {currentTimeInTimezone(lastSentTime, tz, true)} ET
        </span>
      )}
      {!!lastReceiveTime && (
        <span className={styles.time}>
          Last response received:{' '}
          {currentTimeInTimezone(lastReceiveTime, tz, true)} ET
        </span>
      )}
      <span className={styles.time}>Version: {REACT_APP_VERSION}</span>
    </div>
  );
};
