import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import classnames from 'classnames';

import { OrganIconType, TResourceDevice } from '../../../types/new';

import { CellDrawerCounts } from './CellDrawerCounts';
import { CellDrawerHeader } from './CellDrawerHeader';
import { cellDrawerClasses, CellDrawerRoot } from './CellDrawerRoot';

export type CellDeviceDrawerProps = {
  day: 'today' | 'tomorrow';
  organIconName: OrganIconType;
  secondaryText: string;
  open: boolean;
  onClose: () => void;
  resources: TResourceDevice[];
};

export const CellDeviceDrawer = ({
  day,
  organIconName,
  secondaryText,
  resources,
  open,
  onClose,
}: CellDeviceDrawerProps) => {
  const [assigned, available] = resources.reduce<
    [TResourceDevice[], TResourceDevice[]]
  >(
    ([assigned, available], resource) => {
      if (resource.unosID) {
        assigned.push(resource);
      } else {
        available.push(resource);
      }
      return [assigned, available];
    },
    [[], []],
  );
  return (
    <CellDrawerRoot open={open} anchor="right" onClose={onClose}>
      <CellDrawerHeader
        onClose={onClose}
        day={day}
        organIconName={organIconName}
        secondaryText={secondaryText}
        title="Devices"
      />
      <Box className={cellDrawerClasses.resourceTableContainer}>
        <CellDrawerCounts
          assignedCount={assigned.length}
          availableCount={available.length}
        />
        {resources.length ? (
          <Table className={cellDrawerClasses.deviceResources}>
            <TableHead>
              <TableRow>
                <TableCell className={cellDrawerClasses.resourceName}>
                  Device
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceStatus}>
                  Status
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceSerialNumber}>
                  Serial No
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceUnosId}>
                  Unos ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((resource, index) => (
                <TableRow
                  key={resource.serialNumber}
                  className={(index % 2 && 'even') || 'odd'}
                >
                  <TableCell className={cellDrawerClasses.resourceName}>
                    {resource.name}
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceStatus}>
                    <span
                      className={classnames(cellDrawerClasses.resourceStatus, {
                        [cellDrawerClasses.assigned]:
                          resource.status === 'Assigned',
                        [cellDrawerClasses.available]:
                          resource.status === 'Available',
                      })}
                    >
                      {resource.status}
                    </span>
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceSerialNumber}>
                    {resource.serialNumber}
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceUnosId}>
                    {resource.unosID}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </Box>
    </CellDrawerRoot>
  );
};
