export const currentTimeInTimezone = (
  curDate: Date,
  jsTimezone: string,
  useSeconds = false,
) => {
  const datetimeStr = curDate.toLocaleString('en-US', { timeZone: jsTimezone });
  const dateObj = new Date(datetimeStr);
  const hoursInt = dateObj.getHours();
  const minutesInt = dateObj.getMinutes();
  const hoursStr = `${hoursInt < 10 ? '0' : ''}${hoursInt}`;
  const minutesStr = `${minutesInt < 10 ? '0' : ''}${minutesInt}`;

  if (useSeconds) {
    const secondsInt = dateObj.getSeconds();
    const secondsStr = `${secondsInt < 10 ? '0' : ''}${secondsInt}`;

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  return `${hoursStr}:${minutesStr}`;
};

export const msToTime = (duration: number) => {
  const parsedDuration = Math.abs(duration);
  const minutes = Math.floor((parsedDuration / (1000 * 60)) % 60);
  const hours = Math.floor((parsedDuration / (1000 * 60 * 60)) % 24);

  const hoursNumber = hours < 10 ? `0${hours}` : hours;
  const minutesNumber = minutes < 10 ? `0${minutes}` : minutes;

  return `${duration > 0 ? '-' : '+'}${hoursNumber}:${minutesNumber}`;
};
