import { useContext, useMemo } from 'react';

import { Layout, TechnicalTimers } from '../../components';
import { AlertsColumn } from '../../components/AlertsColumn';
import { AppStatus } from '../../components/AppStatus';
import { HubsList } from '../../components/HubsList';
import { Legend } from '../../components/Legend';
import { Loader } from '../../components/Loader';
import { MainMapOverlay } from '../../components/overlays';
import { Timezones } from '../../components/Timezones';
import { TripsColumn } from '../../components/TripsColumn';
import { UsaMap } from '../../components/UsaMap/UsaMap';
import { VerticalCaption } from '../../components/VerticalCaption';
import { MAX_CASE_COLUMN_ITEMS, MAX_CASE_COLUMNS } from '../../const/const';
import { MapContext } from '../../contexts/MapContext';
import {
  countNewCases,
  filterCasesByStatus,
  splitArrayToEqualChunks,
  splitNewCasesIntoColumns,
} from '../../helpers/caseHelper';
import {
  CaseType,
  NewCaseType,
  HubStatusType,
  AppDataErrors,
} from '../../types/new';

import styles from './MainMapPage.module.scss';

interface MainMapPageContentProps {
  cases: CaseType[];
  newCases: NewCaseType[];
  hubs: HubStatusType[];
  appDataErrors: AppDataErrors;
  lastUpdateTime: Date;
}

export const MainMapPageContent = ({
  cases,
  newCases,
  hubs,
  appDataErrors,
  lastUpdateTime,
}: MainMapPageContentProps) => {
  const [leftOne, rightOne, leftTwo, rightTwo] = useMemo(() => {
    const filtered = filterCasesByStatus(cases, ['Returning to Base']);
    return splitArrayToEqualChunks(
      filtered.cases,
      MAX_CASE_COLUMN_ITEMS,
      MAX_CASE_COLUMNS,
    );
  }, [cases]);

  const { west: westNewCases, east: eastNewCases } = useMemo(
    () => splitNewCasesIntoColumns(newCases),
    [newCases],
  );

  const countsInfo = {
    cases: {
      total: cases.length,
      visible: [leftOne, rightOne, leftTwo, rightTwo].flat().length,
    },
    newCases: {
      total: countNewCases(newCases),
      visible: countNewCases([...westNewCases, ...eastNewCases]),
    },
  };

  return (
    <div id="main-map-page" className={styles.layout}>
      <TripsColumn cases={leftOne} />
      <TripsColumn cases={leftTwo} />
      <VerticalCaption text="DESTINATIONS" />
      <AlertsColumn direction="west" newCases={westNewCases} />
      <div className={styles.map}>
        <UsaMap />
        <Timezones />
        <HubsList hubs={hubs} />
        <MainMapOverlay hubs={hubs} cases={cases} newCases={newCases} />
        <AppStatus
          appDataErrors={appDataErrors}
          lastUpdateTime={lastUpdateTime}
          countsInfo={countsInfo}
        >
          <Legend />
        </AppStatus>
      </div>
      <AlertsColumn direction="east" newCases={eastNewCases} />
      <VerticalCaption text="DESTINATIONS" />
      <TripsColumn cases={rightTwo} />
      <TripsColumn cases={rightOne} />
      <div className={styles.endLine}></div>
    </div>
  );
};

export const MainMapPage = () => {
  const { allData, appDataErrors, lastUpdateTime } = useContext(MapContext);
  return (
    <Layout>
      {(allData && lastUpdateTime && (
        <MainMapPageContent
          appDataErrors={appDataErrors}
          cases={allData.activeCases}
          hubs={allData.hubs}
          lastUpdateTime={lastUpdateTime}
          newCases={allData.newCases}
        />
      )) || <Loader isLoading={true} />}
      <TechnicalTimers />
    </Layout>
  );
};
