import { v4 as uuidV4 } from 'uuid';

import { rafWorker } from './raf.worker';
import { WebWorker } from './WebWorker';

const { worker: webWorker } = new WebWorker(rafWorker);

export const requestAnimationWorker = (
  callback: (timer: number) => void,
): string => {
  const rafId = uuidV4();

  webWorker.postMessage({ type: 'raf', id: rafId });
  webWorker.addEventListener('message', ({ data }) => {
    if (data.id === rafId) {
      callback(data.timer);
    }
  });

  return rafId;
};

export const cancelAnimationWorker = (id: string) => {
  webWorker.postMessage({ type: 'cancel', id });
};
