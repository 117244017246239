import { Color, Theme, ThemeOptions, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { defaultTheme } from './default-theme';
import { getPalette } from './helpers';

const grey: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#707983',
  500: '#5A646E',
  600: '#454D56',
  700: '#282F37',
  800: '#191F26',
  900: '#0B1015',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const blue: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#5B728A',
  600: '#364B5F',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const mapBlue: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#5B728A',
  600: '#364B5F',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const green: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#5B728A',
  600: '#364B5F',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const orange: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#5B728A',
  600: '#364B5F',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const violet: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#5B728A',
  600: '#364B5F',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
const mint: Color = {
  50: '#DCE6F0',
  100: '#D1D9E4',
  200: '#ABB4BF',
  300: '#939CA6',
  400: '#657C93',
  500: '#5B728A',
  600: '#364B5F',
  700: '#1D2A37',
  800: '#101C26',
  900: '#0F151C',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
export const lightTheme: Theme = createTheme(
  deepmerge(defaultTheme, {
    typography: {
      blue: {
        color: '#1680FE',
      },
      green: {
        color: '#46A000',
      },
      violet: {
        color: '#A363E2',
      },
    },
    palette: getPalette({
      mode: 'light',
      background: '#ffffff',
      primary: '#0b1015',
      grey,
      blue,
      mapBlue,
      green,
      violet,
      orange,
      mint,
    }),
  } as ThemeOptions),
);
