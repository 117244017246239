import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import classnames from 'classnames';

import { OrganIconType, TResourceHuman } from '../../../types/new';

import { CellDrawerCounts } from './CellDrawerCounts';
import { CellDrawerHeader } from './CellDrawerHeader';
import { cellDrawerClasses, CellDrawerRoot } from './CellDrawerRoot';

export type CellHumanDrawerProps = {
  day: 'today' | 'tomorrow';
  organIconName: OrganIconType;
  // icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  secondaryText: string;
  open: boolean;
  onClose: () => void;
  available: TResourceHuman[];
  assigned: TResourceHuman[];
  returning: TResourceHuman[];
  title: string;
};

export const CellHumanDrawer = ({
  day,
  organIconName,
  title,
  secondaryText,
  open,
  onClose,
  available,
  assigned,
  returning,
}: CellHumanDrawerProps) => {
  const resources = [...assigned, ...available];
  return (
    <CellDrawerRoot open={open} anchor="right" onClose={onClose}>
      <CellDrawerHeader
        onClose={onClose}
        day={day}
        organIconName={organIconName}
        secondaryText={secondaryText}
        title={title}
      />
      <Box className={cellDrawerClasses.resourceTableContainer}>
        <CellDrawerCounts
          assignedCount={assigned.length}
          availableCount={available.length}
        />
        {resources.length ? (
          <Table className={cellDrawerClasses.humanResources}>
            <TableHead>
              <TableRow>
                <TableCell className={cellDrawerClasses.resourceName}>
                  User
                </TableCell>
                <TableCell
                  className={cellDrawerClasses.resourceScheduleSubType}
                >
                  Schedule Sub Type
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceDateTime}>
                  Start
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceDateTime}>
                  End
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceStatus}>
                  Status
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceUnosId}>
                  Unos ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((resource, index) => (
                <TableRow
                  key={resource.name}
                  className={(index % 2 && 'even') || 'odd'}
                >
                  <TableCell className={cellDrawerClasses.resourceName}>
                    {resource.name}
                  </TableCell>
                  <TableCell
                    className={cellDrawerClasses.resourceScheduleSubType}
                  >
                    {resource[day]?.scheduleSubType}
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceDateTime}>
                    <Box className={cellDrawerClasses.resourceDate}>
                      {resource[day]?.startDate}
                    </Box>
                    <Box className={cellDrawerClasses.resourceTime}>
                      {`${resource[day]?.startTime}`}
                    </Box>
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceDateTime}>
                    <Box className={cellDrawerClasses.resourceDate}>
                      {resource[day]?.endDate}
                    </Box>
                    <Box className={cellDrawerClasses.resourceTime}>
                      {resource[day]?.endTime}
                    </Box>
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceStatus}>
                    <span
                      className={classnames(cellDrawerClasses.resourceStatus, {
                        [cellDrawerClasses.assigned]:
                          resource.status === 'Assigned',
                        [cellDrawerClasses.available]:
                          resource.status === 'Available',
                      })}
                    >
                      {resource.status}
                    </span>
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceUnosId}>
                    {resource.unosID}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </Box>
      {returning.length ? (
        <Box className={cellDrawerClasses.resourceReturningTable}>
          <Box className={cellDrawerClasses.headerPrimaryTitle}>
            Returning Resources
          </Box>
          <Table className={cellDrawerClasses.humanResources}>
            <TableHead>
              <TableRow>
                <TableCell className={cellDrawerClasses.resourceName}>
                  User
                </TableCell>
                <TableCell
                  className={cellDrawerClasses.resourceScheduleSubType}
                >
                  Schedule Sub Type
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceStatus}>
                  Status
                </TableCell>
                <TableCell className={cellDrawerClasses.resourceReturnTime}>
                  Returning
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {returning.map((resource, index) => (
                <TableRow
                  key={resource.name}
                  className={(index % 2 && 'even') || 'odd'}
                >
                  <TableCell className={cellDrawerClasses.resourceName}>
                    {resource.name}
                  </TableCell>
                  <TableCell
                    className={cellDrawerClasses.resourceScheduleSubType}
                  >
                    {resource[day]?.scheduleSubType}
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceStatus}>
                    <span>On Rest</span>
                  </TableCell>
                  <TableCell className={cellDrawerClasses.resourceReturnTime}>
                    {resource[day]?.endTime}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : null}
    </CellDrawerRoot>
  );
};
