import { Box, styled } from '@mui/material';

import { TrendedValue, type TrendedValueItemProps } from './TrendedValue';

const PREFIX = 'TSMED-maintSummary';

const maintStatsSummaryClasses = {
  item: `${PREFIX}-item`,
  label: `${PREFIX}-label`,
  value: `${PREFIX}-value`,
  icon: `${PREFIX}-icon`,
  negative: `${PREFIX}-negative`,
  positive: `${PREFIX}-positive`,
  primaryValue: `${PREFIX}-primaryValue`,
  secondaryValue: `${PREFIX}-secondaryValue`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  gap: spacing(1),
  flexDirection: 'column',
  [breakpoints.up('sm')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: spacing(3),
  },
  [`.${maintStatsSummaryClasses.item}`]: {
    padding: spacing(1.5, 2),
    justifyContent: 'space-between',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      flexDirection: 'column',
      padding: spacing(2, 3),
      flexGrow: 1,
    },
    background: '#0F151C',
    borderRadius: spacing(2),
    display: 'flex',
    gap: spacing(3),
  },
  [`.${maintStatsSummaryClasses.label}`]: {
    fontWeight: 400,
    fontSize: '16px',
  },
}));

export interface ISummaryItem
  extends Pick<TrendedValueItemProps, 'primaryValue' | 'secondaryValue'> {
  id: string;
  label: string;
  icon?: 'balance' | 'up' | 'down';
  kind?: 'negative' | 'positive';
}

export interface ISummaryProps {
  items: ISummaryItem[];
}

export const Summary = ({ items }: ISummaryProps) => (
  <Root>
    {items.map(({ id, label, ...item }) => (
      <Box key={id} className={maintStatsSummaryClasses.item}>
        <Box className={maintStatsSummaryClasses.label}>{label}</Box>
        <TrendedValue {...item} />
      </Box>
    ))}
  </Root>
);
