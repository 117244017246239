/* eslint-disable sonarjs/no-duplicate-string */
import {
  Box,
  CSSObject,
  ListItem,
  ListItemProps,
  ListItemText,
  styled,
} from '@mui/material';
import classNames from 'classnames';

import { TWeatherFilter } from '../../../../types';
import { sigmetColor } from '../../weather-constants';

const PREFIX = 'TSMED-weather-legend-sigmet-item';

const sigmetItemClasses = {
  marker: `${PREFIX}-marker`,
};

const Root = styled(ListItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  [`.${sigmetItemClasses.marker}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    border: '2px solid',
    width: '100%',
    height: 12,
    ...Object.entries(sigmetColor).reduce<Record<`&.id-${string}`, CSSObject>>(
      (acc, [key, { primary, secondary }]) => {
        acc[`&.id-${key.replaceAll(' ', '-')}`] = {
          background: `repeating-linear-gradient(45deg, ${primary}, ${primary} 25%, ${secondary} 25%, ${secondary} 50%, rgba(255,0,0, 0.3) 50%) top left fixed`,
          backgroundSize: '16px 16px',
          border: `1px solid ${primary}`,
        };

        return acc;
      },
      {},
    ),
  },
}));
export type TSigmetItemProps = ListItemProps & {
  label: string;
  id: keyof TWeatherFilter['sigmets'];
};
export const SigmetItem = ({ label, id, ...props }: TSigmetItemProps) => (
  <Root {...props}>
    <ListItemText primary={label} />
    <Box className={classNames(sigmetItemClasses.marker, `id-${id}`)} />
  </Root>
);
