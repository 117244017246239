/* eslint-disable camelcase */
import { useQuery } from '@tanstack/react-query';

import {
  REACT_APP_API_FETCH_INTERVAL,
  REACT_APP_BE_API_URL,
} from '../../const/const';
import {
  TAircraftReport,
  TAircraftReportIcingCondition,
  TAircraftReportSkyCondition,
  TAircraftReportTurbulenceCondition,
} from '../../types';

import { aviationWeatherFetch } from './aviationWeatherFetch';

const url = `${REACT_APP_BE_API_URL}api/aviation-weather/aircraftreports.cache.csv.gz`;
const extractIcingConditions = (
  rawFields: string[],
): TAircraftReportIcingCondition[] => {
  const icingConditions: TAircraftReportIcingCondition[] = [];
  const icingConditionsRaw = rawFields.slice(18, 28);
  const fieldsCount = 4;
  for (let i = 0, offset = 0; i < 2; i++, offset += fieldsCount) {
    const icingConditionsFields = icingConditionsRaw.slice(
      offset,
      offset + fieldsCount,
    );
    if (icingConditionsFields.some(i => !!i)) {
      const [icing_type, icing_intensity, icing_base_ft_msl, icing_top_ft_msl] =
        icingConditionsFields;
      icingConditions.push({
        icing_type,
        icing_intensity:
          icing_intensity as TAircraftReportIcingCondition['icing_intensity'],
        icing_base_ft_msl,
        icing_top_ft_msl,
      });
    }
  }
  return icingConditions;
};
const extractTurbulenceConditions = (
  rawFields: string[],
): TAircraftReportTurbulenceCondition[] => {
  const turbulenceConditions: TAircraftReportTurbulenceCondition[] = [];
  const turbulenceConditionsRaw = rawFields.slice(18, 28);
  const fieldsCount = 5;
  for (let i = 0, offset = 0; i < 2; i++, offset += fieldsCount) {
    const turbulenceConditionsFields = turbulenceConditionsRaw.slice(
      offset,
      offset + fieldsCount,
    );
    if (turbulenceConditionsFields.some(i => !!i)) {
      const [
        turbulence_type,
        turbulence_intensity,
        turbulence_base_ft_msl,
        turbulence_top_ft_msl,
        turbulence_freq,
      ] = turbulenceConditionsFields;
      turbulenceConditions.push({
        turbulence_type,
        turbulence_intensity:
          turbulence_intensity as TAircraftReportTurbulenceCondition['turbulence_intensity'],
        turbulence_base_ft_msl,
        turbulence_top_ft_msl,
        turbulence_freq,
      });
    }
  }
  return turbulenceConditions;
};
const extractSkyConditions = (
  rawFields: string[],
): TAircraftReportSkyCondition[] => {
  const skyConditions: TAircraftReportSkyCondition[] = [];
  const skyConditionRaw = rawFields.slice(12, 18);
  const fieldsCount = 3;
  for (let i = 0, offset = 0; i < 2; i++, offset += fieldsCount) {
    const skyConditionFields = skyConditionRaw.slice(
      offset,
      offset + fieldsCount,
    );
    if (skyConditionFields.some(i => !!i)) {
      const [sky_cover, cloud_base_ft_msl, cloud_top_ft_msl] =
        skyConditionFields;

      skyConditions.push({
        sky_cover,
        cloud_base_ft_msl,
        cloud_top_ft_msl,
      });
    }
  }
  return skyConditions;
};

const transform = (raw: string) => {
  const rows = raw.split('\n');
  if (rows.length < 6) {
    throw new Error('Corrupted CSV file');
  }
  const rawData = rows.slice(6);

  const data: TAircraftReport[] = rawData
    .filter(i => !!i)
    .map(row => {
      const rawFields = row.split(',');
      const [
        receipt_time,
        observation_time,
        mid_point_assumed,
        no_time_stamp,
        flt_lvl_range,
        above_ground_level_indicated,
        no_flt_lvl,
        bad_location,
        aircraft_ref,
        latitude,
        longitude,
        altitude_ft_msl,
        visibility_statute_mi,
        wx_string,
        temp_c,
        wind_dir_degrees,
        wind_speed_kt,
        vert_gust_kt,
        report_type,
        raw_text,
      ] = [...rawFields.slice(0, 12), ...rawFields.slice(36, 44)];

      const sky_condition = extractSkyConditions(rawFields);
      const turbulence_condition = extractTurbulenceConditions(rawFields);
      const icing_condition = extractIcingConditions(rawFields);
      const aircraftReport: TAircraftReport = {
        receipt_time,
        observation_time,
        mid_point_assumed: mid_point_assumed === 'TRUE',
        no_time_stamp: no_time_stamp === 'TRUE',
        flt_lvl_range: flt_lvl_range === 'TRUE',
        above_ground_level_indicated: above_ground_level_indicated === 'TRUE',
        no_flt_lvl: no_flt_lvl === 'TRUE',
        bad_location: bad_location === 'TRUE',
        aircraft_ref,
        latitude: +latitude,
        longitude: +longitude,
        altitude_ft_msl: +altitude_ft_msl,
        sky_condition,
        turbulence_condition,
        icing_condition,
        visibility_statute_mi: +visibility_statute_mi,
        wx_string,
        temp_c: +temp_c,
        wind_dir_degrees: +wind_dir_degrees,
        wind_speed_kt: +wind_speed_kt,
        vert_gust_kt,
        report_type,
        raw_text,
      };

      return aircraftReport;
    });
  return data;
};

export const useQueryPireps = () =>
  useQuery({
    queryKey: ['aircraftReports'],
    queryFn: async () => await aviationWeatherFetch(url),
    select: transform,
    refetchInterval: 1000 * REACT_APP_API_FETCH_INTERVAL,
  });
