/* eslint-disable sonarjs/no-duplicate-string */
import { useMemo } from 'react';

import { useQueryStatsAOGDR } from '../../../../adapters';
import { statsFormatCount, statsFormatPercent } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowAOGDR = MobileTableRow<{
  scheduledCount: number;
  cancelledCount: number;
}>;

export const StatsAOGDRTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsAOGDR);

  const rows: MobileTableRowAOGDR[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowAOGDR[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const rows: MobileTableRowAOGDR[] = Object.values(
      statsData.perAircraft,
    ).map(
      ({
        tailNumber,
        intervals,
        aogdr,
        aogdrPrev,
        cancelledCount,
        cancelledPrevCount,
        scheduledCount,
        scheduledPrevCount,
      }) => {
        const intervalValues = intervalFields.map(date => ({
          date,
          scheduledCount: intervals[date].scheduledCount,
          cancelledCount: intervals[date].cancelledCount,
        }));

        return {
          tailNumber,
          scheduledCount,
          cancelledCount,
          rate: [aogdr, aogdrPrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return rows;
  }, [statsData]);

  return (
    <StatsTableMobile
      rows={rows}
      intervalField={{
        fieldName: 'scheduledCount',
        valueFormatter: statsFormatCount,
      }}
      intervalSecondaryField={{
        fieldName: 'cancelledCount',
        valueFormatter: statsFormatCount,
      }}
      rateField={{ valueFormatter: statsFormatPercent }}
      summaryRows={[
        {
          field: 'scheduledCount',
          label: 'Total Scheduled Flights',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'cancelledCount',
          label: 'Total Cancelled Flights',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'rate',
          label: 'AOG Dispatch Reliability',
          valueFormatter: statsFormatPercent,
        },
      ]}
      intervalColumns={['', 'Sch. Flights / Canc. Flights']}
      rateStrategy="asc"
    />
  );
};
