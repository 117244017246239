/* eslint-disable sonarjs/no-duplicate-string */
import { ISigmet, TAirSigmet } from '../../types';

export const sigmetColor: Record<
  TAirSigmet['hazard'] | ISigmet['hazard'],
  { primary: string; secondary: string }
> = {
  CONVECTIVE: {
    primary: 'rgba(255,0,0,0.5)',
    secondary: 'rgba(255,0,0,0.3)',
  },
  IFR: {
    primary: '#909',
    secondary: 'rgba(255,0,0,0.1)',
  },
  'MTN OBSCN': {
    primary: 'rgba(255,255,255,0.4)',
    secondary: 'rgba(255,255,255,0.1)',
  },
  TURB: {
    primary: 'rgba(160,96,0,0.5)',
    secondary: 'rgba(255,0,0,0.1)',
  },
  ICE: {
    primary: 'rgba(0,0,160,0.5)',
    secondary: 'rgba(0,0,128,0.2)',
  },
  TS: {
    primary: 'rgba(255,0,0,0.5)',
    secondary: 'rgba(128,128,255,0.3)',
  },
  TC: {
    primary: 'rgba(255,0,0,0.5)',
    secondary: 'rgba(128,255,255,0.3)',
  },
  TU: {
    primary: 'red',
    secondary: 'rgba(255,0,0,0.1)',
  },
  MT: {
    primary: 'red',
    secondary: 'rgba(255,0,0,0.1)',
  },
  IC: {
    primary: 'red',
    secondary: 'rgba(255,0,0,0.1)',
  },
  VA: {
    primary: 'rgba(0,255,0,0.5)',
    secondary: 'rgba(0,255,0,0.3)',
  },
};
