import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsMTBA } from '../../../../adapters/stats/useQueryStatsMTBA';
import { statsFormatCount, statsFormatHours } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsMTBAChart } from './StatsMTBAChart';
import { StatsMTBATable } from './StatsMTBATable';
import { StatsMTBATableMobile } from './StatsMTBATableMobile';

const PREFIX = 'TSMED-StatsMTBATab';

const statsMTBAClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsMTBAClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsMTBAClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsMTBAClasses.fieldNegative}`]: {},
  [`.${statsMTBAClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsMTBA = () => {
  const { data: statsData } = useQueryData(useQueryStatsMTBA);

  const {
    totalOpHours,
    totalPrevOpHours,
    totalAogCount,
    totalPrevAogCount,
    totalMtba,
    totalPrevMtba,
  } = useMemo(() => {
    if (!statsData) {
      return {
        totalOpHours: 0,
        totalPrevOpHours: 0,
        totalAogCount: 0,
        totalPrevAogCount: 0,
        totalMtba: 0,
        totalPrevMtba: 0,
      };
    }

    return {
      totalOpHours: statsData.summary.opValue,
      totalPrevOpHours: statsData.summary.opPrevValue,
      totalAogCount: statsData.summary.aogCount,
      totalPrevAogCount: statsData.summary.aogPrevCount,
      totalMtba: statsData.summary.mtba,
      totalPrevMtba: statsData.summary.mtbaPrev,
    };
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const summaryItems: ISummaryItem[] = [
    {
      id: 'total-op-hours',
      label: 'Total Operational Hours',
      primaryValue: statsFormatHours(totalOpHours),
      secondaryValue: ` / ${statsFormatHours(totalPrevOpHours)}`,
    },
    {
      id: 'total-aog-count',
      label: 'Total AOG Events',
      primaryValue: statsFormatCount(totalAogCount),
      secondaryValue: ` / ${statsFormatCount(totalPrevAogCount)}`,
    },
    {
      id: 'mtba',
      label: 'MTBA',
      primaryValue: statsFormatHours(totalMtba),
      secondaryValue: ` / ${statsFormatHours(totalPrevMtba)}`,
      icon:
        (totalMtba === totalPrevMtba && 'balance') ||
        (totalMtba < totalPrevMtba && 'down') ||
        'up',
      kind: totalMtba > totalPrevMtba ? 'positive' : 'negative',
    },
  ];

  return (
    <Root>
      <Summary items={summaryItems} />
      <StatsMTBAChart />
      <Box className={statsMTBAClasses.contentBlock}>
        <Typography variant="h2">MTBA by Aircraft</Typography>
        {isMobile ? <StatsMTBATableMobile /> : <StatsMTBATable />}
      </Box>
    </Root>
  );
};
