import { pickBy } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { TWeatherFilter } from '../types';

interface WeatherProviderProps {
  children?: ReactNode;
}

type TWeatherContext = {
  filter: TWeatherFilter;
  setFilter: (value: TWeatherFilter) => void;
};

const filterDefault: TWeatherFilter = {
  flightCats: {
    ['LIFR']: true,
    ['IFR']: true,
    ['MVFR']: false,
    ['VFR']: false,
  },
  pireps: {
    tb: {
      ['TB-NEG']: false,
      ['TB-SMOOTH']: false,
      ['TB-LGT']: false,
      ['TB-LGT-MOD']: false,
      ['TB-MOD']: true,
      ['TB-MOD-SEV']: true,
      ['TB-SEV']: true,
      ['TB-EXTRM']: true,
      ['TB-LLWS']: true,
    },
    ic: {
      ['IC-NEG']: false,
      ['IC-TRACE']: false,
      ['IC-TRACE-LGT']: false,
      ['IC-LGT']: false,
      ['IC-LGT-MOD']: false,
      ['IC-MOD']: true,
      ['IC-MOD-SEV']: true,
      ['IC-SEV']: true,
      ['IC-OTHER']: false,
    },
  },
  sigmets: {
    ['CONVECTIVE']: true,
    ['ICE']: true,
    ['IFR']: false,
    ['MTN-OBSCN']: false,
    ['MTW']: false,
    ['TC']: true,
    ['TS']: true,
    ['TURB']: false,
    ['VA']: false,
  },
};

const filterInitial: Partial<TWeatherFilter> = JSON.parse(
  localStorage.getItem('weatherFilter') ?? '{}',
);

const initialContextData: TWeatherContext = {
  filter: {
    flightCats: {
      ...filterDefault.flightCats,
      ...filterInitial.flightCats,
      ...pickBy(filterDefault.flightCats, v => !v),
    } as TWeatherFilter['flightCats'],
    pireps: {
      ic: {
        ...filterDefault.pireps.ic,
        ...filterInitial.pireps?.ic,
        ...pickBy(filterDefault.pireps.ic, v => !v),
      } as TWeatherFilter['pireps']['ic'],
      tb: {
        ...filterDefault.pireps.tb,
        ...filterInitial.pireps?.tb,
        ...pickBy(filterDefault.pireps.tb, v => !v),
      } as TWeatherFilter['pireps']['tb'],
    },

    sigmets: {
      ...filterDefault.sigmets,
      ...filterInitial.sigmets,
      ...pickBy(filterDefault.sigmets, v => !v),
    } as TWeatherFilter['sigmets'],
  },
  setFilter: (value: TWeatherFilter) => {
    throw new Error(`setFilter not assigned for WeatherContext`);
  },
};

export const WeatherContext =
  createContext<TWeatherContext>(initialContextData);

export const WeatherProvider = ({ children }: WeatherProviderProps) => {
  const [filter, setFilter] = useState<TWeatherFilter>(
    initialContextData.filter,
  );

  const storeFilter = useCallback(
    (newFilter: TWeatherFilter) => {
      localStorage.setItem('weatherFilter', JSON.stringify(newFilter));
      setFilter(newFilter);
    },
    [setFilter],
  );
  const ctx = useMemo(
    () => ({ filter, setFilter: storeFilter }),
    [filter, storeFilter],
  );
  return (
    <WeatherContext.Provider value={ctx}>{children}</WeatherContext.Provider>
  );
};
