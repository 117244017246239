import { useContext, useEffect, useState } from 'react';

import { setCasesToAircrafts } from '../../adapters/myAirOps/transformAircraftStatuses';
import { AircraftCard } from '../../components/AircraftCard';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { TechnicalTimers } from '../../components/TechnicalTimers';
import { MapContext } from '../../contexts/MapContext';
import { AircraftCardItem, AircraftStatus, CaseType } from '../../types/new';

import styles from './AircraftCardsPage.module.scss';

interface AircraftCardsPageContentProps {
  aircraftsList: AircraftStatus[];
  activeCases: CaseType[];
  backendNetworkError?: string;
}

export const AircraftCardsPageContent = ({
  aircraftsList,
  activeCases,
  backendNetworkError,
}: AircraftCardsPageContentProps) => {
  const [aircrafts, setAircrafts] = useState<AircraftCardItem[]>([]);

  useEffect(() => {
    if (!backendNetworkError) {
      setAircrafts(setCasesToAircrafts(aircraftsList, activeCases));
    }
  }, [aircraftsList, activeCases, backendNetworkError]);

  return (
    <div className={styles.layout}>
      {aircrafts.map(item => (
        <AircraftCard key={item.Id} {...item} count={aircrafts.length} />
      ))}
    </div>
  );
};

export const AircraftCardsPage = () => {
  const { allData, backendNetworkError } = useContext(MapContext);

  return (
    <Layout>
      {(allData && (
        <AircraftCardsPageContent
          aircraftsList={allData.aircrafts.list}
          activeCases={allData.activeCases}
          backendNetworkError={backendNetworkError}
        />
      )) || <Loader isLoading={true} />}
      <TechnicalTimers />
    </Layout>
  );
};
