import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import './muiInit';
import './dev';
import { CssBaseline } from '@mui/material';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import { ThemeConnector } from './components';
import { AppRouter } from './components/AppRouter';
import { DomElementsProvider } from './contexts/DomElementsContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});
type AppProps = {
  msalInstance: IPublicClientApplication;
};

export const App = ({ msalInstance }: AppProps) => (
  <MsalProvider instance={msalInstance}>
    <DomElementsProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <ThemeConnector>
          <CssBaseline enableColorScheme />
          <AppRouter />
        </ThemeConnector>
      </PersistQueryClientProvider>
    </DomElementsProvider>
  </MsalProvider>
);
