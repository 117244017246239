import { geoAlbers, geoConicEqualArea, GeoConicProjection } from 'd3-geo';

import { MAP_OVERLAY_VIEW_WIDTH } from '../const/const';
import { AircraftPositionLeg, TPoint } from '../types/new';

import { getPartOfMap } from './boundaries';

const xmlns = 'http://www.w3.org/2000/svg';
const DEFAULT_OFFSET = {
  top: 130,
  left: 252,
};

const OFFSETS: Record<string, Record<string, number>> = {
  alaska: {
    top: -95,
    left: -155,
  },
  lower48: DEFAULT_OFFSET,
  canada: DEFAULT_OFFSET,
  hawaii: {
    top: 315,
    left: -337,
  },
  puertoRico: {
    top: 427,
    left: 698,
  },
};

const DEFAULT_PROJECTION = geoAlbers().scale(1330);

const ALBERS_PROJECTIONS: Record<string, GeoConicProjection> = {
  alaska: geoConicEqualArea()
    .scale(485)
    .rotate([116, 0.5, -0.4])
    .center([-2, 58.5])
    .parallels([55, 65]),
  lower48: DEFAULT_PROJECTION,
  canada: DEFAULT_PROJECTION,
  hawaii: geoConicEqualArea()
    .scale(1700)
    .rotate([157, 0])
    .center([-3, 19.9])
    .parallels([8, 18]),
  puertoRico: geoConicEqualArea()
    .scale(2400)
    .rotate([66, 0])
    .center([0, 18])
    .parallels([8, 18]),
};

export const getPointPixelsCoordinates = (
  [a, b]: TPoint,
  boundary: string,
  isWeather = false,
): TPoint => {
  if (isWeather && boundary === 'canada') {
    return [-1000, -1000];
  }
  const albersProjection = ALBERS_PROJECTIONS[boundary];
  const offsets = OFFSETS[boundary];

  const projected = albersProjection([a, b]);

  if (projected) {
    // eslint-disable-next-line prefer-const
    let [x, y] = projected;
    // set item closer to east coast of USA
    if (x > 980) {
      x = 980;
    }

    const yPosition = y + offsets.top;
    const xPosition = x + offsets.left;

    if (yPosition < 55 && boundary === 'canada') {
      return [xPosition, 55];
    }

    return [xPosition, yPosition];
  }

  return [-1000, -1000];
};

export const getPoint = (point: TPoint) => {
  const boundary = getPartOfMap(point);

  if (boundary) {
    return getPointPixelsCoordinates(point, boundary, true);
  }
};

export const getPolygon = (points: TPoint[]) =>
  points
    .reduce<TPoint[]>((acc, point) => {
      const boundary = getPartOfMap(point);

      if (boundary) {
        acc.push(getPointPixelsCoordinates(point, boundary));
      }
      return acc;
    }, [])
    .map(([x, y]) => `${x},${y}`)
    .join(' ');
export const getPointsCenter = (points: TPoint[]): TPoint => {
  const minMax = points.reduce(
    (acc, point) => {
      acc[0] = Math.min(point[0], acc[0]);
      acc[1] = Math.min(point[1], acc[1]);
      acc[2] = Math.max(point[0], acc[2]);
      acc[3] = Math.max(point[1], acc[3]);

      return acc;
    },
    [points[0][0], points[0][1], points[0][0], points[0][1]],
  );
  const point: TPoint = [
    (minMax[2] + minMax[0]) / 2,
    (minMax[3] + minMax[1]) / 2,
  ];
  const boundary = getPartOfMap(point);

  if (boundary) {
    return getPointPixelsCoordinates(point, boundary);
  }
  return [-1000, -1000];
};

export const getCurve = (
  [x1, y1]: number[],
  [x2, y2]: number[],
  curveIndex = 1,
): string => {
  const mpx = (x1 + x2) * 0.5;
  const mpy = (y1 + y2) * 0.5;

  const startToTopLeft = Math.hypot(x1, y1);
  const endToTopLeft = Math.hypot(x2, y2);
  const theta = Math.atan2(y2 - y1, x2 - x1) - Math.PI / 2;
  const isTop = y1 < 250 && y2 < 250;
  const isStartTopLeft = startToTopLeft < endToTopLeft;

  const path = document.createElementNS(xmlns, 'path');
  path.setAttributeNS(null, 'd', `M${x1},${y1} ${x2},${y2}`);
  const offsetLength = (path.getTotalLength() / 8) * curveIndex;

  // offset calculations
  let offset: number;

  if (isTop) {
    offset = x1 > x2 ? offsetLength : offsetLength * -1;
  } else {
    offset = isStartTopLeft ? offsetLength : offsetLength * -1;
  }

  // location of control point:
  const c1x = mpx + offset * Math.cos(theta);
  const c1y = mpy + offset * Math.sin(theta);

  return `M${x1},${y1} Q${c1x},${c1y} ${x2},${y2}`;
};

export const getNewCaseLine = (
  [x1, y1]: number[],
  cardYPosition: number,
  offset: number,
  isWest = true,
): string => {
  const cardXPosition = isWest ? offset : MAP_OVERLAY_VIEW_WIDTH;

  return `M${x1},${y1} ${cardXPosition},${cardYPosition}`;
};

export const getHubLine = ([x1, y1]: number[], [x2, y2]: number[]): string =>
  `M${x1},${y1} ${x2},${y2}`;

type GetAircraftPositionParams = {
  coordinates: number[];
  boundary?: string | null;
  leg?: AircraftPositionLeg;
};

export const getAircraftPosition = ({
  coordinates,
  boundary,
  leg,
}: GetAircraftPositionParams): number[] | null => {
  if (boundary) {
    const [pointX, pointY] = getPointPixelsCoordinates(
      [coordinates[0], coordinates[1]],
      boundary,
    );

    if (pointX && pointY) {
      return [pointX, pointY];
    }
  }

  if (leg?.start.Boundary && leg.end.Boundary) {
    const startCoordinates = getPointPixelsCoordinates(
      [leg.start.Longitude, leg.start.Latitude],
      leg.start.Boundary,
    );
    const endCoordinates = getPointPixelsCoordinates(
      [leg.end.Longitude, leg.end.Latitude],
      leg.end.Boundary,
    );
    const line = getHubLine(startCoordinates, endCoordinates);

    const path = document.createElementNS(xmlns, 'path');
    path.setAttributeNS(null, 'd', line);
    const pathLength = path.getTotalLength();

    const dateNowMs = new Date().getTime();
    const dateStartMs = new Date(leg.startTime).getTime();
    const dateEndMs = new Date(leg.endTime).getTime();
    const progress =
      (100 / (dateEndMs - dateStartMs)) * (dateNowMs - dateStartMs);

    const { x, y } = path.getPointAtLength(pathLength * (progress / 100));

    return [x, y];
  }

  return null;
};
