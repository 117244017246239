import { Box, Fade, List, ListItem, Popper, styled } from '@mui/material';
import classnames from 'classnames';
import { FunctionComponent, SVGProps } from 'react';

const PREFIX = 'TSMED-hubsReport-CellPopper';

const cellPopperClasses = {
  day: `${PREFIX}-day`,
  icon: `${PREFIX}-icon`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  countBar: `${PREFIX}-countBar`,
  counts: `${PREFIX}-counts`,
  countsNumber: `${PREFIX}-countsNumber`,
  countsText: `${PREFIX}-countsText`,
  available: `${PREFIX}-available`,
  unavailable: `${PREFIX}-unavailable`,
  assigned: `${PREFIX}-assigned`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#2A3641',
  padding: 12,
  borderRadius: 8,
  gap: 8,
  boxShadow: '0 8px 40px rgba(0, 0, 0, 0.5)',
  alignItems: 'center',
  [`.${cellPopperClasses.day}`]: {
    background: '#364B5F',
    padding: '2px 8px',
    borderRadius: 4,
    alignSelf: 'end',
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  [`.${cellPopperClasses.icon}`]: {
    svg: {
      height: 24,
      width: 24,
      padding: 8,
      display: 'block',
    },
  },
  [`.${cellPopperClasses.primary}`]: {
    fontSize: '20px',
    fontWeight: 500,
  },
  [`.${cellPopperClasses.secondary}`]: {
    fontSize: '16px',
    color: '#B6B6B6',
  },
  [`.${cellPopperClasses.countBar}`]: {
    display: 'flex',
    gap: 2,
    padding: 0,
    li: {
      width: 10,
      height: 10,
      borderRadius: 2,
      padding: 0,
    },
  },
  [`.${cellPopperClasses.counts}`]: {
    display: 'flex',
    div: {
      background: 'none',
      padding: '0 8px',
      gap: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  [`.${cellPopperClasses.countsNumber}`]: {
    fontWeight: 600,
    fontSize: '20px',
  },
  [`.${cellPopperClasses.countsText}`]: {
    fontWeight: 500,
    fontSize: '14px',
  },
  [`.${cellPopperClasses.available}`]: {
    color: '#83BC62',
    background: '#83BC62',
  },
  [`.${cellPopperClasses.unavailable}`]: {
    color: '#7F8083',
    background: '#7F8083',
  },
  [`.${cellPopperClasses.assigned}`]: {
    color: '#FF4848',
    background: '#FF4848',
  },
}));

export type CellPopperProps = {
  day: 'today' | 'tomorrow';
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  primaryText: string;
  secondaryText: string;
  availableCount: number;
  assignedCount: number;
  returningCount: number;
  anchorEl: HTMLElement | null;
};

export const CellPopper = ({
  day,
  icon: Icon,
  primaryText,
  secondaryText,
  availableCount,
  assignedCount,
  returningCount,
  anchorEl,
}: CellPopperProps) => {
  const open = Boolean(anchorEl);
  return (
    <Popper open={open} anchorEl={anchorEl}>
      <Fade in={open}>
        <Root>
          <Box className={cellPopperClasses.day}>{day}</Box>
          <Box className={cellPopperClasses.icon}>
            <Icon />
          </Box>
          <Box className={cellPopperClasses.primary}>{primaryText}</Box>
          <Box className={cellPopperClasses.secondary}>{secondaryText}</Box>
          <List className={cellPopperClasses.countBar}>
            {Array(availableCount)
              .fill(null)
              .map((_, index) => (
                <ListItem key={index} className={cellPopperClasses.available} />
              ))}
            {Array(assignedCount)
              .fill(null)
              .map((_, index) => (
                <ListItem key={index} className={cellPopperClasses.assigned} />
              ))}
            {Array(returningCount)
              .fill(null)
              .map((_, index) => (
                <ListItem
                  key={index}
                  className={cellPopperClasses.unavailable}
                />
              ))}
          </List>
          <Box className={cellPopperClasses.counts}>
            <Box
              className={classnames({
                [cellPopperClasses.available]: Boolean(availableCount),
                [cellPopperClasses.unavailable]: !availableCount,
              })}
            >
              <Box className={cellPopperClasses.countsNumber}>
                {availableCount}
              </Box>
              <Box className={cellPopperClasses.countsText}>Available</Box>
            </Box>
            <Box
              className={classnames({
                [cellPopperClasses.assigned]: Boolean(assignedCount),
                [cellPopperClasses.unavailable]: !assignedCount,
              })}
            >
              <Box className={cellPopperClasses.countsNumber}>
                {assignedCount}
              </Box>
              <Box className={cellPopperClasses.countsText}>Assigned</Box>
            </Box>
          </Box>
        </Root>
      </Fade>
    </Popper>
  );
};
