import { useMemo } from 'react';

import { CaseClusterType, ClusterItem } from '../../../types/new';

import { DirectionCurve } from './DirectionCurve';

interface Props {
  cluster: ClusterItem<CaseClusterType>;
}

export const CasesSvgCluster = ({ cluster }: Props) => {
  const data = useMemo(() => [...cluster.items].reverse(), [cluster.items]);

  return (
    <>
      {data.map(({ data }) => (
        <DirectionCurve
          key={data.id}
          id={data.id}
          organ={data.organ}
          cardStatus={data.cardStatus}
          mapProgress={data.mapProgress}
          points={data.points}
          viewData={data.viewData}
          cluster={cluster}
        />
      ))}
    </>
  );
};
