import { ReactComponent as ComboPageBgSVG } from '../../assets/comboPageBg.svg';
import { ReactComponent as ComboPageSVG } from '../../assets/comboPageIcon.svg';
import { ReactComponent as HubSVG } from '../../assets/hub.svg';
import { ReactComponent as MainMapSVG } from '../../assets/mainMapIcon.svg';
import { ReactComponent as MonitorMenuSVG } from '../../assets/monitorMenuIcon.svg';
import { ReactComponent as PlaneMenuSVG } from '../../assets/planeMenuIcon.svg';
import { ReactComponent as PlannerMenuSVG } from '../../assets/plannerMenuIcon.svg';
import { ReactComponent as StatisticsSVG } from '../../assets/statistics.svg';
import { ReactComponent as TransmedicsLogoSVG } from '../../assets/transmedicsLogo.svg';
import { ReactComponent as WeatherSVG } from '../../assets/weather.svg';
import { allRoutes as routes } from '../../const/routes';

import styles from './StartPage.module.scss';

export const StartPage = () => (
  <div className={styles.layout}>
    <div className={styles.backgroundBlock}>
      <div className={styles.logo}>
        <TransmedicsLogoSVG />
      </div>
      <div className={styles.lines}>
        <ComboPageBgSVG />
      </div>
      <h1 className={styles.text}>Command center</h1>
    </div>
    <div className={styles.list}>
      <a className={styles.link} href={routes.MAIN_MAP}>
        <MainMapSVG />
        <span>Active Cases Map</span>
      </a>
      <a className={styles.link} href={routes.COMBO_PAGE}>
        <ComboPageSVG />
        <span>Combo Screen</span>
      </a>
      <a className={styles.link} href={routes.AIRCRAFTS_MAP}>
        <PlaneMenuSVG />
        <span>Air Fleet Tracker</span>
      </a>
      <a className={styles.link} href={routes.AIRCRAFTS_CARDS}>
        <PlannerMenuSVG />
        <span>Air Fleet Planner</span>
      </a>
      <a className={styles.link} href={routes.ERRORS}>
        <MonitorMenuSVG />
        <span>System Monitor</span>
      </a>
      <a className={styles.link} href={routes.STATISTICS}>
        <StatisticsSVG />
        <span>Statistics</span>
      </a>
      <a className={styles.link} href={routes.HUBS_REPORT}>
        <HubSVG />
        <span>Hubs Report</span>
      </a>
      <a className={styles.link} href={routes.WEATHER}>
        <WeatherSVG />
        <span>Aviation Weather</span>
      </a>
    </div>
  </div>
);
