import styles from './VerticalCaption.module.scss';

interface Props {
  text: string;
}

export const VerticalCaption = ({ text }: Props) => (
  <div className={styles.caption}>
    <div className={styles.genericCaptionText}>{text}</div>
  </div>
);
