import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  EIGHT_HOURS,
  SIX_HOURS,
  MAP_OVERLAY_VIEW_HEIGHT,
  MAP_OVERLAY_VIEW_WIDTH,
} from '../../../const/const';
import { AIRCRAFTS_MAP_Y_RADIUS_PX } from '../../../helpers/clustering';
import {
  getAircraftPosition,
  getHubLine,
  getPointPixelsCoordinates,
} from '../../../helpers/pathGeneration';
import { useElementRef } from '../../../hooks/useElementRef';
import { useSvgClientRect } from '../../../hooks/useSvgClientRect';
import { AirportType, OrganIconType } from '../../../types/new';
import styles from '../overlays.module.scss';

const ORGAN_COLORS: Record<'liver' | 'lung' | 'heart', string> = {
  liver: '#9BDD67',
  lung: '#6CA5D6',
  heart: '#A27CC9',
};
interface Props {
  id: string;
  longitude: number | null;
  latitude: number | null;
  boundary: string | null;
  timeOnDuty: number;
  organ: OrganIconType | null;
  isLate: boolean;
  isOnFly: boolean;
  tripLegStart: string | null;
  tripLegEnd: string | null;
  isReturnToBase: boolean;
  airports: AirportType[];
}

export const AircraftCircle = ({
  id,
  longitude,
  latitude,
  boundary,
  timeOnDuty,
  isLate,
  isOnFly,
  isReturnToBase,
  tripLegStart,
  tripLegEnd,
  organ,
  airports,
}: Props) => {
  const { element } = useElementRef<HTMLDivElement>(`${id}-aircraft-icons`);
  const { ref } = useElementRef<SVGPathElement>(id);
  const { width: clientWidth, height: clientHeight } =
    useSvgClientRect('overlaySVG');
  const [elementTop, setElementTop] = useState(0);
  const [elementLeft, setElementLeft] = useState(0);
  const [elementParent, setElementParent] = useState<HTMLDivElement | null>();

  const updateOffsets = useCallback(
    (element: HTMLDivElement, parent: HTMLDivElement) => {
      const top = element.offsetTop;

      if (top) {
        const elementHeight = element.offsetHeight;
        const heightOffset = elementHeight > AIRCRAFTS_MAP_Y_RADIUS_PX ? 12 : 2;
        const parentStyles = getComputedStyle(parent);

        // https://stackoverflow.com/a/64654744/20412354
        const domMatrix = new DOMMatrixReadOnly(
          parentStyles.getPropertyValue('transform'),
        );
        const parentTranslateY = domMatrix.m42;

        const parentTop = parent.offsetTop + parentTranslateY;
        const parentLeft = parent.offsetLeft;

        setElementTop(parentTop + top + elementHeight / 2 + heightOffset);
        setElementLeft(parentLeft + 14);
      }
    },
    [],
  );

  useEffect(() => {
    if (element) {
      const parent = element.offsetParent as HTMLDivElement | null;
      setElementParent(parent);
    }
  }, [element]);

  useEffect(() => {
    if (element && elementParent) {
      updateOffsets(element, elementParent);

      const observer = new MutationObserver(() => {
        updateOffsets(element, elementParent);
      });

      observer.observe(elementParent as Node, {
        subtree: true,
        attributes: true,
        childList: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [element, updateOffsets, elementParent]);

  const aircraftCircle = useMemo<number[] | null>(() => {
    if (
      longitude &&
      latitude &&
      airports.length &&
      tripLegStart &&
      tripLegEnd
    ) {
      const currentArrivalAirportIndex = airports.findIndex(
        i => !!i?.IsNextArrival,
      );
      const startAirport = airports[currentArrivalAirportIndex - 1];
      const endAirport = airports[currentArrivalAirportIndex];

      const coordinates = getAircraftPosition({
        coordinates: [longitude, latitude],
        boundary,
        leg: airports.length
          ? {
              start: startAirport,
              end: endAirport,
              startTime: tripLegStart,
              endTime: tripLegEnd,
            }
          : undefined,
      });

      if (coordinates) {
        return coordinates;
      }
    } else if (longitude && latitude) {
      const coordinates = getAircraftPosition({
        coordinates: [longitude, latitude],
        boundary,
      });

      if (coordinates) {
        return coordinates;
      }
    }

    return null;
  }, [longitude, latitude, boundary, airports, tripLegStart, tripLegEnd]);

  const line = useMemo(() => {
    if (
      !elementTop ||
      !elementLeft ||
      !clientHeight ||
      !clientWidth ||
      !aircraftCircle
    ) {
      return null;
    }

    const elementLeftPosition = elementLeft + 5;

    const cardPositionY = (elementTop / clientHeight) * MAP_OVERLAY_VIEW_HEIGHT;
    const cardPositionX =
      (elementLeftPosition / clientWidth) * MAP_OVERLAY_VIEW_WIDTH;

    return getHubLine([cardPositionX, cardPositionY], aircraftCircle);
  }, [elementTop, elementLeft, clientWidth, clientHeight, aircraftCircle]);

  const airportCircles = useMemo<
    { id: string; coordinates: number[] | null }[] | null
  >(() => {
    if (!isOnFly || !aircraftCircle || !airports.length) {
      return null;
    }

    return airports.map((airport, index) => ({
      id: `${airport.Id}-${airport.IATA}-${index}`,
      coordinates: airport.Boundary
        ? getPointPixelsCoordinates(
            [airport.Longitude, airport.Latitude],
            airport.Boundary,
          )
        : null,
    }));
  }, [isOnFly, aircraftCircle, airports]);

  const airportLines = useMemo<
    { type: string; line: string | null }[] | null
  >(() => {
    if (!isOnFly || !aircraftCircle || !airports.length) {
      return null;
    }

    const arrivalAirportIndex = airports.findIndex(i => i?.IsNextArrival);
    let airportsLines: { type: string; line: string | null }[] = [];
    let startAirport = airports[0];

    for (let i = 1; i < airports.length; i++) {
      const endAirport = airports[i];
      const startPoint = startAirport.Boundary
        ? getPointPixelsCoordinates(
            [startAirport.Longitude, startAirport.Latitude],
            startAirport.Boundary,
          )
        : null;
      const endPoint = endAirport.Boundary
        ? getPointPixelsCoordinates(
            [endAirport.Longitude, endAirport.Latitude],
            endAirport.Boundary,
          )
        : null;

      if (arrivalAirportIndex === i) {
        const startLine = startPoint
          ? getHubLine(startPoint, aircraftCircle)
          : null;
        const endLine = endPoint ? getHubLine(endPoint, aircraftCircle) : null;

        airportsLines = [
          ...airportsLines,
          {
            type: 'startLine',
            line: startLine,
          },
          {
            type: 'endLine',
            line: endLine,
          },
        ];
      } else {
        const line =
          startPoint && endPoint ? getHubLine(startPoint, endPoint) : null;

        airportsLines = [
          ...airportsLines,
          {
            type: i < arrivalAirportIndex ? 'startLine' : 'endLine',
            line,
          },
        ];
      }

      startAirport = endAirport;
    }

    return airportsLines;
  }, [isOnFly, aircraftCircle, airports]);

  const strokeColor = useMemo(() => {
    if (isLate) {
      return '#FF3844';
    }

    if (isReturnToBase) {
      return '#7F8083';
    }

    if (organ) {
      return ORGAN_COLORS[organ];
    }

    if (timeOnDuty > EIGHT_HOURS) {
      return 'white';
    }

    if (timeOnDuty >= SIX_HOURS && timeOnDuty <= EIGHT_HOURS) {
      return '#8BD8AE';
    }

    if (timeOnDuty > 0 && timeOnDuty < SIX_HOURS) {
      return '#7591AD';
    }

    return '#7F8083';
  }, [timeOnDuty, isReturnToBase, isLate, organ]);

  return (
    <>
      {aircraftCircle && (
        <circle
          id={`${id}-pointA`}
          cx={aircraftCircle[0]}
          cy={aircraftCircle[1]}
          ref={ref}
          r="3"
          className={styles.pointOnMap}
          style={{
            stroke: strokeColor,
          }}
        />
      )}
      {line && (
        <path className={styles.clusterLine} stroke={strokeColor} d={line} />
      )}
      {airportCircles?.map(({ id, coordinates }) =>
        coordinates ? (
          <circle
            key={id}
            cx={coordinates[0]}
            cy={coordinates[1]}
            r="1.75"
            className={styles.directionPoint}
            style={{
              stroke: strokeColor,
            }}
          />
        ) : null,
      )}
      {airportLines?.map(({ type, line }) =>
        line ? (
          <path
            key={line}
            className={
              type === 'startLine'
                ? styles.startAirportLine
                : styles.endAirportLine
            }
            stroke={strokeColor}
            d={line}
          />
        ) : null,
      )}
    </>
  );
};
