import { svgIconClasses, Theme, typographyClasses } from '@mui/material';
import { dayCalendarClasses } from '@mui/x-date-pickers-pro';

import { themeBase } from '../theme-base';

const { spacing, palette } = themeBase;
export const muiDateRangePicker: Theme['components'] = {
  MuiPickersPopper: {
    styleOverrides: {
      paper: {
        marginTop: spacing(0.5),
      },
    },
  },
  MuiDayCalendar: {
    styleOverrides: {
      root: {
        [`.${dayCalendarClasses.weekDayLabel}`]: {
          color: palette.grey[200],
        },
      },
    },
  },
  MuiPickersToolbarText: {
    styleOverrides: {
      root: {
        ['&.Mui-selected']: {
          color: palette.mapBlue[300],
        },
      },
    },
  },
  MuiDateRangePickerDay: {
    styleOverrides: {
      root: {
        color: palette.grey[200],
      },
      day: {
        color: palette.grey[300],
        fontSize: '12px',
        ['&.Mui-selected']: {
          backgroundColor: palette.mapBlue[600],
          ['&:hover']: {
            backgroundColor: palette.mapBlue[500],
          },
        },
      },
      rangeIntervalDayHighlight: {
        background: palette.mapBlue[800],
      },
      hiddenDayFiller: {
        background: 'transparent',
      },
      rangeIntervalDayHighlightStart: {
        borderColor: palette.mapBlue[600],
      },
      rangeIntervalDayHighlightEnd: {
        borderColor: palette.mapBlue[600],
      },
    },
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      root: {
        [`.${svgIconClasses.root}`]: {
          color: palette.grey[400],
          height: 24,
          width: 24,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      root: {
        [`.${typographyClasses.root}`]: {
          color: palette.grey[100],
        },
      },
    },
  },
};
