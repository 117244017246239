import classnames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './Loader.module.scss';

interface LoaderProps {
  isLoading: boolean;
  type?: 'absolute' | 'static';
}

export const Loader = ({
  isLoading,
  type = 'absolute',
  children,
}: PropsWithChildren<LoaderProps>) => {
  if (isLoading) {
    return (
      <div
        className={classnames(styles.loaderWrapper, {
          [styles.loaderAbsolute]: type === 'absolute',
        })}
      >
        <div className={styles.loader}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
