import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsFR } from '../../../../adapters';
import {
  statsFormatCount,
  statsFormatHours,
  statsFormatRate,
} from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsFRChart } from './StatsFRChart';
import { StatsFRCountsTable } from './StatsFRCountsTable';
import { StatsFRCountsTableMobile } from './StatsFRCountsTableMobile';
import { StatsFRHoursTable } from './StatsFRHoursTable';
import { StatsFRHoursTableMobile } from './StatsFRHoursTableMobile';

const PREFIX = 'TSMED-statsFRTab';

const statsFrequencyClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsFrequencyClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsFrequencyClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsFrequencyClasses.fieldNegative}`]: {},
  [`.${statsFrequencyClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsFR = () => {
  const { data: statsData } = useQueryData(useQueryStatsFR);
  const {
    totalAogEventsCount,
    totalPrevAogEventsCount,
    totalFlightEventsCount,
    totalPrevFlightEventsCount,
    totalFlightsHours,
    totalPrevFlightsHours,
    totalAogFRPerFlights: aogFRPerFlights,
    totalPrevAogFRPerFlights: aogPrevFRPerFlights,
    totalAogFRPerHours: aogFRPerHours,
    totalPrevAogFRPerHours: aogPrevFRPerHours,
  } = useMemo(() => {
    if (!statsData) {
      return {
        totalAogEventsCount: 0,
        totalPrevAogEventsCount: 0,
        totalFlightEventsCount: 0,
        totalPrevFlightEventsCount: 0,
        totalAogHours: 0,
        totalPrevAogHours: 0,
        totalFlightsHours: 0,
        totalPrevFlightsHours: 0,
        totalAogFRPerFlights: 0,
        totalPrevAogFRPerFlights: 0,
        totalAogFRPerHours: 0,
        totalPrevAogFRPerHours: 0,
      };
    }

    return {
      totalAogEventsCount: statsData.summary.aogCount,
      totalFlightEventsCount: statsData.summary.flightCount,
      totalAogHours: statsData.summary.aogValue,
      totalFlightsHours: statsData.summary.flightValue,
      totalAogFRPerFlights: statsData.summary.aogFrequencyRatePerFlights,
      totalAogFRPerHours: statsData.summary.aogFrequencyRatePerHours,
      totalPrevAogEventsCount: statsData.summary.aogPrevCount,
      totalPrevFlightEventsCount: statsData.summary.flightPrevCount,
      totalPrevAogHours: statsData.summary.aogPrevValue,
      totalPrevFlightsHours: statsData.summary.flightPrevValue,
      totalPrevAogFRPerFlights:
        statsData.summary.aogFrequencyRatePerFlightsPrev,
      totalPrevAogFRPerHours: statsData.summary.aogFrequencyRatePerHoursPrev,
    };
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const summaryItems: ISummaryItem[] = [
    {
      id: 'total-flights',
      label: 'Total Flights',
      primaryValue: statsFormatCount(totalFlightEventsCount),
      secondaryValue: ` / ${statsFormatCount(totalPrevFlightEventsCount)}`,
    },
    {
      id: 'total-flight-hours',
      label: 'Total Flight Hours',
      primaryValue: statsFormatHours(totalFlightsHours),
      secondaryValue: ` / ${statsFormatHours(totalPrevFlightsHours)}`,
    },
    {
      id: 'total-aog-events',
      label: 'Total AOG Events',
      primaryValue: statsFormatCount(totalAogEventsCount),
      secondaryValue: ` / ${statsFormatCount(totalPrevAogEventsCount)}`,
    },
    {
      id: 'aog-frequency-rate-per-flights',
      label: 'AOG Frequency Rate per 1000 Flights',
      icon:
        (aogFRPerFlights === aogPrevFRPerFlights && 'balance') ||
        (aogFRPerFlights < aogPrevFRPerFlights && 'down') ||
        'up',
      kind: aogFRPerFlights < aogPrevFRPerFlights ? 'positive' : 'negative',
      primaryValue: statsFormatRate(aogFRPerFlights),
      secondaryValue: ` / ${statsFormatRate(aogPrevFRPerFlights)}`,
    },
    {
      id: 'aog-frequency-rate-per-flight-hours',
      label: 'AOG Frequency Rate per 1000 Flight Hours',
      icon:
        (aogFRPerHours === aogPrevFRPerHours && 'balance') ||
        (aogFRPerHours < aogPrevFRPerHours && 'down') ||
        'up',
      kind: aogFRPerHours < aogPrevFRPerHours ? 'positive' : 'negative',
      primaryValue: statsFormatRate(aogFRPerHours),
      secondaryValue: ` / ${statsFormatRate(aogPrevFRPerHours)}`,
    },
  ];

  return (
    <Root>
      <Summary items={summaryItems} />
      <StatsFRChart />
      <Box className={statsFrequencyClasses.contentBlock}>
        <Typography variant="h2">
          AOG Frequency by 1000 Flights by Aircraft
        </Typography>
        {isMobile ? <StatsFRCountsTableMobile /> : <StatsFRCountsTable />}
      </Box>
      <Box className={statsFrequencyClasses.contentBlock}>
        <Typography variant="h2">
          AOG Frequency by 1000 Flight Hours by Aircraft
        </Typography>
        {isMobile ? <StatsFRHoursTableMobile /> : <StatsFRHoursTable />}
      </Box>
    </Root>
  );
};
