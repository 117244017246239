import { useMemo } from 'react';

import { MAP_OVERLAY_VIEW_HEIGHT } from '../../../const/const';
import {
  getHubLine,
  getPointPixelsCoordinates,
} from '../../../helpers/pathGeneration';
import { useElementRef } from '../../../hooks/useElementRef';
import { useSvgClientRect } from '../../../hooks/useSvgClientRect';
import { TPoint } from '../../../types';
import styles from '../overlays.module.scss';

interface Props {
  id: string;
  boundary: string;
  point: TPoint;
}

export const HubLine = ({ id, point, boundary }: Props) => {
  const { element: hubStatusCard } = useElementRef<HTMLDivElement>(id);
  const { width: clientWidth, height: clientHeight } =
    useSvgClientRect('mainOverlaySVG');

  const hubPosition = useMemo(
    () => getPointPixelsCoordinates(point, boundary),
    [point, boundary],
  );

  const line = useMemo<string | null>(() => {
    if (!hubStatusCard || !clientHeight || !clientWidth) {
      return null;
    }

    const cardYPosition =
      ((hubStatusCard.offsetTop + 60) / clientHeight) * MAP_OVERLAY_VIEW_HEIGHT;
    const cardXPosition =
      ((hubStatusCard.offsetLeft + 60) / clientHeight) *
      MAP_OVERLAY_VIEW_HEIGHT;

    return getHubLine(hubPosition, [cardXPosition, cardYPosition]);
  }, [hubStatusCard, clientHeight, clientWidth, hubPosition]);

  return (
    <>
      {line ? (
        <path
          className={styles.line}
          id={`${id}-line`}
          strokeWidth={1}
          stroke="url(#hubLineGradient)"
          d={line}
        />
      ) : null}
      <circle
        id={`${id}-point`}
        cx={hubPosition[0]}
        cy={hubPosition[1]}
        r="1.5"
        className={styles.directionPoint}
        stroke="#7591AD"
      />
    </>
  );
};
