import { Navigate } from 'react-router-dom';

import { useSsoUserGroupAccess } from '../hooks/useUserGroupAccess';

export const NoAccessPage = () => {
  const { defaultPage, status } = useSsoUserGroupAccess();

  if (status === 'pending') {
    return <>Loading...</>;
  }

  return defaultPage ? (
    <Navigate to={defaultPage} />
  ) : (
    <>No access to any group</>
  );
};
