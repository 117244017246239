import classnames from 'classnames';
import Lottie from 'lottie-react';
import { PropsWithChildren, useMemo } from 'react';

import cloudErrorAnimation from '../../assets/cloudErrorAnimation.json';
import { ReactComponent as GearSVG } from '../../assets/gear.svg';
import { ReactComponent as ShieldSVG } from '../../assets/shield.svg';
import { currentTimeInTimezone } from '../../helpers/timezoneHelper';
import { AppDataErrors } from '../../types/new';

import styles from './AppStatus.module.scss';

interface Props {
  appDataErrors: AppDataErrors;
  lastUpdateTime: Date;
  countsInfo: {
    cases: { total: number; visible: number };
    newCases: { total: number; visible: number };
  };
}

export const AppStatus = ({
  appDataErrors,
  lastUpdateTime,
  countsInfo,
  children,
}: PropsWithChildren<Props>) => {
  const hasErrors: boolean = useMemo(
    () => !!Object.keys(appDataErrors).length,
    [appDataErrors],
  );

  return (
    <>
      {!hasErrors && children}
      <div className={styles.appStatusLayout}>
        <div className={styles.infoList}>
          <div
            className={classnames(styles.icon, {
              [styles.errorColor]: hasErrors,
            })}
          >
            {hasErrors ? <GearSVG /> : <ShieldSVG />}
            <p
              className={classnames(styles.infoText, {
                [styles.errorColor]: hasErrors,
              })}
            >
              Last Update:{' '}
              {currentTimeInTimezone(lastUpdateTime, 'America/New_York')} ET
            </p>
          </div>
          <p className={styles.infoText}>
            Cases: {countsInfo.cases.visible}/{countsInfo.cases.total}
          </p>
          <p className={styles.infoText}>
            New Cases: {countsInfo.newCases.visible}/{countsInfo.newCases.total}
          </p>
        </div>
        {hasErrors && (
          <div className={styles.errorInfo}>
            <Lottie
              animationData={cloudErrorAnimation}
              loop={true}
              style={{ width: '30px' }}
            />
            <span className={styles.errorMessage}>Backend Network Error!</span>
          </div>
        )}
      </div>
    </>
  );
};
