import { REACT_APP_BE_API_URL } from '../../const/const';
import { fetchWithMsalHeaders } from '../../helpers/msal';
import { AllDataType, StatisticsType } from '../../types/new';

export const fetchAllData = async () => {
  let allData: AllDataType = {
    activeCases: [],
    newCases: [],
    hubs: [],
    hubsReport: [],
    aircrafts: { list: [], statuses: {} },
    statistics: {} as StatisticsType,
    dataErrors: {},
    networkErrors: {},
  };
  let backendNetworkError;

  try {
    /* parse from API */
    const url = `${REACT_APP_BE_API_URL}api/all_data`;
    /* parse from local */
    // const url = `/test_data/all_data.json`;
    /* random failure */
    // const url = Number(new Date)%2 ? `/test_data/all_data.json` : 'https://all_data.fake.website/';

    const [res, msalError] = await fetchWithMsalHeaders(url);

    if (msalError === null) {
      const json = await res.json();
      if (json && Object.keys(json).length) {
        allData = json;
      } else {
        backendNetworkError = 'Backend Data is empty.';
      }
    }
  } catch (e) {
    backendNetworkError = 'Backend Network error.';
  }
  return { allData, backendNetworkError };
};
