import {
  CSSObject,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  styled,
} from '@mui/material';

import { TWeatherFilter } from '../../../../types';

const PREFIX = 'TSMED-weather-legend-flight-cat-item';

const Root = styled(ListItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  [`& svg`]: {
    [`&.LIFR`]: {
      fill: 'purple',
    },
    [`&.IFR`]: {
      fill: 'red',
    },
    [`&.MVFR`]: {
      fill: 'blue',
    },
    [`&.VFR`]: {
      fill: 'green',
    },
  } as Record<`&.${keyof TWeatherFilter['flightCats']}`, CSSObject>,
}));
export type TFlightCatItemProps = ListItemProps & {
  flightCat: keyof TWeatherFilter['flightCats'];
};
export const FlightCatItem = ({ flightCat, ...props }: TFlightCatItemProps) => (
  <Root {...props}>
    <ListItemIcon>
      <svg
        className={flightCat}
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle r="12" cx="12" cy="12" />
      </svg>
    </ListItemIcon>
    <ListItemText primary={flightCat} />
  </Root>
);
