import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsDowntime } from '../../../../adapters';
import { statsFormatHours, statsFormatPercent } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsDowntimeAogTable } from './StatsDowntimeAogTable';
import { StatsDowntimeAogTableMobile } from './StatsDowntimeAogTableMobile';
import { StatsDowntimeChart } from './StatsDowntimeChart';
import { StatsDowntimeTable } from './StatsDowntimeTable';
import { StatsDowntimeTableMobile } from './StatsDowntimeTableMobile';

const PREFIX = 'TSMED-StatsDowntime';

const statsDowntimeClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsDowntimeClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsDowntimeClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsDowntimeClasses.fieldNegative}`]: {},
  [`.${statsDowntimeClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsDowntime = () => {
  const { data: statsData } = useQueryData(useQueryStatsDowntime);

  const {
    totalHours,
    totalPrevHours,
    totalDowntimeHours,
    totalDowntimePrevHours,
    totalAogDowntimeHours,
    totalAogDowntimePrevHours,
    downtimeRate,
    downtimePrevRate,
    aogDowntimeRate,
    aogDowntimePrevRate,
  } = useMemo(() => {
    if (!statsData) {
      return {
        totalHours: 0,
        totalPrevHours: 0,
        totalDowntimeHours: 0,
        totalDowntimePrevHours: 0,
        totalAogDowntimeHours: 0,
        totalAogDowntimePrevHours: 0,
        downtimeRate: 0,
        downtimePrevRate: 0,
        aogDowntimeRate: 0,
        aogDowntimePrevRate: 0,
      };
    }

    return {
      totalHours: statsData.summary.totalHoursValue,
      totalPrevHours: statsData.summary.totalHoursPrevValue,
      totalDowntimeHours: statsData.summary.downtimeValue,
      totalDowntimePrevHours: statsData.summary.downtimePrevValue,
      totalAogDowntimeHours: statsData.summary.aogDowntimeValue,
      totalAogDowntimePrevHours: statsData.summary.aogDowntimePrevValue,
      downtimeRate: statsData.summary.downtimeRate,
      downtimePrevRate: statsData.summary.downtimeRatePrev,
      aogDowntimeRate: statsData.summary.aogDowntimeRate,
      aogDowntimePrevRate: statsData.summary.aogDowntimeRatePrev,
    };
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const summaryItems: ISummaryItem[] = [
    {
      id: 'total-hours',
      label: 'Total Hours',
      primaryValue: statsFormatHours(totalHours),
      secondaryValue: ` / ${statsFormatHours(totalPrevHours)}`,
    },
    {
      id: 'total-downtime-hours',
      label: 'Total Downtime Hours',
      primaryValue: statsFormatHours(totalDowntimeHours),
      secondaryValue: ` / ${statsFormatHours(totalDowntimePrevHours)}`,
    },
    {
      id: 'total-aog-hours',
      label: 'Total AOG Downtime Hours',
      primaryValue: statsFormatHours(totalAogDowntimeHours),
      secondaryValue: ` / ${statsFormatHours(totalAogDowntimePrevHours)}`,
    },
    {
      id: 'total-downtime-rate',
      label: 'Downtime Rate %',
      primaryValue: statsFormatPercent(downtimeRate),
      secondaryValue: ` / ${statsFormatPercent(downtimePrevRate)}`,
      icon:
        (downtimeRate === downtimePrevRate && 'balance') ||
        (downtimeRate < downtimePrevRate && 'down') ||
        'up',
      kind: downtimeRate > downtimePrevRate ? 'positive' : 'negative',
    },
    {
      id: 'total-aog-downtime-rate',
      label: 'Total AOG Downtime Rate %',
      primaryValue: statsFormatPercent(aogDowntimeRate),
      secondaryValue: ` / ${statsFormatPercent(aogDowntimePrevRate)}`,
      icon:
        (aogDowntimeRate === aogDowntimePrevRate && 'balance') ||
        (aogDowntimeRate < aogDowntimePrevRate && 'down') ||
        'up',
      kind: aogDowntimeRate > aogDowntimePrevRate ? 'negative' : 'positive',
    },
  ];

  return (
    <Root>
      <Summary items={summaryItems} />
      <StatsDowntimeChart />
      <Box className={statsDowntimeClasses.contentBlock}>
        <Typography variant="h2">Downtime by Aircraft</Typography>
        {isMobile ? <StatsDowntimeTableMobile /> : <StatsDowntimeTable />}
      </Box>
      <Box className={statsDowntimeClasses.contentBlock}>
        <Typography variant="h2">AOG Downtime by Aircraft</Typography>
        {isMobile ? <StatsDowntimeAogTableMobile /> : <StatsDowntimeAogTable />}
      </Box>
    </Root>
  );
};
