import { ReactElement } from 'react';

import styles from './StatisticsItem.module.scss';

export type StatisticsItemProps = {
  bg: string;
  icon: ReactElement;
  mainColor?: string;
  percents: string;
  label: string;
  extraPercents?: string;
  extraIcon?: ReactElement;
  hours?: string;
};
export const StatisticsItem = ({
  bg,
  percents,
  mainColor,
  icon,
  extraPercents,
  extraIcon,
  label,
  hours,
}: StatisticsItemProps) => {
  const hasExtra = extraIcon || extraPercents;
  const isSingleIcon = icon && !extraIcon;
  return (
    <div className={styles.pieChart}>
      <div className={styles.container}>
        <div
          className={styles.containerBg}
          style={{
            background: bg,
          }}
        />

        <div className={styles.contentWrapper}>
          {isSingleIcon && <div className={styles.icon}>{icon}</div>}
          <div className={styles.content}>
            <div className={styles.main}>
              {!isSingleIcon && (
                <div
                  className={styles.icon}
                  style={{
                    color: mainColor,
                  }}
                >
                  {icon}
                </div>
              )}
              <div
                className={styles.text}
                style={{
                  color: mainColor,
                }}
              >
                {percents}%
              </div>
            </div>
            {hasExtra && (
              <div className={styles.extra}>
                {extraIcon && <div className={styles.icon}>{extraIcon}</div>}
                {extraPercents && (
                  <div className={styles.text}>{extraPercents}%</div>
                )}
              </div>
            )}
          </div>
          {hours && <div className={styles.hours}>{hours}h</div>}
        </div>
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};
