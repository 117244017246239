import { useMemo } from 'react';

import { useQueryStatsDowntime } from '../../../../adapters';
import { statsFormatHours, statsFormatPercent } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowDowntimeAog = MobileTableRow<{
  aogDowntimeHours: number;
  totalHoursHours: number;
}>;
export const StatsDowntimeAogTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsDowntime);

  const rows: MobileTableRowDowntimeAog[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowDowntimeAog[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const rows: MobileTableRowDowntimeAog[] = Object.values(
      statsData.perAircraft,
    ).map(
      ({
        tailNumber,
        downtimeValue,
        aogDowntimeValue,
        totalHoursValue,
        downtimeRate,
        downtimeRatePrev,
        intervals,
      }) => {
        const intervalValues = intervalFields.map(date => ({
          date,
          aogDowntimeHours: intervals[date]?.aogDowntimeValue ?? 0,
          totalHoursHours: intervals[date]?.totalHoursValue ?? 0,
        }));

        return {
          tailNumber,
          aogDowntimeHours: aogDowntimeValue,
          totalHoursHours: totalHoursValue,
          rate: [downtimeRate, downtimeRatePrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return rows;
  }, [statsData]);
  return (
    <StatsTableMobile
      rows={rows}
      intervalField={{
        fieldName: 'aogDowntimeHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        fieldName: 'totalHoursHours',
        valueFormatter: statsFormatHours,
      }}
      rateField={{ valueFormatter: statsFormatPercent }}
      summaryRows={[
        {
          field: 'aogDowntimeHours',
          label: 'Total AOG Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'totalHoursHours',
          label: 'Total Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          valueFormatter: statsFormatPercent,
          label: 'AOG Downtime',
        },
      ]}
      intervalColumns={['', 'Op. h / AOG h']}
      rateStrategy="desc"
    />
  );
};
