import { useCallback, useEffect, useState } from 'react';

import { useElementRef } from './useElementRef';

type UseSvgClientRectType = {
  width: number;
  height: number;
};

export const useSvgClientRect = (id: string): UseSvgClientRectType => {
  const [clientWidth, setClientWidth] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const { element: svgElement } = useElementRef<SVGSVGElement>(id);

  const setClientDimensions = useCallback((width: number, height: number) => {
    setClientWidth(width);
    setClientHeight(height);
  }, []);

  const listener = useCallback(() => {
    if (svgElement) {
      setClientDimensions(svgElement.clientWidth, svgElement.clientHeight);
    }
  }, [setClientDimensions, svgElement]);

  useEffect(() => {
    if (svgElement) {
      setClientDimensions(svgElement.clientWidth, svgElement.clientHeight);

      window.addEventListener('resize', listener);

      return () => {
        window.removeEventListener('resize', listener);
      };
    }
  }, [listener, setClientDimensions, svgElement]);

  return {
    width: clientWidth,
    height: clientHeight,
  };
};
