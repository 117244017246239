import { HubStatusType } from '../../types/new';
import { HubStatusCard } from '../HubStatusCard';

import styles from './HubsList.module.scss';

interface Props {
  hubs: HubStatusType[];
}

export const HubsList = ({ hubs }: Props) => (
  <div className={styles.hubs}>
    {hubs.map(item => (
      <HubStatusCard key={item.id} {...item} />
    ))}
  </div>
);
