import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CURVE_COLORS,
  MAP_OVERLAY_VIEW_HEIGHT,
  MAP_OVERLAY_VIEW_WIDTH,
  ORGAN_COLORS,
  STATUS_COLORS,
} from '../../../const/const';
import { getHubLine } from '../../../helpers/pathGeneration';
import { useElementRef } from '../../../hooks/useElementRef';
import { useSvgClientRect } from '../../../hooks/useSvgClientRect';
import { CaseClusterType, CaseType, ClusterItem } from '../../../types/new';
import styles from '../overlays.module.scss';

type Props = Pick<
  CaseType,
  'id' | 'organ' | 'cardStatus' | 'viewData' | 'mapProgress' | 'points'
> & {
  cluster: ClusterItem<CaseClusterType>;
};

export const DirectionCurve = ({
  id,
  organ,
  cardStatus,
  viewData,
  mapProgress,
  points,
  cluster,
}: Props) => {
  const { element: caseIcons } = useElementRef<HTMLDivElement>(
    `${id}-case-icons`,
  );
  const { ref, element } = useElementRef<SVGPathElement>(`${id}-curve`);
  const { width: clientWidth, height: clientHeight } =
    useSvgClientRect('mainOverlaySVG');
  const [elementTop, setElementTop] = useState(0);
  const [elementLeft, setElementLeft] = useState(0);
  const [elementParent, setElementParent] = useState<HTMLDivElement | null>();

  const updateOffsets = useCallback((top: number, parent: HTMLDivElement) => {
    if (top) {
      const parentTop = parent.offsetTop;
      const parentLeft = parent.offsetLeft;

      setElementTop(top - 10 + parentTop);
      setElementLeft(parentLeft + 15);
    }
  }, []);

  useEffect(() => {
    if (caseIcons) {
      const parent = caseIcons.offsetParent as HTMLDivElement | null;
      setElementParent(parent);
    }
  }, [caseIcons, updateOffsets, cluster]);

  useEffect(() => {
    if (caseIcons && elementParent) {
      updateOffsets(caseIcons.offsetTop, elementParent);

      const observer = new MutationObserver(() => {
        updateOffsets(caseIcons.offsetTop, elementParent);
      });

      observer.observe(elementParent as Node, {
        subtree: true,
        attributes: true,
        childList: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [caseIcons, updateOffsets, elementParent]);

  const curveColor = useMemo(() => {
    const organColor = CURVE_COLORS[organ.name];
    const statusColor =
      cardStatus !== 'error' && STATUS_COLORS[cardStatus]
        ? STATUS_COLORS[cardStatus]
        : null;

    return statusColor ?? organColor;
  }, [organ, cardStatus]);

  const mainColor = useMemo(() => {
    const organColor = ORGAN_COLORS[organ.name];
    const statusColor =
      cardStatus !== 'error' && STATUS_COLORS[cardStatus]
        ? STATUS_COLORS[cardStatus]
        : null;

    return statusColor ?? organColor;
  }, [organ, cardStatus]);

  const progressCircle = useMemo<{ x: number; y: number } | null>(() => {
    if (!element) {
      return null;
    }

    const pathLength = element.getTotalLength();

    return element.getPointAtLength(pathLength * (mapProgress / 100));
  }, [element, mapProgress]);

  const line = useMemo(() => {
    if (
      !elementTop ||
      !elementLeft ||
      !clientHeight ||
      !clientWidth ||
      !progressCircle
    ) {
      return null;
    }

    const cardPositionY = (elementTop / clientHeight) * MAP_OVERLAY_VIEW_HEIGHT;
    const cardPositionX = (elementLeft / clientWidth) * MAP_OVERLAY_VIEW_WIDTH;

    return getHubLine(
      [cardPositionX, cardPositionY],
      [progressCircle.x, progressCircle.y],
    );
  }, [elementTop, elementLeft, clientWidth, clientHeight, progressCircle]);

  const isThirdPartyAviation = useMemo(
    () => points.some(i => i.type === 'airNonMao'),
    [points],
  );

  const isTmdxAviation = useMemo(
    () => points.some(i => i.type === 'airMao'),
    [points],
  );

  return (
    <>
      {!!viewData?.curve && (
        <path
          className={classnames(styles.curve, {
            [styles.thirdPartyAviation]: isThirdPartyAviation,
            [styles.tmdxAviation]: isTmdxAviation,
          })}
          id={`${id}-curve`}
          ref={ref}
          d={viewData.curve}
          style={{ stroke: curveColor }}
        />
      )}
      {!!viewData?.pointA && (
        <circle
          id={`${id}-pointA`}
          cx={viewData?.pointA[0]}
          cy={viewData?.pointA[1]}
          r="1.75"
          className={styles.directionPoint}
          stroke={cardStatus === 'warning' ? STATUS_COLORS.warning : '#D9D9D9'}
        />
      )}
      {!!viewData?.pointB && (
        <circle
          id={`${id}-pointB`}
          cx={viewData?.pointB[0]}
          cy={viewData?.pointB[1]}
          r="1.75"
          className={styles.directionPoint}
          stroke={cardStatus === 'warning' ? STATUS_COLORS.warning : '#D9D9D9'}
        />
      )}
      {!!progressCircle && (
        <circle
          id={`${id}-progress-circle`}
          cx={progressCircle.x}
          cy={progressCircle.y}
          r="3"
          className={styles.pointOnMap}
          style={{
            stroke: mainColor,
          }}
        />
      )}
      {line && (
        <path className={styles.clusterLine} stroke={mainColor} d={line} />
      )}
    </>
  );
};
