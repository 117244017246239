import { LogLevel } from '@azure/msal-browser';

import {
  REACT_APP_SSO_AUTHORITY,
  REACT_APP_SSO_AUTHORITY_DOMAIN,
  REACT_APP_SSO_CLIENT_ID,
  REACT_APP_SSO_LOG_LEVEL,
  REACT_APP_SSO_SCOPES,
} from './const/const';

export const msalConfig = {
  log: 'warning',
  auth: {
    clientId: REACT_APP_SSO_CLIENT_ID,
    authority: REACT_APP_SSO_AUTHORITY,
    knownAuthorities: [REACT_APP_SSO_AUTHORITY_DOMAIN],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii || level > REACT_APP_SSO_LOG_LEVEL) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: REACT_APP_SSO_SCOPES.split(','),
};
