import { useMemo, ReactNode } from 'react';

import { useQueryMetars } from '../../../adapters';
import { hashCode } from '../../../helpers';
import { getPoint } from '../../../helpers/pathGeneration';

import { useWeatherFilter } from './useWeatherFilter';

export const useMetars = () => {
  const { data: metarsData } = useQueryMetars();

  const { filter } = useWeatherFilter();

  return useMemo(() => {
    if (!metarsData) {
      return [];
    }

    return metarsData
      .filter(m => filter.flightCats[m.flightCategory])
      .reduce<ReactNode[]>((acc, a, index) => {
        const point = getPoint([a.longitude, a.latitude]);
        if (point && point[0] > 0) {
          const key = hashCode(`${point.toString()}-${index} `);
          if (['IFR', 'LIFR'].includes(a.flightCategory)) {
            const color = a.flightCategory === 'IFR' ? 'red' : 'purple';
            acc.push(
              <circle
                key={`${key}-metar-point`}
                r="3"
                className={`${a.longitude} ${a.latitude}`}
                cx={point[0]}
                cy={point[1]}
                fill={color}
                opacity={0.75}
              />,
            );
          }
        }

        return acc;
      }, []);
  }, [filter.flightCats, metarsData]);
};
