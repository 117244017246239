import { THubsReportStatus } from '../../../types/new';

import styles from './TableRowGroupItem.module.scss';
import { TableRowItem } from './TableRowItem';

export interface TableRowGroupItemProps {
  region: string;
  rows: THubsReportStatus[];
  startIndex: number; // for alternating row colors (even/odd)
}

export const TableRowGroupItem = ({
  region,
  rows,
  startIndex,
}: TableRowGroupItemProps) => (
  <li className={styles.group}>
    <div className={styles['group-header']}>{region}</div>
    <ul className={styles.rows}>
      {rows.map((row, index) => (
        <TableRowItem
          key={row.location.abbreviation}
          row={row}
          isEven={(startIndex + index) % 2 === 1}
        />
      ))}
    </ul>
  </li>
);
