import { useState } from 'react';
import { useDebounce } from 'react-use';

import {
  MAP_OVERLAY_VIEW_WIDTH,
  MAP_OVERLAY_VIEW_HEIGHT,
} from '../../const/const';
import { getAircraftClustering } from '../../helpers/clustering';
import { useElementRef } from '../../hooks/useElementRef';
import { useSvgClientRect } from '../../hooks/useSvgClientRect';
import {
  ClusterItem,
  TAircraftList,
  AircraftClusterTypes,
} from '../../types/new';

import { AircraftsSvgCluster } from './components/AircraftsSvgCluster';
import { ClusterComponent } from './components/ClusterComponent';
import styles from './overlays.module.scss';

interface Props {
  aircrafts: TAircraftList;
}

export const AircraftsMapOverlay = ({ aircrafts }: Props) => {
  const { ref } = useElementRef<SVGSVGElement>('overlaySVG');
  const { width: clientWidth, height: clientHeight } =
    useSvgClientRect('overlaySVG');
  const hasContent = Boolean(aircrafts.length);

  const [clusters, setClusters] = useState<ClusterItem<AircraftClusterTypes>[]>(
    [],
  );

  useDebounce(
    () => {
      if (hasContent && clientHeight && clientWidth) {
        const data = getAircraftClustering({
          aircrafts,
          clientHeight,
          clientWidth,
        });

        setClusters(data);
      } else {
        setClusters([]);
      }
    },
    250,
    [aircrafts, hasContent, clientHeight, clientWidth],
  );

  return hasContent ? (
    <div className={styles.aircraftsContainer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="overlaySVG"
        className={styles.overlaySVG}
        ref={ref}
        height="100%"
        viewBox={`0 0 ${MAP_OVERLAY_VIEW_WIDTH} ${MAP_OVERLAY_VIEW_HEIGHT}`}
      >
        {clusters.map(item => (
          <AircraftsSvgCluster key={item.key} cluster={item} />
        ))}
      </svg>
      {clusters.map(item => (
        <ClusterComponent
          key={item.key}
          items={item.items}
          clientWidth={clientWidth}
          clientHeight={clientHeight}
          isCluster={item.isCluster}
        />
      ))}
    </div>
  ) : null;
};
