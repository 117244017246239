// export const usaStatesFullList = {
//   "AL": "Alabama",
//   "AK": "Alaska",
//   // "AS": "American Samoa",
//   "AZ": "Arizona",
//   "AR": "Arkansas",
//   "CA": "California",
//   "CO": "Colorado",
//   "CT": "Connecticut",
//   "DE": "Delaware",
//   "DC": "District Of Columbia",
//   // "FM": "Federated States Of Micronesia",
//   "FL": "Florida",
//   "GA": "Georgia",
//   // "GU": "Guam",
//   "HI": "Hawaii",
//   "ID": "Idaho",
//   "IL": "Illinois",
//   "IN": "Indiana",
//   "IA": "Iowa",
//   "KS": "Kansas",
//   "KY": "Kentucky",
//   "LA": "Louisiana",
//   "ME": "Maine",
//   // "MH": "Marshall Islands",
//   "MD": "Maryland",
//   "MA": "Massachusetts",
//   "MI": "Michigan",
//   "MN": "Minnesota",
//   "MS": "Mississippi",
//   "MO": "Missouri",
//   "MT": "Montana",
//   "NE": "Nebraska",
//   "NV": "Nevada",
//   "NH": "New Hampshire",
//   "NJ": "New Jersey",
//   "NM": "New Mexico",
//   "NY": "New York",
//   "NC": "North Carolina",
//   "ND": "North Dakota",
//   // "MP": "Northern Mariana Islands",
//   "OH": "Ohio",
//   "OK": "Oklahoma",
//   "OR": "Oregon",
//   // "PW": "Palau",
//   "PA": "Pennsylvania",
//   "PR": "Puerto Rico",
//   "RI": "Rhode Island",
//   "SC": "South Carolina",
//   "SD": "South Dakota",
//   "TN": "Tennessee",
//   "TX": "Texas",
//   "UT": "Utah",
//   "VT": "Vermont",
//   // "VI": "Virgin Islands",
//   "VA": "Virginia",
//   "WA": "Washington",
//   "WV": "West Virginia",
//   "WI": "Wisconsin",
//   "WY": "Wyoming"
// };

// https://image3.slideserve.com/6573221/the-southern-states-part-2-n.jpg
export const usaStatesWestern = [
  'WA',
  'OR',
  'CA',
  'ID',
  'NV',
  'UT',
  'AZ',
  'MT',
  'WY',
  'CO',
  'NM',
  'ND',
  'SD',
  'NE',
  'KS',
  'OK',
  'TX',
  'AK',
  'HI',
];

export const usaStatesEastern = [
  // north-east
  'ME',
  'NH',
  'NY',
  'MA',
  'RI',
  'NJ',
  'CT',
  'DC',
  'PA',
  'VT',
  // midwest
  'MN',
  'WI',
  'MI',
  'IA',
  'IL',
  'IN',
  'OH',
  'MO',
  // south
  'DE',
  'NC',
  'VA',
  'WV',
  'KY',
  'AR',
  'TN',
  'LA',
  'MS',
  'AL',
  'MD',
  'GA',
  'SC',
  'FL',
  'PR',
];

export const timezonesConfig = [
  { caption: 'HAWAII', jsTimezone: 'Pacific/Honolulu' },
  { caption: 'ALASKA', jsTimezone: 'America/Yakutat' },
  { caption: 'PACIFIC', jsTimezone: 'America/Los_Angeles' },
  { caption: 'MOUNTAIN', jsTimezone: 'America/Denver' },
  { caption: 'CENTRAL', jsTimezone: 'America/Chicago' },
  { caption: 'EASTERN', jsTimezone: 'America/New_York' },
  { caption: 'ATLANTIC', jsTimezone: 'America/Puerto_Rico' },
];
