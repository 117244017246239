import classNames from 'classnames';

import { currentTimeInTimezone } from '../../../helpers/timezoneHelper';

import styles from './LastUpdate.module.scss';

interface Props {
  lastUpdateTime: Date;
  networkErrorsCount: number;
  backendNetworkError: string;
}

export const LastUpdate = ({
  lastUpdateTime,
  backendNetworkError,
  networkErrorsCount,
}: Props) => (
  <div
    className={classNames(styles.lastUpdate, {
      [styles.error]: backendNetworkError || networkErrorsCount,
    })}
  >
    Last Update: {currentTimeInTimezone(lastUpdateTime, 'America/New_York')} ET
  </div>
);
