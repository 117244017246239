import { useQuery } from '@tanstack/react-query';

import {
  REACT_APP_API_FETCH_INTERVAL,
  REACT_APP_BE_API_URL,
} from '../../const/const';
import { TPoint, TAirSigmet } from '../../types';

import { aviationWeatherFetch } from './aviationWeatherFetch';

const url = `${REACT_APP_BE_API_URL}api/aviation-weather/airsigmets.cache.csv.gz`;

const transform = (raw: string) => {
  const rows = raw.split('\n');
  if (rows.length < 6) {
    throw new Error('Corrupted CSV file');
  }
  const rawData = rows.slice(6);

  const data: TAirSigmet[] = rawData
    .filter(i => !!i)
    .map(row => {
      const [
        rawText,
        validTimeFrom,
        validTimeTo,
        rawLonLatPoints,
        minFtMsl,
        maxFtMsl,
        movementDirDegrees,
        movementSpeedKt,
        hazard,
        severity,
        airsigmetType,
      ] = row.split(',');
      const lonLatPoints: TPoint[] = rawLonLatPoints
        ? rawLonLatPoints.split(';').map(s => {
            const [lon, lat] = s.split(':');
            return [parseFloat(lon), parseFloat(lat)] as TPoint;
          })
        : [];

      return {
        rawText,
        validTimeFrom,
        validTimeTo,
        lonLatPoints,
        minFtMsl,
        maxFtMsl,
        movementDirDegrees,
        movementSpeedKt,
        hazard: hazard as TAirSigmet['hazard'],
        severity: severity as TAirSigmet['severity'],
        airsigmetType: airsigmetType as TAirSigmet['airsigmetType'],
      };
    });
  return data;
};

export const useQueryAirSigmets = () =>
  useQuery({
    queryKey: ['airSigmets'],
    queryFn: async () => await aviationWeatherFetch(url),
    select: transform,
    refetchInterval: 1000 * REACT_APP_API_FETCH_INTERVAL,
  });
