const STATE_BOUNDARIES: Record<string, Record<string, number>> = {
  alaska: {
    top: 71.441,
    bottom: 51.2097,
    right: -129.9795,
    left: 179.1505,
  },
  lower48: {
    top: 49.384358,
    bottom: 24.396308,
    right: -66.949895,
    left: -124.784407,
  },
  canada: {
    top: 83.3362128,
    bottom: 41.6765556,
    right: -52.3231981,
    left: -141.00275,
  },
  hawaii: {
    top: 22.229,
    bottom: 18.911,
    right: -154.806,
    left: -160.247,
  },
  puertoRico: {
    top: 18.515,
    bottom: 17.926,
    right: -65.589,
    left: -67.271,
  },
};

export const getPartOfMap = ([x, y]: number[]): string | null =>
  Object.keys(STATE_BOUNDARIES).find((state: string) => {
    const { top, bottom, right, left } = STATE_BOUNDARIES[state];

    if (state === 'alaska') {
      return y >= bottom && y <= top && (x <= right || x >= left);
    }

    return y >= bottom && y <= top && x <= right && x >= left;
  }) ?? null;
