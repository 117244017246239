import { createContext } from 'react';

export type ThemeNameType = 'light' | 'dark';

export type ThemeContextType = {
  setSelectedTheme: (themeName: ThemeNameType) => void;
  selectedTheme: ThemeNameType;
};

export const ThemeContext = createContext<ThemeContextType>({
  selectedTheme: 'light',
  setSelectedTheme: () => {
    console.error('setTheme was not defined');
  },
});
