import classNames from 'classnames';
import Lottie from 'lottie-react';

import cloudErrorAnimation from '../../../assets/cloudErrorAnimation.json';
import { ReactComponent as CloudOkSVG } from '../../../assets/cloudOk.svg';
import { ReactComponent as ShieldSVG } from '../../../assets/shield.svg';

import styles from './InfoTile.module.scss';

interface Props {
  icon: 'cloudOk' | 'shield' | 'cloudError';
  largeCaption: string;
  smallCaption: string;
  additionalClassName?: string;
}

const icons = {
  shield: <ShieldSVG />,
  cloudOk: <CloudOkSVG />,
  cloudError: (
    <Lottie
      animationData={cloudErrorAnimation}
      loop={true}
      style={{ width: '130px' }}
    />
  ),
};

export const InfoTile = ({
  icon,
  largeCaption,
  smallCaption,
  additionalClassName = '',
}: Props) => (
  <div className={classNames(styles.infoTile, styles[additionalClassName])}>
    <span className={styles.icon}>{icons[icon]}</span>
    <span className={styles.captions}>
      <div className={styles.largeCaption}>{largeCaption}</div>
      <div className={styles.smallCaption}>{smallCaption}</div>
    </span>
  </div>
);
