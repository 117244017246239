import { Logout as LogoutIcon, Home as HomeIcon } from '@mui/icons-material';
import { PropsWithChildren, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ComboPageSVG } from '../../assets/comboPageIcon.svg';
import { ReactComponent as HubSVG } from '../../assets/hub.svg';
import { ReactComponent as MonitorMenuSVG } from '../../assets/monitorMenuIcon.svg';
import { ReactComponent as PlaneMenuSVG } from '../../assets/planeMenuIcon.svg';
import { ReactComponent as PlannerMenuSVG } from '../../assets/plannerMenuIcon.svg';
import { ReactComponent as StatisticsSVG } from '../../assets/statistics.svg';
import { ReactComponent as WeatherSVG } from '../../assets/weather.svg';
import { allRoutes as routes } from '../../const/routes';
import { PageDropdown } from '../PageDropdown';

const dropdownItems = [
  {
    id: routes.COMBO_PAGE,
    icon: <ComboPageSVG />,
    title: 'Combo Screen',
  },
  {
    id: routes.AIRCRAFTS_MAP,
    icon: <PlaneMenuSVG />,
    title: 'Air Fleet Tracker',
  },
  {
    id: routes.AIRCRAFTS_CARDS,
    icon: <PlannerMenuSVG />,
    title: 'Air Fleet Planner',
  },
  {
    id: routes.ERRORS,
    icon: <MonitorMenuSVG />,
    title: 'System Monitor',
  },
  {
    id: routes.STATISTICS,
    icon: <StatisticsSVG />,
    title: 'Statistics',
  },
  {
    id: routes.HUBS_REPORT,
    icon: <HubSVG />,
    title: 'Hubs Report',
  },
  {
    id: routes.WEATHER,
    icon: <WeatherSVG />,
    title: 'Aviation Weather',
  },
  {
    id: routes.START_PAGE,
    icon: <HomeIcon />,
    title: 'Start Page',
  },
  {
    id: routes.LOGOUT,
    icon: <LogoutIcon />,
    title: 'Logout',
  },
];

export type LayoutProps = PropsWithChildren;

export const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleSwitch = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );
  return (
    <div id="layout">
      <PageDropdown
        id="mainMenu"
        items={dropdownItems}
        value={location.pathname}
        onChange={handleSwitch}
        fixed
      />
      {children}
    </div>
  );
};
